import { useEffect } from 'react';

import { Patient } from '../../interfaces/entities/patient.interface';
import { getUnitParentsJoin } from '../../utils/get-unit-parents-join';
import { toCamelCase } from '../../utils/to-camel-case';
import { useStores } from '../use-stores';

export const usePatientsByFilters = (
  parents: string[] = [],
  admin?: boolean,
): {
  items?: ReadonlyArray<Patient>;
  loading?: boolean;
} => {
  const { v2PatientsStore: store } = useStores();
  const key = `${admin ? 'admin-' : ''}parents-${parents.sort().join('-')}`;

  useEffect(() => {
    const searchObj: Record<string, any> = { $or: [] };

    const join: string[] = getUnitParentsJoin('unit');
    for (const joinItem of join) {
      for (const parent of parents) {
        searchObj.$or.push({
          [`${toCamelCase(joinItem)}.id`]: parent,
        });
      }
    }

    store.fetchAllItemsByFilters(searchObj, key, admin);
  }, [store.fetchAllItemsByFilters, parents, admin]);

  return {
    items: store.allItemsByFilters[key],
    loading: store.loadingAllItemsByFilters[key],
  };
};
