import { LOCALES } from '../../../utils/constants';

export const TYNIMCE_PLUGINS = [
  'autoresize advlist autolink lists link charmap print preview anchor',
  'searchreplace visualblocks code fullscreen',
  'insertdatetime media table paste code help wordcount',
];

export const TYNIMCE_IMAGE_BUTTON = 'image';
export const TYNIMCE_IMAGE_BUTTON_ICON = 'image';

export const TYNIMCE_VIDEO_BUTTON = 'video';
export const TYNIMCE_VIDEO_BUTTON_ICON = 'embed';

export const TYNIMCE_TOOLBAR = `formatselect 
                        | bold italic underline bullist numlist undo redo
                        | alignleft aligncenter alignright
                        | link ${TYNIMCE_IMAGE_BUTTON} ${TYNIMCE_VIDEO_BUTTON}`;

export const TYNIMCE_API_KEY = process.env.REACT_APP_TYNIMCE_API_KEY;

export const TYNIMCE_MIN_HEIGHT = 200;

export const TYNIMCE_AUTORISIZE_BOTTOM_MARGIN = 20;

export const EXTENDED_VALID_ELEMENTS =
  'img[ta-insert-video|contenteditable|class|src|border=0|alt|title|hspace|vspace|width|height|align|onmouseover|onmouseout|name]';

export const TINYMCE_LOCALES_MAPPER: Record<LOCALES, string> = {
  [LOCALES.en]: 'en',
  [LOCALES.nb]: 'nb_NO',
  [LOCALES.pt]: 'pt_PT',
  [LOCALES.sv]: 'sv_SE',
  [LOCALES.da]: 'da',
};
