import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AvatarComponent } from '../../avatar.component';
import { ImageComponent } from '../../image.component';
import {
  DESKTOP_IMAGE_HEIGHT,
  DESKTOP_IMAGE_WIDTH,
  MOBILE_IMAGE_HEIGHT,
  MOBILE_IMAGE_WIDTH,
} from '../../profiles/profile-short-info.component';

interface ProfileShortInfoLoadingProps {
  mobile?: boolean;
  isExpanded?: boolean;
  onClickExpanded?: () => void;
}

function ProfileShortInfoLoadingComponentBase({
  mobile = false,
  isExpanded = false,
  onClickExpanded,
}: ProfileShortInfoLoadingProps) {
  const { t } = useTranslation();
  const bornLabelRef = useRef<HTMLDivElement>(null);

  const [labelMinWidth, setLabelMinWidth] = useState(0);

  useEffect(() => {
    const newMinWith = bornLabelRef.current?.offsetWidth || 0;

    if (labelMinWidth !== newMinWith) {
      setLabelMinWidth(newMinWith);
    }
  }, [bornLabelRef.current, labelMinWidth]);

  if (mobile) {
    return (
      <Box>
        <Skeleton variant="circular">
          <AvatarComponent
            sx={{
              width: mobile ? '48px' : '40px',
              height: mobile ? '48px' : '40px',
            }}
          />
        </Skeleton>
        <Stack
          sx={{ pt: 0.5 }}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <Typography
            whiteSpace={'normal'}
            fontWeight={500}
            variant={!mobile ? 'h6' : 'subtitle1'}
          >
            <Skeleton sx={{ width: '200px' }} />
          </Typography>
          <Button
            id="profile-read-more"
            size="small"
            sx={{
              textTransform: 'none',
              py: 0.75,
              mt: 1,
            }}
            variant="contained"
            disabled
          >
            {t('read-more.button')}
          </Button>
        </Stack>
      </Box>
    );
  }

  return (
    <Accordion
      expanded={isExpanded}
      onChange={onClickExpanded}
      sx={{
        boxShadow: 'none',
        minHeight: 'auto',
        '& .Mui-expanded': { minHeight: 'auto!important' },
      }}
    >
      <AccordionSummary
        sx={{
          px: 0,
          m: 0,
          '& .MuiAccordionSummary-content': { m: 0 },
          '& .Mui-expanded': { m: `0!important`, minHeight: 'auto' },
        }}
        expandIcon={<ExpandMoreIcon />}
      >
        <Fade in={!isExpanded}>
          <Stack direction="row" alignItems="center" spacing={{ xs: 1, md: 2 }}>
            <Skeleton variant="circular">
              <AvatarComponent
                sx={{
                  width: mobile ? '48px' : '40px',
                  height: mobile ? '48px' : '40px',
                }}
              />
            </Skeleton>
            <Typography
              whiteSpace={'normal'}
              fontWeight={500}
              variant={!mobile ? 'h6' : 'subtitle1'}
            >
              <Skeleton sx={{ width: '200px' }} />
            </Typography>
          </Stack>
        </Fade>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={{ xs: 1, md: 2 }}>
          <Grid item>
            <Box position="relative">
              <Skeleton component={'div'} sx={{ m: 'auto' }}>
                <ImageComponent
                  cardMediaSx={{
                    width: !mobile ? DESKTOP_IMAGE_WIDTH : MOBILE_IMAGE_WIDTH,
                    height: !mobile
                      ? DESKTOP_IMAGE_HEIGHT
                      : MOBILE_IMAGE_HEIGHT,
                  }}
                />
              </Skeleton>
            </Box>
            {mobile && (
              <Button
                id="profile-read-more"
                size="small"
                fullWidth
                endIcon={<VisibilityIcon />}
                sx={{
                  py: 0.75,
                  mt: 1,
                }}
                variant="outlined"
                disabled
              >
                {t('read-more.button')}
              </Button>
            )}
          </Grid>
          <Grid item xs container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid
              item
              xs
              container
              direction="column"
              spacing={{ xs: 1, sm: 1.5, md: 2.5 }}
            >
              <Grid item>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={{ xs: 0.5 }}
                >
                  <Typography width={'100%'} variant={!mobile ? 'h4' : 'h6'}>
                    <Skeleton
                      sx={(theme) => ({
                        width: '100%',
                        maxWidth: '450px',
                        [theme.breakpoints.down('sm')]: {
                          maxWidth: '200px',
                        },
                      })}
                    />
                  </Typography>
                  {mobile && (
                    <Skeleton
                      sx={() => ({
                        width: '35px',
                      })}
                    />
                  )}
                </Stack>
              </Grid>
              <Grid item>
                <Stack
                  spacing={{ xs: 1, md: 2 }}
                  direction="row"
                  alignItems="center"
                >
                  <Typography
                    ref={bornLabelRef}
                    minWidth={labelMinWidth}
                    variant={!mobile ? 'body1' : 'body2'}
                  >
                    {t('birth-date.label')}:
                  </Typography>

                  <Typography variant={!mobile ? 'body1' : 'body2'}>
                    <Skeleton
                      sx={(theme) => ({
                        width: '180px',
                        [theme.breakpoints.down('sm')]: {
                          width: '120px',
                        },
                      })}
                    />
                  </Typography>
                </Stack>
              </Grid>
              <Grid item>
                <Stack
                  spacing={{ xs: 1, md: 2 }}
                  direction="row"
                  alignItems="center"
                >
                  <Typography
                    ref={bornLabelRef}
                    minWidth={labelMinWidth}
                    variant={!mobile ? 'body1' : 'body2'}
                  >
                    {t('birth-place.label')}:
                  </Typography>

                  <Typography variant={!mobile ? 'body1' : 'body2'}>
                    <Skeleton
                      sx={(theme) => ({
                        width: '180px',
                        [theme.breakpoints.down('sm')]: {
                          width: '120px',
                        },
                      })}
                    />
                  </Typography>
                </Stack>
              </Grid>

              <Grid item>
                <Typography
                  variant={!mobile ? 'h5' : 'subtitle1'}
                  pt={{ xs: 1, md: 2 }}
                  sx={(theme) => ({
                    [theme.breakpoints.down('sm')]: {
                      fontWeight: 500,
                      mb: 0.5,
                    },
                  })}
                >
                  <Skeleton
                    sx={{
                      width: '100%',
                      maxWidth: '200px',
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  component={'div'}
                  variant={!mobile ? 'body1' : 'body2'}
                  whiteSpace={'pre-line'}
                  mt={-1}
                >
                  <Skeleton
                    sx={{
                      width: '100%',
                    }}
                  />
                </Typography>
                <Typography
                  mt={{ xs: 0.25, md: 0.5 }}
                  component={'div'}
                  variant={!mobile ? 'body1' : 'body2'}
                  whiteSpace={'pre-line'}
                >
                  <Skeleton
                    sx={{
                      width: '100%',
                    }}
                  />
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                sx={{
                  height: '100%',
                }}
                container
                direction="column"
                justifyContent="space-between"
                alignItems="flex-end"
              >
                {!mobile && (
                  <Grid item>
                    <Skeleton
                      sx={(theme) => ({
                        height: theme.spacing(4.5),
                        width: '100%',
                        minWidth: '75px',
                        [theme.breakpoints.down('sm')]: {
                          minWidth: '25px',
                        },
                      })}
                    />
                  </Grid>
                )}
                <Grid item>
                  {!mobile && (
                    <Button id="profile-read-more" variant="contained" disabled>
                      {t('read-more.button')}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

export const ProfileShortInfoLoadingComponent = memo(
  ProfileShortInfoLoadingComponentBase,
);
