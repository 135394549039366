import orderBy from 'lodash/orderBy';

const DEFAULT_NOT_FOUND_PARENT_GROUP_NAME = 'No Available Groups';

export function getGroupsAndOptionsForFilters<
  T extends { id: string; name: string; parentIds?: string[] },
>(
  groupsArr: T[],
  itemsArr: T[],
  notFoundGroupName = DEFAULT_NOT_FOUND_PARENT_GROUP_NAME,
) {
  const groupsObj: Record<string, { group: T; options: T[] }> = {};
  const itemOptions: T[] = [];
  const notFoundParentOptions: T[] = [];

  const n = itemsArr.length;
  for (let i = 0; i < n; i++) {
    const item = itemsArr[i];
    if (!item) {
      continue;
    }
    const m = (item.parentIds || []).length;
    if (m === 0) {
      itemOptions.push(item);
      continue;
    }

    for (let j = 0; j < m; j++) {
      const parentId = item.parentIds?.[j];

      if (!parentId) {
        continue;
      }

      const parent = groupsArr.find(({ id }) => id === parentId);

      if (!parent && !notFoundParentOptions.some(({ id }) => id === item.id)) {
        notFoundParentOptions.push(item);
        continue;
      }

      if (!parent) {
        continue;
      }

      if (!groupsObj[parentId]) {
        groupsObj[parentId] = {
          group: parent,
          options: [],
        };
      }

      if (!groupsObj[parentId].options.some(({ id }) => id === item.id)) {
        groupsObj[parentId].options.push(item);
      }
    }
  }
  const itemGroups: {
    groupName: string;
    options: T[];
  }[] = [];

  for (const key in groupsObj) {
    itemGroups.push({
      groupName: groupsObj[key].group.name,
      options: orderBy(groupsObj[key].options, ['name'], ['asc']),
    });
  }
  return {
    groups: notFoundParentOptions.length
      ? [
          ...orderBy(itemGroups, ['groupName'], ['asc']),
          {
            groupName: notFoundGroupName,
            options: orderBy(notFoundParentOptions, ['name'], ['asc']),
          },
        ]
      : orderBy(itemGroups, ['groupName'], ['asc']),
    options: orderBy(itemOptions, ['name'], ['asc']),
  };
}
