import AddIcon from '@mui/icons-material/Add';
import Grid from '@mui/material/Grid';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { EmptyScreenComponent } from '../../components/empty-screen.component';
import { FailedLoadingDataComponent } from '../../components/failed-loading-data.component';
import { RelativesListLoadingComponent } from '../../components/loading/relatives/relatives-list-loading.component';
import { PatientNestedItemsInfiniteScrollComponent } from '../../components/patients/patient-nested-items-infinite-scroll.component';
import { PatientNestedItemsWrapperComponent } from '../../components/patients/patient-nested-items-wrapper.component';
import { RelativeCardComponent } from '../../components/relatives/relative-card.component';
import { usePatient } from '../../hooks/patients/use-patient';
import { usePatientNestedItems } from '../../hooks/patients/use-patient-nested-items';
import { usePatientRole } from '../../hooks/patients/use-patient-role';
import { useCreateButton } from '../../hooks/use-create-button';
import { useStores } from '../../hooks/use-stores';
import { Relative } from '../../interfaces/entities/relative.interface';
import { ProfilePageProps } from '../../interfaces/profile-page-props.interface';

const LIMIT = 30;

function RelativesList({ profileId }: ProfilePageProps) {
  const { relativesStore: store } = useStores();

  const { t } = useTranslation();

  const { items, loading, hasMore, loadMore, errorLoading, reload } =
    usePatientNestedItems(store, profileId, LIMIT);

  const { isPatientRoleAdmin } = usePatientRole(profileId);
  const { item } = usePatient(profileId);

  const { onClick: onClickCreate } = useCreateButton();

  const showCreateButton = useMemo(() => {
    return isPatientRoleAdmin && items?.length > 0;
  }, [isPatientRoleAdmin, items?.length]);

  if (loading || !items) {
    return <RelativesListLoadingComponent />;
  }

  if (loading === false && errorLoading) {
    return <FailedLoadingDataComponent onClick={reload} />;
  }

  return (
    <>
      <PatientNestedItemsWrapperComponent
        onClickCreate={showCreateButton ? onClickCreate : undefined}
      >
        <PatientNestedItemsInfiniteScrollComponent
          dataLength={items.length}
          hasMore={hasMore}
          loadMore={loadMore}
        >
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={6}
          >
            {items.length ? (
              items.map((item: Relative) => (
                <Grid key={item.id} item xs={12} sm={6} md={3} lg={2}>
                  <RelativeCardComponent {...item} />
                </Grid>
              ))
            ) : (
              <Grid item width="100%">
                <EmptyScreenComponent
                  title={`${t('relatives-list-empty.header')}`}
                  description={`${t('relatives-list-empty.body', {
                    firstName: item?.firstName,
                  })}`}
                  icon={<AddIcon sx={{ pl: '2px' }} />}
                  onClick={onClickCreate}
                  text={`${t('relatives-list-empty-create-relation.button')}`}
                  hasContent={isPatientRoleAdmin}
                />
              </Grid>
            )}
          </Grid>
        </PatientNestedItemsInfiniteScrollComponent>
      </PatientNestedItemsWrapperComponent>
    </>
  );
}

export const RelativesListContainer = observer(RelativesList);
