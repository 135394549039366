import AppsIcon from '@mui/icons-material/Apps';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import { SubNavItem } from '../interfaces/sub-nav-item.interface';
import { SUPPORT_URL } from '../utils/constants';
import { CREATE_PATIENT_ROUTE, MY_PATIENTS_ROUTE } from './routes';

export const SUB_NAV_ITEM_MY_PATIENTS: SubNavItem = {
  to: MY_PATIENTS_ROUTE.path,
  label: MY_PATIENTS_ROUTE.title as string,
  Icon: AppsIcon,
};

export const SUB_NAV_ITEM_SUPPORT: SubNavItem = {
  href: SUPPORT_URL,
  label: 'support',
  Icon: MenuBookIcon,
  target: '_blank',
};

export const SUB_NAV_ITEM_CREATE_PATIENT: SubNavItem = {
  to: CREATE_PATIENT_ROUTE.path,
  label: CREATE_PATIENT_ROUTE.title as string,
  Icon: PersonAddIcon,
};
