import Badge from '@mui/material/Badge';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { MY_PATIENTS_ROUTE } from '../../configs/routes';
import { Patient as PatientProps } from '../../interfaces/entities/patient.interface';
import { BLACK_COLOR, WHITE_COLOR } from '../../theme';
import getAge from '../../utils/get-age';
import getName from '../../utils/get-name';
import { AvatarComponent } from '../avatar.component';

export type PatientCardProps = PatientProps;
const BADGE_SIZE = 46;

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    fontSize: `${BADGE_SIZE / 2}px`,
    height: `${BADGE_SIZE}px`,
    borderRadius: `${BADGE_SIZE / 2}px`,
    width: `${BADGE_SIZE}px`,
    backgroundColor: theme.palette.primary.main,
    color: WHITE_COLOR,
    boxShadow: `0 0 0 1px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.5s infinite ease-in-out',
      border: `2px solid ${theme.palette.primary.main}`,
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(1.4)',
      opacity: 0,
    },
  },
}));

function PatientCard(props: PatientCardProps) {
  const { t } = useTranslation();

  const {
    id,
    firstName,
    lastName,
    birthDate,
    image,
    nickname,
    newGuestbookPostsCount,
  } = props;
  const name = getName(firstName, lastName);
  const age = birthDate && getAge(birthDate);

  return (
    <Link
      underline="none"
      to={`${MY_PATIENTS_ROUTE.path}/${id}`}
      component={RouterLink}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <StyledBadge
            overlap="circular"
            badgeContent={newGuestbookPostsCount}
            color="primary"
            max={99}
          >
            <AvatarComponent
              id={`profile-${id}`}
              name={name}
              src={image || ''}
              sx={(theme) => {
                const styles: Record<string, any> = {
                  height: '166px',
                  width: '166px',
                  fontSize: theme.typography.h2.fontSize,
                  fontWeight: theme.typography.h2.fontWeight,
                  fontStyle: theme.typography.h2.fontStyle,
                  lineHeight: theme.typography.h2.lineHeight,
                  letterSpacing: theme.typography.h2.letterSpacing,
                };

                if (newGuestbookPostsCount && newGuestbookPostsCount > 0) {
                  styles.border = '1px solid';
                  styles.color = WHITE_COLOR;
                  styles.boxShadow = `0 0 0 3px ${theme.palette.primary.main}`;
                }
                return styles;
              }}
            />
          </StyledBadge>
        </Grid>
        <Grid item>
          <Typography
            variant="body1"
            color={BLACK_COLOR}
            textAlign={'center'}
            paddingTop={2}
          >
            {name}
          </Typography>
        </Grid>
        {nickname && (
          <Grid item>
            <Typography
              variant="body1"
              color={BLACK_COLOR}
              textAlign={'center'}
            >
              ({nickname})
            </Typography>
          </Grid>
        )}
        {!!age && (
          <Grid item>
            <Typography variant="body2" color="primary" paddingTop={1}>
              {`${age} ${t('persona-age.label')}`}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Link>
  );
}

export const PatientCardComponent = memo(PatientCard);
