import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

interface GiveAccessToNursesButtonProps extends ButtonProps {
  loading?: boolean;
}

function GiveAccessToNursesButton({
  loading,
  ...props
}: GiveAccessToNursesButtonProps) {
  const { t } = useTranslation();

  return (
    <Button variant="outlined" {...props} disabled={props.disabled || loading}>
      <>
        {t('access-list-access-to-department.button')}
        {loading && <CircularProgress sx={{ ml: 1 }} size={16} />}
      </>
    </Button>
  );
}

export const GiveAccessToNursesButtonComponent = memo(GiveAccessToNursesButton);
