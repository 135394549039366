import { User } from '../interfaces/user.interface';

export function getEmails(emails: User['emails']): string {
  return (emails || []).reduce((str, { email }, index, arr) => {
    if (index < arr.length - 1) {
      return `${str}${email} | `;
    }

    return `${str}${email}`;
  }, '');
}
