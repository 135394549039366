import { useCallback, useEffect, useState } from 'react';

import { Patient } from '../../interfaces/entities/patient.interface';
import { useStores } from '../use-stores';

export const usePatient = (
  id: Patient['id'],
): {
  item: Patient | null;
  fetchLoading?: boolean;
  updateLoading?: boolean;
  update: (data: Partial<Patient>) => Promise<void>;
  updateWithPhoto: (
    data: Partial<Patient>,
    photo: File,
    field?: string,
  ) => Promise<void>;
} => {
  const { patientsStore: store } = useStores();

  const [updateLoading, setUpdateLoading] = useState(false);

  useEffect(() => {
    store.one(id);
  }, [store.one, id]);

  const update = useCallback(
    async (data: Partial<Patient>) => {
      setUpdateLoading(true);
      await store.update(id, data);
      setUpdateLoading(false);
    },
    [store.update, setUpdateLoading, id],
  );

  const updateWithPhoto = useCallback(
    async (data: Partial<Patient>, photo: File, field = 'image') => {
      setUpdateLoading(true);
      await store.updateWithPhoto(id, data, photo, field);
      setUpdateLoading(false);
    },
    [store.updateWithPhoto, setUpdateLoading, id],
  );

  return {
    item: store.item,
    fetchLoading: store.loadingOne,
    updateLoading,
    update,
    updateWithPhoto,
  };
};
