import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { memo, ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useIntercom } from 'react-use-intercom';

import { Unit } from '../../interfaces/entities/unit.interface';
import { User } from '../../interfaces/user.interface';
import { LOCALES } from '../../utils/constants';
import { formatUnitsToIntercom } from '../../utils/format-units-to-intercom';
import getName from '../../utils/get-name';

const INTERCOM_LANGUAGE_ATTRIBUTE = 'MinMemoria language';
const INTERCOM_UNITS_ATTRIBUTE = 'Units';
const INTERCOM_USER_ROLE_ATTRIBUTE = 'User role';

const SUPPORTED_LANGUAGES: Record<string, string> = {
  [LOCALES.nb]: 'nb',
  [LOCALES.sv]: 'sv',
};

function IntercomProviderChildren({
  children,
  user,
  loadingUser,
  units,
  loadingUnits,
}: {
  children: ReactNode;
  user?: User;
  loadingUser?: boolean;
  units?: Unit[];
  loadingUnits?: boolean;
}) {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const { i18n } = useTranslation();
  const { update, shutdown, boot } = useIntercom();

  useEffect(() => {
    if (loadingUser) {
      return;
    }
    if (!user) {
      shutdown();
      return boot({
        customAttributes: {
          [INTERCOM_LANGUAGE_ATTRIBUTE]: i18n.language,
        },
      });
    }

    update({
      userId: user.vilmerId?.toString() || user.id,
      email: user.emails?.[0]?.email,
      name: getName(user.firstName, user.lastName),
      ...(user.role
        ? {
            customAttributes: {
              [INTERCOM_USER_ROLE_ATTRIBUTE]: user.role,
            },
          }
        : {}),
    });
  }, [user, loadingUser, update, shutdown, boot, i18n.language]);

  useEffect(() => {
    update({
      languageOverride:
        SUPPORTED_LANGUAGES[user?.language || i18n.language] ||
        SUPPORTED_LANGUAGES.nb,
      customAttributes: {
        [INTERCOM_LANGUAGE_ATTRIBUTE]: user?.language || i18n.language,
      },
    });
  }, [user?.language, i18n.language]);

  useEffect(() => {
    if (loadingUnits !== false || !units) {
      return;
    }

    update({
      customAttributes: {
        [INTERCOM_UNITS_ATTRIBUTE]: formatUnitsToIntercom(units),
      },
    });
  }, [units, loadingUnits]);

  update({ hideDefaultLauncher: mobileView });

  return <>{children}</>;
}

export const IntercomProviderChildrenComponent = memo(IntercomProviderChildren);
