import { AxiosInstance } from 'axios';
import { action, makeObservable, observable, runInAction } from 'mobx';

import { AccessContact } from '../interfaces/access-contact.interface';
import { Access, ACCESS_ROLES } from '../interfaces/entities/access.interface';
import { Patient } from '../interfaces/entities/patient.interface';
import { Unit } from '../interfaces/entities/unit.interface';
import { GetManyResponse } from '../interfaces/get-many-response.interface';
import { User } from '../interfaces/user.interface';

const FETCH_ITEMS_BY_PATIENT_ID_LIMIT = 100;
const FETCH_ITEMS_BY_PATIENT_INSTITUTION_ID_LIMIT = 100;

export class AccessesStore {
  contactsByPatientId: Record<Patient['id'], ReadonlyArray<AccessContact>> = {};
  allItemsByPatientId: Record<Patient['id'], Access[]> = {};
  allItemsByPatientAndDepartmentId: Record<string, Access[]> = {};
  loadingAllItemsByPatientId: Record<Patient['id'], boolean> = {};
  loadingAllItemsByPatientAndDepartmentId: Record<string, boolean> = {};
  errorLoadingByPatientId: Record<Patient['id'], Error | undefined> = {};

  private readonly entitiesName = 'accesses';

  constructor(private readonly api: AxiosInstance) {
    makeObservable(this, {
      allItemsByPatientId: observable,
      contactsByPatientId: observable,
      allItemsByPatientAndDepartmentId: observable,
      loadingAllItemsByPatientId: observable,
      loadingAllItemsByPatientAndDepartmentId: observable,
      errorLoadingByPatientId: observable,
      fetchAccessContacts: action,
      fetchAllItemsByPatientId: action,
      fetchAllItemsByPatientAndDepartmentId: action,
      deleteAccessesByPatientId: action,
      createManyAccessesByPatientAndDepartmentId: action,
    });
  }

  async fetchAccessContacts(patientId: Patient['id']) {
    try {
      const { data } = await this.api.get<ReadonlyArray<AccessContact>>(
        this.getUrlByPatientId(patientId, '/contacts'),
      );
      runInAction(() => {
        this.contactsByPatientId[patientId] = data;
      });
    } catch (err) {
      console.error(err);
      this.contactsByPatientId[patientId] = [];
    }
  }

  async fetchAllItemsByPatientId(patientId: Patient['id'], force = false) {
    const key = patientId;
    if (!force && this.allItemsByPatientId[key]) {
      return;
    }

    if (this.loadingAllItemsByPatientId[key]) {
      return;
    }

    runInAction(() => {
      this.loadingAllItemsByPatientId[key] = true;
    });
    let more = true;
    let page = 1;
    const arr: Access[] = [];
    while (more) {
      const searchParams = new URLSearchParams();
      searchParams.append('page', '' + page);
      searchParams.append('limit', '' + FETCH_ITEMS_BY_PATIENT_ID_LIMIT);
      searchParams.append('sort', `user.firstName,ASC`);
      searchParams.append('join', 'user');

      try {
        const {
          data: { data, pageCount },
        } = await this.api.get<GetManyResponse<Access>>(
          `v2/${this.getUrlByPatientId(patientId)}?${searchParams.toString()}`,
        );
        arr.push(...data);
        more = (pageCount || 0) > page;
        page += 1;
      } catch (err) {
        console.error(err);
        break;
      }

      runInAction(() => {
        this.allItemsByPatientId[key] = arr;
        this.loadingAllItemsByPatientId[key] = false;
      });
    }
  }

  async fetchAllItemsByPatientAndDepartmentId(
    patientId: Patient['id'],
    departmentId: Unit['id'],
    force = false,
  ) {
    const key = this.getKeyForPatientAndDepartment(patientId, departmentId);
    if (!force && this.allItemsByPatientAndDepartmentId[key]) {
      return;
    }

    if (this.loadingAllItemsByPatientAndDepartmentId[key]) {
      return;
    }

    runInAction(() => {
      this.loadingAllItemsByPatientAndDepartmentId[key] = true;
      this.errorLoadingByPatientId[patientId] = undefined;
    });
    let more = true;
    let page = 1;
    const arr: Access[] = [];
    while (more) {
      const searchParams = new URLSearchParams();
      searchParams.append('page', '' + page);
      searchParams.append(
        'limit',
        '' + FETCH_ITEMS_BY_PATIENT_INSTITUTION_ID_LIMIT,
      );
      searchParams.append('sort', `user.firstName,ASC`);
      searchParams.append('join', 'user');
      searchParams.append('join', 'user.userToUnits');
      searchParams.append('join', 'user.userToUnits.unit');

      const search: Record<string, any> = {
        $or: [
          {
            $and: [
              { 'user.userToUnits.unitId': departmentId },
              { 'user.userToUnits.deactivatedAt': { $isnull: 1 } },
            ],
          },
        ],
      };
      searchParams.append('s', JSON.stringify(search));

      try {
        const {
          data: { data, pageCount },
        } = await this.api.get<GetManyResponse<Access>>(
          `v2/${this.getUrlByPatientId(patientId)}?${searchParams.toString()}`,
        );
        arr.push(...data);
        more = (pageCount || 0) > page;
        page += 1;
      } catch (err) {
        console.error(err);
        runInAction(() => {
          this.errorLoadingByPatientId[patientId] = err as Error;
        });
        break;
      }

      runInAction(() => {
        this.allItemsByPatientAndDepartmentId[key] = arr;
        this.loadingAllItemsByPatientAndDepartmentId[key] = false;
      });
    }
  }

  async createManyAccessesByPatientAndDepartmentId(
    patientId: Patient['id'],
    departmentId: Unit['id'],
    data: { userId: User['id']; role: ACCESS_ROLES }[],
  ): Promise<void> {
    try {
      const { data: newAccesses } = await this.api.post<Access[]>(
        `v2/${this.getUrlByPatientId(patientId)}/bulk`,
        {
          bulk: data.map((item) => ({ ...item, departmentId })),
        },
      );
      runInAction(() => {
        for (const key in this.allItemsByPatientAndDepartmentId) {
          if (
            key.startsWith(this.getKeyForPatientAndDepartment(patientId, '')) &&
            this.allItemsByPatientAndDepartmentId[key]
          ) {
            this.allItemsByPatientAndDepartmentId[key] = [
              ...this.allItemsByPatientAndDepartmentId[key],
              ...newAccesses,
            ];
          }
        }
      });
    } catch (err) {
      console.error(err);
    }
  }

  async deleteAccessesByPatientId(
    patientId: Patient['id'],
    id: Access['id'],
  ): Promise<void> {
    try {
      await this.api.delete(`v2/${this.getUrlByPatientId(patientId)}/${id}`);
      runInAction(() => {
        for (const key in this.allItemsByPatientAndDepartmentId) {
          if (
            key.startsWith(this.getKeyForPatientAndDepartment(patientId, '')) &&
            this.allItemsByPatientAndDepartmentId[key]
          ) {
            this.allItemsByPatientAndDepartmentId[key] =
              this.allItemsByPatientAndDepartmentId[key].filter(
                (access) => access.id !== id,
              );
          }
        }
      });
    } catch (err) {
      console.error(err);
    }
  }

  getKeyForPatientAndDepartment(
    patientId: Patient['id'],
    departmentId: Unit['id'],
  ): string {
    return `patient-${patientId}-department-${departmentId}`;
  }

  private getUrlByPatientId(patientId: string, end?: string): string {
    const patientsUrl = `patients/${patientId}/${this.entitiesName}`;
    return !end ? patientsUrl : `${patientsUrl}${end}`;
  }
}
