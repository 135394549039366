import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { memo } from 'react';

interface PatientFormWrapperProps {
  title?: string;
  children: React.ReactNode;
}

function PatientFormWrapper({ title, children }: PatientFormWrapperProps) {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box>
      {title && (
        <Typography
          variant={mobileView ? 'h5' : 'h4'}
          color={'primary.dark'}
          fontWeight={700}
          pb={3}
        >
          {title}
        </Typography>
      )}
      {children}
    </Box>
  );
}

export const PatientFormWrapperComponent = memo(PatientFormWrapper);
