import CloseIcon from '@mui/icons-material/Close';
import InstallMobileIcon from '@mui/icons-material/InstallMobile';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { memo, MouseEvent, useCallback } from 'react';
import { browserName, browserVersion, isIOS } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import { useInstallWidget } from '../../../hooks/use-install-widget';
import { NavigationProps } from '../../../interfaces/navigation-props.interface';
import { LOGO_WITHOUT_TEXT_PATH } from '../../../utils/constants';
import { InstallPWADialogComponent } from '../../dialog/install-pwa-dialog.component';
import { MobileNavigationMenuItemComponent } from './mobile-navigation-menu-item.component';

interface MobileNavigationMenuProps {
  open?: boolean;
  onClose?: () => void;
  items: NavigationProps['items'];
  logged?: boolean;
}

function MobileNavigationMenu(props: MobileNavigationMenuProps) {
  const { t } = useTranslation();

  const {
    isInstalled,
    hiddenByDelay,
    onClickInstall,
    showDialog,
    onCloseDialog,
    onConfirmDialog,
  } = useInstallWidget();

  const { open, onClose, items = [], logged } = props;

  const onClickInstallStack = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      event.preventDefault();
    },
    [],
  );

  const onClickInstallButton = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      event.preventDefault();
      if (onClickInstall) {
        onClickInstall();
      }
    },
    [onClickInstall],
  );

  return (
    <Drawer
      variant="temporary"
      anchor={'left'}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          minWidth: '75%',
        },
      }}
      ModalProps={{
        keepMounted: true,
      }}
      sx={(theme) => ({
        zIndex: theme.zIndex.drawer + 3,
      })}
    >
      <Box
        sx={{
          height: '56px',
          '&>div': {
            height: '100%',
          },
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item>
            <IconButton
              onClick={onClose}
              sx={{
                color: 'rgba(0, 0, 0, 0.87)',
                mr: 'auto',
                ml: 0,
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <Typography
              variant="h6"
              sx={{ color: 'rgba(0, 0, 0, 0.87)', padding: '12px' }}
            >
              {t('menu')}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Divider
        sx={{
          height: '1px',
          background: 'rgba(33, 33, 33, 0.08)',
        }}
      />
      <Box role="presentation" onClick={onClose} onKeyDown={onClose}>
        <List component="nav" disablePadding>
          {items.map((item, index) =>
            item.element ? (
              <Box key={index}>{item.element}</Box>
            ) : (
              <MobileNavigationMenuItemComponent {...item} key={index} />
            ),
          )}
        </List>
        {!!logged && !isInstalled && !hiddenByDelay && (
          <>
            <Divider sx={{ pt: 5 }} />
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
              sx={{
                pr: 1.5,
              }}
              onClick={onClickInstallStack}
            >
              <ListItem>
                <ListItemAvatar sx={{ display: 'flex', minWidth: '40px' }}>
                  <img height={36} src={LOGO_WITHOUT_TEXT_PATH} />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle2"
                      color={'rgba(0, 0, 0, 0.87)'}
                      fontWeight={600}
                    >
                      {t('logo-primary.title')}
                    </Typography>
                  }
                  secondary={
                    <Typography variant="body2" color={'rgba(0, 0, 0, 0.87)'}>
                      {t('logo-secondary.title')}
                    </Typography>
                  }
                />
              </ListItem>

              <Button
                variant="contained"
                disableElevation
                sx={{
                  py: 1.5,
                }}
                startIcon={<InstallMobileIcon />}
                onClick={onClickInstallButton}
              >
                {t('install.button')}
              </Button>
            </Stack>
          </>
        )}
      </Box>
      <InstallPWADialogComponent
        isOpen={showDialog}
        onClose={onCloseDialog}
        onConfirm={onConfirmDialog}
        browserName={browserName}
        browserVersion={browserVersion}
        isIOS={isIOS}
      />
    </Drawer>
  );
}

export const MobileNavigationMenuComponent = memo(MobileNavigationMenu);
