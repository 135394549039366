export function findOptionForMultiSelectChipLabel(
  value: string,
  options: { id: string; name: string }[],
  groups: { groupName: string; options: { id: string; name: string }[] }[],
): string {
  const n = options.length;
  for (let i = 0; i < n; i++) {
    if (options[i].id === value) {
      return options[i].name;
    }
  }

  const m = groups.length;
  for (let i = 0; i < m; i++) {
    const k = groups[i].options.length;
    for (let j = 0; j < k; j++) {
      if (groups[i].options[j].id === value) {
        return groups[i].options[j].name;
      }
    }
  }
  return value;
}
