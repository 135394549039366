import Grid from '@mui/material/Grid';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BackButtonComponent } from '../../components/buttons/back-button.component';
import { ConfirmLeaveDialogComponent } from '../../components/dialog/confirm-leave-dialog.component';
import { BackdropLoadingComponent } from '../../components/loading/backdrop-loading.component';
import { PROFILE_ROUTE_STORIES } from '../../configs/profile-routes';
import { MY_PATIENTS_ROUTE } from '../../configs/routes';
import { StoryForm } from '../../forms/story.form';
import { usePatientRole } from '../../hooks/patients/use-patient-role';
import { useFormActions } from '../../hooks/use-form-actions';
import { useStores } from '../../hooks/use-stores';
import { Story } from '../../interfaces/entities/story.interface';
import { ProfilePageProps } from '../../interfaces/profile-page-props.interface';

function StoriesNew({ profileId }: ProfilePageProps) {
  const { storiesStore: store } = useStores();
  const { isPatientRoleAdmin } = usePatientRole(profileId);
  const [loading, setLoading] = useState(false);
  const [blockLeave, setBlockLeave] = useState<boolean>(true);
  const { t } = useTranslation();

  const backPath = `${MY_PATIENTS_ROUTE.path}/${profileId}/${PROFILE_ROUTE_STORIES.path}`;

  const { onCancel, onConfirm } = useFormActions(backPath);

  const onSave = useCallback(
    async (data: Partial<Story>) => {
      setLoading(true);
      await store.createByPatientId(profileId, data);
      setLoading(false);
      setBlockLeave(false);
      onConfirm();
      setBlockLeave(true);
    },
    [onConfirm, profileId, setLoading, store.createByPatientId],
  );

  if (!isPatientRoleAdmin) {
    return <></>;
  }

  return (
    <>
      <Grid container direction="row" spacing={1}>
        <Grid item xs={12}>
          <BackButtonComponent path={backPath} />
        </Grid>
        <Grid item xs={12}>
          <StoryForm
            onSave={onSave}
            onCancel={onCancel}
            resetFormAfterCancel={false}
          />
        </Grid>
      </Grid>
      <BackdropLoadingComponent open={loading} />
      <ConfirmLeaveDialogComponent
        blockLeave={blockLeave}
        description={t('modal-leave-story-warning.body')}
        stayButtonText={t('modal-leave-story-warning-stay.button')}
        leaveButtonText={t('modal-leave-story-warning-leave.button')}
      />
    </>
  );
}

export const StoriesNewContainer = observer(StoriesNew);
