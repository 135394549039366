const DEFAULT_API_ID_DEVELOPMENT = 'e3x5ugea';

class IntercomConfig {
  constructor(
    public readonly applicationId = process.env.REACT_APP_INTERCOM_API_ID ||
      DEFAULT_API_ID_DEVELOPMENT,
  ) {}
}

const config = new IntercomConfig();

export default config;
