import { Unit } from '../interfaces/entities/unit.interface';

export function findUnitOptionsByParentId(
  units: Unit[],
  parentId?: Unit['id'],
): Unit[] {
  return !parentId
    ? units
    : units.filter(
        ({ parents }) => parents && parents.some(({ id }) => id === parentId),
      );
}
