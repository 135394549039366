import ReplayIcon from '@mui/icons-material/Replay';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { memo, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { useInstallWidget } from '../hooks/use-install-widget';
import { TryAgainButtonComponent } from './buttons/try-again-button.component';

interface FailedLoadingDataProps {
  title?: string;
  onClick?: () => void;
  text?: string;
  icon?: ReactNode;
}

const BOX_STYLE = {
  position: 'fixed',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  bottom: '70px',
  padding: '20px 10px 36px',
  background: '#FFFFFF',
};

function FailedLoadingData({
  title,
  onClick,
  text,
  icon,
}: FailedLoadingDataProps) {
  const { t } = useTranslation();
  const { show: showInstallWidget } = useInstallWidget();

  return (
    <Grid
      direction="column"
      container
      alignItems="center"
      sx={(theme) => ({
        paddingTop: '100px',
        [theme.breakpoints.up('sm')]: {
          pt: 6,
        },
      })}
    >
      <Typography
        variant={'h6'}
        pb={4}
        textAlign="center"
        sx={(theme) => ({
          [theme.breakpoints.up('sm')]: {
            fontSize: '34px',
          },
        })}
      >
        {title || t('failed-loading.error')}
      </Typography>

      <Box
        sx={(theme) => ({
          [theme.breakpoints.down('sm')]: {
            ...BOX_STYLE,
            bottom: !showInstallWidget ? BOX_STYLE.bottom : '166px',
          },
        })}
      >
        <TryAgainButtonComponent
          startIcon={icon || <ReplayIcon />}
          onClick={onClick}
          variant="contained"
          color="secondary"
        >
          {text || t('try-again.button')}
        </TryAgainButtonComponent>
      </Box>
    </Grid>
  );
}

export const FailedLoadingDataComponent = memo(FailedLoadingData);
