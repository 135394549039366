import Box from '@mui/material/Box';
import Dialog, { DialogProps as MuiDialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { useTheme } from '@mui/material/styles';
import { TransitionProps } from '@mui/material/transitions';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { memo, ReactNode, useMemo } from 'react';

import { CloseButtonComponent } from '../buttons/close-button.component';
import { IconCloseButtonComponent } from '../buttons/icon-close-button.component';

const DIALOG_TIMEOUT = {
  appear: 600,
  enter: 600,
  exit: 300,
};

const DIALOG_DIRECTION = 'down';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return (
    <Slide
      {...props}
      direction={DIALOG_DIRECTION}
      ref={ref}
      timeout={DIALOG_TIMEOUT}
    />
  );
});

export interface DialogProps {
  onClose?: () => void;
  isOpen: boolean;
  title?: string | ReactNode;
  description?: string | ReactNode;
  children?: ReactNode;
  fullScreen?: boolean;
  maxWidth?: MuiDialogProps['maxWidth'];
  CloseButton?: ReactNode;
}

function DialogMessage({
  onClose,
  isOpen,
  title,
  description,
  children,
  fullScreen,
  maxWidth,
  CloseButton,
}: DialogProps) {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const closeButton = useMemo(() => {
    if (CloseButton) {
      return CloseButton;
    }

    if (mobileView) {
      return <IconCloseButtonComponent />;
    }

    return <CloseButtonComponent />;
  }, [CloseButton, mobileView]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      TransitionComponent={Transition}
      keepMounted
      fullScreen={fullScreen}
      maxWidth={maxWidth}
    >
      <DialogTitle id="alert-dialog-title">
        {title}
        {onClose && (
          <Box
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.secondary.main,
            }}
          >
            {closeButton}
          </Box>
        )}
      </DialogTitle>
      <DialogContent
        sx={(theme) => ({
          [theme.breakpoints.down('sm')]: {
            px: 1.25,
          },
        })}
      >
        {description && (
          <DialogContentText component={'div'} id="alert-dialog-description">
            {description}
          </DialogContentText>
        )}
      </DialogContent>
      {children}
    </Dialog>
  );
}

export const DialogComponent = memo(DialogMessage);
