import Backdrop, { BackdropProps } from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import React, { memo, ReactElement } from 'react';

function BackdropLoading(props: BackdropProps): ReactElement {
  return (
    <Backdrop
      {...props}
      sx={{
        ...{
          backgroundColor: 'rgba(255, 255, 255, 0.5)',
          zIndex: (theme) => theme.zIndex.drawer + 10,
        },
        ...props.sx,
      }}
    >
      <CircularProgress color="primary" />
    </Backdrop>
  );
}

export const BackdropLoadingComponent = memo(BackdropLoading);
