import { Field, FieldProps, Form, Formik, FormikProps } from 'formik';
import React, { forwardRef, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Patient } from '../../interfaces/entities/patient.interface';
import { validateBirthDate } from '../helpers/validate-birth-date';
import { DateField } from './shared/date-field.component';

type PatientBirthDate = Pick<Patient, 'birthDate'>;

interface PatientNameFormProps extends PatientBirthDate {
  onChange: (data: PatientBirthDate) => void;
}

function PatientBirthDateFormBase(
  { onChange, ...props }: PatientNameFormProps,
  ref: React.Ref<FormikProps<Patient>>,
) {
  const { t } = useTranslation();

  const validate = useCallback(
    (value: Date | null) => {
      return validateBirthDate(t, value);
    },
    [t],
  );
  return (
    <Formik<PatientBirthDate>
      initialValues={props || {}}
      onSubmit={onChange}
      innerRef={ref as React.Ref<FormikProps<PatientBirthDate>>}
    >
      {() => (
        <Form>
          <Field name="birthDate" validate={validate}>
            {(props: FieldProps) => {
              return (
                <DateField
                  {...props}
                  assistiveText={t('patient-edit-birth-date.assistive')}
                  label={t('patient-edit-birth-date.label')}
                  assistiveTextPosition="top"
                />
              );
            }}
          </Field>
        </Form>
      )}
    </Formik>
  );
}

export const PatientBirthDateForm = memo(forwardRef(PatientBirthDateFormBase));
