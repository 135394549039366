import { useEffect } from 'react';

import { Unit } from '../../interfaces/entities/unit.interface';
import { useStores } from '../use-stores';

export const useAllUnits = (): {
  items?: ReadonlyArray<Unit>;
  loading?: boolean;
} => {
  const { unitsStore: store } = useStores();

  useEffect(() => {
    store.fetchAllUnits();
  }, [store.fetchAllUnits]);

  return {
    items: store.allUnits,
    loading: store.loadingAllUnits,
  };
};
