import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { isIOS } from 'react-device-detect';

const LOCAL_STORAGE_KEY = 'install-widget-closed';
const LOCAL_STORAGE_TRUE_VALUE = 'true';
const SHOW_IOS_DELAY = 5 * 1000; // ms
const SHOW_DELAY = 20 * 1000; // ms

export function useInstallWidget(): {
  onClickClose?: () => void;
  onClickInstall?: () => void;
  show?: boolean;
  showDialog: boolean;
  onCloseDialog: () => void;
  onConfirmDialog: () => void;
  isInstalled?: boolean;
  hiddenByDelay?: boolean;
  onSuccessInstall?: boolean;
} {
  const theme = useTheme();
  const desktopView = useMediaQuery(theme.breakpoints.up('lg'));
  const displayModeStandalone = useMediaQuery('(display-mode: standalone)');

  const [deferredPrompt, setDeferredPrompt] = useState<Event | null>(null);

  const [isWidgetClosed, setIsWidgetClosed] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [hiddenByDelay, setHiddenByDelay] = useState(true);

  useEffect(() => {
    const isClosed = localStorage.getItem(LOCAL_STORAGE_KEY);

    if (isClosed === LOCAL_STORAGE_TRUE_VALUE) {
      setIsWidgetClosed(true);
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(
      () => {
        setHiddenByDelay(false);
      },
      isIOS ? SHOW_IOS_DELAY : SHOW_DELAY,
    );

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    function beforeinstallprompt(event: Event) {
      // Prevent the default browser installation prompt
      event.preventDefault();
      // Store the event to use it when the "Install" button is clicked
      setDeferredPrompt(event);
      if (event) {
        setHiddenByDelay(false);
      }
    }

    window.addEventListener('beforeinstallprompt', beforeinstallprompt);

    return () =>
      window.removeEventListener('beforeinstallprompt', beforeinstallprompt);
  }, []);

  const onCloseDialog = useCallback(() => {
    setShowDialog(false);
  }, []);

  const onClickClose = useCallback(() => {
    setIsWidgetClosed(true);
    localStorage.setItem(LOCAL_STORAGE_KEY, LOCAL_STORAGE_TRUE_VALUE);
  }, []);

  const onClickInstall = useCallback(async () => {
    const prompt = deferredPrompt as null | {
      prompt?: () => void;
      userChoice?: Promise<{ outcome?: 'accepted' | 'dismissed' }>;
    };

    if (!prompt?.prompt) {
      return setShowDialog(true);
    }

    if (prompt?.prompt) {
      prompt.prompt();
      if (prompt.userChoice) {
        const result = await prompt.userChoice;

        if (result.outcome === 'accepted') {
          onClickClose();
        }
      }
    }
  }, [deferredPrompt, onClickClose]);

  const isInstalled = useMemo(() => {
    return (
      (window.navigator as { standalone?: boolean }).standalone ||
      displayModeStandalone
    );
  }, [displayModeStandalone]);

  return {
    show: !hiddenByDelay && !desktopView && !isWidgetClosed && !isInstalled,
    onClickClose,
    onClickInstall,
    showDialog,
    onCloseDialog,
    onConfirmDialog: onCloseDialog,
    isInstalled,
    hiddenByDelay,
  };
}
