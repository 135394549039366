import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Language } from '../../interfaces/language.interface';
import {
  DEFAULT_LOCALE,
  DEFAULT_LOCALES_LIST,
  LOCALES,
} from '../../utils/constants';
import { ButtonComponent } from '../button.component';
import { LocaleSwitcherOptionComponent } from './locale-switcher-option.component';

export interface LocaleSwitcherProps {
  defaultValue?: Language;
  locales?: ReadonlyArray<Language>;
  open?: boolean;
  onChange?: (value: LOCALES) => void;
}

function LocaleSwitcher({
  defaultValue,
  open: defaultOpen,
  locales = DEFAULT_LOCALES_LIST,
  onChange,
}: LocaleSwitcherProps) {
  const { i18n } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(defaultOpen || false);

  const handleToggle = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
      setOpen((prevOpen) => !prevOpen);
    },
    [setOpen],
  );

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleMenuItemClick = useCallback(
    (event, locale) => {
      i18n.changeLanguage(locale.value);
      setOpen(false);
      if (onChange) {
        onChange(locale.value);
      }
    },
    [i18n, onChange],
  );

  const language =
    defaultValue ||
    locales.filter(({ value }) => value === i18n.language)[0] ||
    locales.filter(({ value }) => value === DEFAULT_LOCALE.value)[0];

  return (
    <>
      <ButtonComponent variant="text" onClick={handleToggle}>
        <LocaleSwitcherOptionComponent {...language} />
        <ArrowDropDownIcon
          sx={{
            marginTop: '-4px',
            marginBottom: '-4px',
          }}
        />
      </ButtonComponent>
      <Popper
        open={open}
        anchorEl={anchorEl}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {locales.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.value}
                      selected={
                        item.value === (language?.value || i18n.language)
                      }
                      onClick={(event) => handleMenuItemClick(event, item)}
                    >
                      <LocaleSwitcherOptionComponent {...item} useFullLabel />
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

export const LocaleSwitcherComponent = memo(LocaleSwitcher);
