import { LOCALES } from './constants';

export const VILMER_LANGUAGE_MAPPER: Record<string, string> = {
  [LOCALES.nb]: '',
  [LOCALES.en]: 'en',
  [LOCALES.sv]: 'sv',
  [LOCALES.da]: 'da',
  [LOCALES.pt]: 'pt',
};

export function mapVilmerLanguage(lng: string): string | undefined {
  return VILMER_LANGUAGE_MAPPER[lng] || VILMER_LANGUAGE_MAPPER[LOCALES.nb];
}
