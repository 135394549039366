import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Access } from '../../interfaces/entities/access.interface';
import { getEmails } from '../../utils/get-emails';
import getName from '../../utils/get-name';
import { getPhones } from '../../utils/get-phones';
import { AvatarWithLightboxComponent } from '../avatar-with-lightbox.component';

function AccessCard({ user, role }: Access) {
  const { t } = useTranslation();

  const name = getName(user?.firstName, user?.lastName);

  const emails = useMemo(() => {
    return getEmails(user.emails);
  }, [user.emails]);

  const phones = useMemo(() => {
    return getPhones(user.phones);
  }, [user.phones]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item xs={10} md={9} lg={7} xl={6}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <AvatarWithLightboxComponent name={name} src={user?.image} />
          </Grid>
          <Grid item>
            <Typography
              variant={'subtitle1'}
              color={'rgba(0, 0, 0, 0.87)'}
              mb={0.5}
            >
              {name}
            </Typography>
            {emails && (
              <Typography variant={'body2'} color={'rgba(0, 0, 0, 0.6)'}>
                {emails}
              </Typography>
            )}
            {phones && (
              <Typography variant={'body2'} color={'rgba(0, 0, 0, 0.6)'}>
                {phones}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2} md={3} lg={5} xl={6}>
        {role && (
          <Typography variant={'subtitle1'} color={'rgba(0, 0, 0, 0.87)'}>
            {t(`access-list-role-${role}.label`)}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}

export const AccessCardComponent = memo(AccessCard);
