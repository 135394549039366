import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import React, { memo, ReactElement } from 'react';

import { AccessCardLoadingComponent } from './access-card-loading.component';

const ARRAY = [1, 2, 3, 4];

function AccessesListLoading(): ReactElement {
  return (
    <List>
      {ARRAY.map((item, index) => (
        <ListItem key={index}>
          <AccessCardLoadingComponent />
        </ListItem>
      ))}
    </List>
  );
}

export const AccessesListLoadingComponent = memo(AccessesListLoading);
