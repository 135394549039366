import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Link from '@mui/material/Link';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import LinesEllipsis from 'react-lines-ellipsis';
import { Link as RouterLink } from 'react-router-dom';

import { PROFILE_ROUTE_STORIES } from '../../configs/profile-routes';
import { Story } from '../../interfaces/entities/story.interface';
import getDate from '../../utils/get-date';
import { parseTextFromHTML } from '../../utils/parse-text-from-html';

function StoryCard({ id, name, date, body }: Story) {
  const { t } = useTranslation();

  return (
    <Card sx={{ height: '100%' }}>
      <CardContent
        sx={{
          pb: '8px !important',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant={'h6'}>{name}</Typography>
        <Typography variant={'caption'}>{getDate(date)}</Typography>
        {body && (
          <Typography
            component={'div'}
            my={2}
            variant={'body2'}
            color={'rgba(0, 0, 0, 0.6)'}
          >
            <LinesEllipsis
              text={parseTextFromHTML(body)}
              maxLine="2"
              ellipsis="..."
              trimRight
              basedOn="letters"
            />
          </Typography>
        )}
        <CardActions sx={{ marginTop: 'auto', padding: 0 }}>
          <Link
            underline="none"
            width={'100%'}
            to={`${PROFILE_ROUTE_STORIES.path}/${id}`}
            component={RouterLink}
          >
            <Button
              sx={{
                ml: 'auto',
              }}
            >
              <ArrowRightIcon />
              {t('stories-list-card-open-story.button')}
            </Button>
          </Link>
        </CardActions>
      </CardContent>
    </Card>
  );
}

export const StoryCardComponent = memo(StoryCard);
