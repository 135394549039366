import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { PROFILE_ROUTE_INVITATION_CREATE } from '../../configs/profile-routes';
import { MY_PATIENTS_ROUTE } from '../../configs/routes';
import { Patient } from '../../interfaces/entities/patient.interface';
import { BLACK_COLOR, WHITE_COLOR } from '../../theme';
import getName from '../../utils/get-name';
import { AvatarComponent } from '../avatar.component';

const IMAGE_WIDTH = 160;
const IMAGE_HEIGHT = 160;

interface PatientCreatedSuccessInvitedDepartmentProps extends Patient {
  onClickGiveAccess?: () => void;
  departmentName: string;
}

function PatientCreatedSuccessInvitedDepartment({
  id,
  firstName,
  lastName,
  image,
  departmentName,
}: PatientCreatedSuccessInvitedDepartmentProps) {
  const { t } = useTranslation();

  const name = useMemo(() => {
    return getName(firstName, lastName);
  }, [firstName, lastName]);

  return (
    <Stack alignItems="center" spacing={{ xs: 3, md: 4 }}>
      <Box position={'relative'} mt={2}>
        <AvatarComponent
          name={name}
          src={image || ''}
          sx={(theme) => ({
            height: IMAGE_HEIGHT,
            width: IMAGE_WIDTH,
            fontSize: theme.typography.h1.fontSize,
            fontWeight: theme.typography.h1.fontWeight,
            fontStyle: theme.typography.h1.fontStyle,
            lineHeight: theme.typography.h1.lineHeight,
            letterSpacing: theme.typography.h1.letterSpacing,
            border: '1px solid',
            color: WHITE_COLOR,
            borderColor: WHITE_COLOR,
            boxShadow: `0 0 0 3px ${theme.palette.primary.main}`,
          })}
        />
        <Box
          sx={{
            backgroundImage: `url('/assets/success-checked-icon.svg')`,
            position: 'absolute',
            top: -20,
            right: -20,
            height: 80,
            width: 80,
          }}
        />
      </Box>

      <Box>
        <Typography variant="h4" fontSize={'28px'} fontWeight={700}>
          {t('create-profile-success-access-to-department.title', {
            department: departmentName,
          })}
        </Typography>
        <Typography variant="subtitle1" whiteSpace={'pre-line'}>
          {t('create-profile-success-access-to-department.subtitle', {
            name: firstName,
          })}
        </Typography>
      </Box>

      <Box width={'100%'} maxWidth={'450px'}>
        <Link
          to={`${MY_PATIENTS_ROUTE.path}/${id}/${PROFILE_ROUTE_INVITATION_CREATE.path}`}
          underline="none"
          component={RouterLink}
        >
          <Button
            fullWidth
            variant="contained"
            sx={{
              height: '50px',
              textTransform: 'none',
              borderRadius: '68.953px',
              boxShadow:
                '0px 1.9481251239776611px 3.8962502479553223px 0px rgba(0, 0, 0, 0.20), 0px 0.9740625619888306px 9.740625381469727px 0px rgba(0, 0, 0, 0.12), 0px 3.8962502479553223px 4.870312690734863px 0px rgba(0, 0, 0, 0.14)',
              fontSize: '19.307px',
              fontWeight: 500,
              lineHeight: '15.585px',
              letterSpacing: '1.218px',
            }}
            disableElevation
          >
            {t('create-profile-invite-more.button')}
          </Button>
        </Link>
        <Link
          to={`${MY_PATIENTS_ROUTE.path}/${id}`}
          underline="none"
          component={RouterLink}
        >
          <Button
            fullWidth
            variant="contained"
            sx={{
              height: '50px',
              mt: 2.25,
              textTransform: 'none',
              color: BLACK_COLOR,
              backgroundColor: '#F2F2F2',
              borderRadius: '68.953px',
              boxShadow:
                '0px 1.9481251239776611px 3.8962502479553223px 0px rgba(0, 0, 0, 0.20), 0px 0.9740625619888306px 9.740625381469727px 0px rgba(0, 0, 0, 0.12), 0px 3.8962502479553223px 4.870312690734863px 0px rgba(0, 0, 0, 0.14)',
              fontSize: '19.307px',
              fontWeight: 500,
              lineHeight: '15.585px',
              letterSpacing: '1.218px',
              '&:hover': {
                backgroundColor: '#F2F2F2',
              },
            }}
            disableElevation
          >
            {t('open-profile.button')}
          </Button>
        </Link>
      </Box>
    </Stack>
  );
}

export const PatientCreatedSuccessInvitedDepartmentComponent = memo(
  PatientCreatedSuccessInvitedDepartment,
);
