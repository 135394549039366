import { useCallback, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { Patient } from '../../interfaces/entities/patient.interface';
import { Story } from '../../interfaces/entities/story.interface';
import { usePatientNestedItem } from '../patients/use-patient-nested-item';
import { useStores } from '../use-stores';

export const useStory = (
  patientId: Patient['id'],
): {
  item: Story | null;
  fetchLoading?: boolean;
  updateLoading?: boolean;
  update: (data: Partial<Story>) => Promise<void>;
  remove: () => Promise<void>;
} => {
  const { params } = useRouteMatch<{ storyId: string }>();

  const { storiesStore: store } = useStores();

  const { item, loading } = usePatientNestedItem(
    store,
    patientId,
    params.storyId,
  );

  const [updateLoading, setUpdateLoading] = useState(false);

  const update = useCallback(
    async (data: Partial<Story>) => {
      setUpdateLoading(true);
      await store.updateByPatientId(patientId, params.storyId, data);
      setUpdateLoading(false);
    },
    [store.updateByPatientId, setUpdateLoading, patientId, params.storyId],
  );

  const remove = useCallback(async () => {
    await store.deleteByPatientId(patientId, params.storyId);
    setUpdateLoading(false);
  }, [store.deleteByPatientId, patientId, params.storyId]);

  return {
    item,
    fetchLoading: loading,
    updateLoading,
    update,
    remove,
  };
};
