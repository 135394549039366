import React, { memo, ReactElement } from 'react';

import { PatientsNewContainer } from '../containers/patients/patients-new.container';
import PageProps from '../interfaces/page-props.interface';
import { Page } from './page';

function CreatePatient(props: PageProps): ReactElement {
  return (
    <Page {...props}>
      <PatientsNewContainer />
    </Page>
  );
}

export const CreatePatientPage = memo(CreatePatient);
