import { omit } from 'lodash';
import React, { memo } from 'react';
import {
  IntercomProvider as IntercomProviderBase,
  IntercomProviderProps as IntercomProviderPropsBase,
} from 'react-use-intercom';

import { Unit } from '../../interfaces/entities/unit.interface';
import { User } from '../../interfaces/user.interface';
import { IntercomProviderChildrenComponent } from './intercom-provider-children.component';

interface IntercomProviderProps extends IntercomProviderPropsBase {
  user?: User;
  loadingUser?: boolean;
  units?: Unit[];
  loadingUnits?: boolean;
}

function IntercomProvider(props: IntercomProviderProps) {
  const { autoBoot = true, user, loadingUser, units, loadingUnits } = props;

  return (
    <IntercomProviderBase
      {...omit(props, ['user', 'loadingUser', 'units', 'loadingUnits'])}
      autoBoot={autoBoot}
    >
      <IntercomProviderChildrenComponent
        user={user}
        loadingUser={loadingUser}
        units={units}
        loadingUnits={loadingUnits}
      >
        {props.children}
      </IntercomProviderChildrenComponent>
    </IntercomProviderBase>
  );
}

export const IntercomProviderComponent = memo(IntercomProvider);
