import Box from '@mui/material/Box';
import React, { memo, useCallback, useState } from 'react';
import { useIntercom } from 'react-use-intercom';

import { NavigationProps } from '../../../interfaces/navigation-props.interface';
import { MobileNavigationHeaderComponent } from './mobile-navigation-header.component';
import { MobileNavigationMenuComponent } from './mobile-navigation-menu.component';

function MobileNavigation(props: NavigationProps) {
  const { show } = useIntercom();

  const [open, setOpen] = useState(false);

  const toggleOpen = useCallback(() => {
    setOpen(!open);
  }, [open]);

  return (
    <Box hidden={!props.open}>
      <MobileNavigationHeaderComponent
        menuOnClick={toggleOpen}
        supportOnClick={show}
      />
      <MobileNavigationMenuComponent
        open={open}
        items={props.items}
        onClose={toggleOpen}
        logged={props.logged}
      />
    </Box>
  );
}

export const MobileNavigationComponent = memo(MobileNavigation);
