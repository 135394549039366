import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React, { memo } from 'react';

function EdgeMenuIcon(props?: SvgIconProps) {
  return (
    <SvgIcon
      viewBox="0 0 2014 1569"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="2014" height="1569" fill="white" />
      <rect x="468" y="525" width="1163" height="566" rx="283" fill="#211C1C" />
      <path
        d="M509 805C509 666.929 620.929 555 759 555H1346C1484.07 555 1596 666.929 1596 805V805C1596 943.071 1484.07 1055 1346 1055H759C620.929 1055 509 943.071 509 805V805Z"
        fill="white"
      />
      <ellipse cx="1052" cy="805" rx="41" ry="43" fill="#261D1D" />
      <ellipse cx="853" cy="808" rx="41" ry="43" fill="#261D1D" />
      <ellipse cx="1251" cy="805" rx="41" ry="43" fill="#261D1D" />
    </SvgIcon>
  );
}

export const EdgeMenuIconComponent = memo(EdgeMenuIcon);
