import { Field, FieldProps, Form, Formik, FormikProps } from 'formik';
import React, { forwardRef, memo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { Patient } from '../../interfaces/entities/patient.interface';
import { MultiLinesField } from './shared/multi-lines-field.component';

type PatientDescription = Pick<Patient, 'description'>;

interface PatientDescriptionFormProps extends PatientDescription {
  onChange: (data: PatientDescription) => void;
}

const MIN_ROWS = 5;

function PatientDescriptionFormBase(
  { onChange, ...props }: PatientDescriptionFormProps,
  ref: React.Ref<FormikProps<Patient>>,
) {
  const { t } = useTranslation();

  return (
    <Formik<PatientDescription>
      initialValues={props || {}}
      onSubmit={onChange}
      innerRef={ref as React.Ref<FormikProps<PatientDescription>>}
      validationSchema={Yup.object<
        Record<keyof PatientDescription, Yup.AnySchema>
      >().shape({
        description: Yup.string().nullable().optional(),
      })}
    >
      {() => (
        <Form>
          <Field name="description">
            {(props: FieldProps) => {
              return (
                <MultiLinesField
                  {...props}
                  label={t('patient-edit-about.label')}
                  assistiveText={t('patient-edit-about.assistive')}
                  assistiveTextPosition="top"
                  minRows={MIN_ROWS}
                />
              );
            }}
          </Field>
        </Form>
      )}
    </Formik>
  );
}

export const PatientDescriptionForm = memo(
  forwardRef(PatientDescriptionFormBase),
);
