import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  GuestbookPost,
  GuestbookPostUser,
} from '../../interfaces/entities/guestbook-post.interface';
import { getDateTime } from '../../utils/get-date-time';
import getName from '../../utils/get-name';
import { AvatarWithLightboxComponent } from '../avatar-with-lightbox.component';
import { MoreButtonComponent } from '../more-button.component';

export interface GuestbookReplyMessageProps extends Partial<GuestbookPostUser> {
  showMenuButton?: boolean;
  createdAt: GuestbookPost['createdAt'];
  onClickEdit?: () => void;
  onClickDelete?: () => void;
  isNew?: GuestbookPost['isNew'];
}

function GuestbookReplyMessage({
  firstName,
  lastName,
  image,
  accesses,
  createdAt,
  showMenuButton,
  onClickEdit,
  onClickDelete,
  isNew,
}: GuestbookReplyMessageProps) {
  const { t } = useTranslation();

  const handleClickEdit = useCallback(() => {
    if (onClickEdit) {
      onClickEdit();
    }
  }, [onClickEdit]);

  const handleClickDelete = useCallback(() => {
    if (onClickDelete) {
      onClickDelete();
    }
  }, [onClickDelete]);

  const name = useMemo(() => {
    return getName(firstName, lastName);
  }, [firstName, lastName]);

  const dateTime = useMemo(() => {
    return getDateTime(createdAt);
  }, [createdAt]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="flex-end"
      spacing={0.5}
    >
      <Grid item>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={0.5}
        >
          <Grid item>
            <AvatarWithLightboxComponent src={image} name={name} />
          </Grid>
          <Grid item ml={1}>
            <Typography variant={'subtitle2'} color={'rgba(0, 0, 0, 0.87)'}>
              {name}
            </Typography>
            {!!accesses?.length && (
              <Typography
                variant={'body2'}
                color={'rgba(0, 0, 0, 0.6)'}
                fontSize={'12px'}
              >
                {t(`access-list-role-${accesses[0].role}.label`)}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-end"
          spacing={0.5}
        >
          {showMenuButton && (
            <MoreButtonComponent
              aria-label="more"
              aria-haspopup="true"
              size={'small'}
              sx={{
                padding: 0,
                color: 'rgba(0, 0, 0, 0.87)',
              }}
              items={[
                <ListItem
                  button
                  key={'general-edit.button'}
                  onClick={handleClickEdit}
                  divider
                >
                  <ListItemIcon>
                    <EditIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('general-edit.button')} />
                </ListItem>,
                <ListItem
                  button
                  key={'general-delete.button'}
                  onClick={handleClickDelete}
                >
                  <ListItemIcon>
                    <DeleteIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('general-delete.button')} />
                </ListItem>,
              ]}
            />
          )}
          {isNew && (
            <Chip
              avatar={
                <FiberManualRecordIcon
                  sx={(theme) => ({
                    color: `${theme.palette.primary.main}!important`,
                    backgroundColor: 'transparent!important',
                  })}
                />
              }
              label={t('guestbook-post-new.label')}
              variant="outlined"
              color="primary"
            />
          )}
          <Typography
            variant={'caption'}
            fontSize={'10px'}
            color={'rgba(0, 0, 0, 0.87)'}
          >
            {dateTime}
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
}

export const GuestbookReplyMessageComponent = memo(GuestbookReplyMessage);
