import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useQuery } from '../hooks/use-query';
import { useStores } from '../hooks/use-stores';
import PageProps from '../interfaces/page-props.interface';
import { AUTH_REDIRECT_URL_QUERY, LOCALES } from '../utils/constants';
import { Page } from './page';

function Auth(props: PageProps) {
  const { i18n } = useTranslation();

  const { authStore, usersStore } = useStores();
  const query = useQuery();

  useEffect(() => {
    const token = query.get('token');
    const redirectUrl = query.get(AUTH_REDIRECT_URL_QUERY);
    if (token) {
      authStore
        .setToken(token)
        .then(() => usersStore.updateLanguage(i18n.language as LOCALES))
        .finally(() => window.location.replace(redirectUrl || '/'));
    }
  }, [authStore, query, history]);

  return <Page {...props} title={'Login'} />;
}

export const AuthPage = observer(Auth);
