import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  HeaderComponent,
  HeaderProps,
} from '../components/header/header.component';
import { useCountryCode } from '../hooks/use-country-code';
import { useStores } from '../hooks/use-stores';
import { LOCALES } from '../utils/constants';

const CHANGED_LANGUAGE_LOCAL_STORAGE_KEY = 'changedLanguage';

function Header(props: Omit<HeaderProps, 'onClickLogin'>) {
  const { i18n } = useTranslation();

  const { authStore, usersStore } = useStores();

  const { loading: loadingCountry, countryCode } = useCountryCode();

  useEffect(() => {
    authStore.fetchMe();
  }, [authStore]);

  const logged = !!authStore.me;

  useEffect(() => {
    const changedLanguage = localStorage.getItem(
      CHANGED_LANGUAGE_LOCAL_STORAGE_KEY,
    );

    if (changedLanguage || authStore.loading !== false) {
      return;
    }

    if (
      !authStore.me &&
      loadingCountry === false &&
      countryCode?.toLowerCase() === 'se'
    ) {
      i18n.changeLanguage(LOCALES.sv);
    } else if (
      authStore.me?.language &&
      i18n.language !== authStore.me?.language
    ) {
      i18n.changeLanguage(authStore.me.language);
    }
  }, [
    authStore.me,
    authStore.loading,
    loadingCountry,
    countryCode,
    i18n.language,
  ]);

  const onChangeLanguage = useCallback(
    (language: LOCALES) => {
      localStorage.setItem(CHANGED_LANGUAGE_LOCAL_STORAGE_KEY, '1');
      if (logged) {
        usersStore.updateLanguage(language);
      }
    },
    [logged, usersStore.updateLanguage],
  );

  return (
    <HeaderComponent
      {...props}
      loadingLogin={authStore.loading}
      logged={logged}
      onChangeLanguage={onChangeLanguage}
    />
  );
}

export const HeaderContainer = observer(Header);
