import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import React, { memo, useState } from 'react';

import { useInstallWidget } from '../../../hooks/use-install-widget';
import { NavigationProps } from '../../../interfaces/navigation-props.interface';
import { PatientNavigationItem } from '../../../interfaces/patient-navigation-item';
import { MobilePatientNavigationTabComponent } from './mobile-patient-navigation-tab.component';

interface MobilePatientNavigationProps extends NavigationProps {
  items?: ReadonlyArray<PatientNavigationItem>;
}

function MobilePatientNavigation(props: MobilePatientNavigationProps) {
  const { items = [] } = props;
  const { show: showInstallWidget } = useInstallWidget();

  const index = items.findIndex(({ to, exact }) => {
    if (exact) {
      return to === window.location.pathname;
    }

    return window.location.pathname.startsWith('' + to);
  });
  const [value, setValue] = useState<number | undefined>(
    index !== -1 ? index : 0,
  );

  return (
    <Paper
      sx={(theme) => ({
        height: '69px',
        position: 'fixed',
        zIndex: theme.zIndex.drawer + 2,
        bottom: !showInstallWidget ? '3px' : '99px',
        left: 0,
        right: 0,
        boxShadow: 'none',
        border: '1px solid',
        borderColor: theme.palette.primary.light,
      })}
    >
      <Tabs
        value={value}
        variant="scrollable"
        scrollButtons="auto"
        indicatorColor="primary"
        TabIndicatorProps={{
          style: {
            height: '2px',
          },
        }}
        sx={{
          backgroundColor: '#FDFAFB',
        }}
      >
        {items.map((item, index) => {
          return (
            <MobilePatientNavigationTabComponent
              onClick={() => setValue(index)}
              key={index}
              tabIndex={index}
              isActive={value === index}
              {...item}
            />
          );
        })}
      </Tabs>
    </Paper>
  );
}

export const MobilePatientNavigationComponent = memo(MobilePatientNavigation);
