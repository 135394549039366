import { AxiosInstance } from 'axios';

import { Patient } from '../interfaces/entities/patient.interface';
import { Relative } from '../interfaces/entities/relative.interface';
import { UploadsService } from '../services/uploads.service';
import { PatientBaseStore } from './shared/patient-base.store';

export class RelativesStore extends PatientBaseStore<Relative> {
  constructor(
    api: AxiosInstance,
    private readonly uploadsService = new UploadsService(api),
  ) {
    super(api, 'relatives');
  }

  async createByPatientIdWithPhoto(
    patientId: Patient['id'],
    data: Partial<Relative>,
    file: File,
  ) {
    const relative = await this.createByPatientId(patientId, {
      ...data,
      image: null,
    });

    if (!relative?.id) {
      return;
    }

    const { url } = await this.uploadsService.upload(
      `patients/${patientId}/${this.entitiesName}/${relative.id}`,
      file,
    );

    await this.updateByPatientId(patientId, relative.id, {
      ...data,
      image: url,
    });
  }

  async updateByPatientIdWithPhoto(
    patientId: Patient['id'],
    id: Relative['id'],
    data: Partial<Relative>,
    file: File,
  ) {
    const { url } = await this.uploadsService.upload(
      `patients/${patientId}/${this.entitiesName}/${id}`,
      file,
    );
    await this.updateByPatientId(patientId, id, { ...data, image: url });
  }
}
