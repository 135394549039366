import Stack, { StackProps } from '@mui/material/Stack';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import React, { memo, useCallback, useMemo } from 'react';

import { GuestbookPost } from '../../interfaces/entities/guestbook-post.interface';
import { User } from '../../interfaces/user.interface';
import { LIGHT_SILVER_COLOR, LIGHT_SILVER_DARKER_COLOR } from '../../theme';
import getName from '../../utils/get-name';
import { AvatarWithLightboxComponent } from '../avatar-with-lightbox.component';

interface CommonGuestbookPostMessageInputProps extends Pick<StackProps, 'sx'> {
  user?: User;
  message?: GuestbookPost['message'];
  onChange?: (value: string) => void;
  textFieldProps?: TextFieldProps;
}

function CommonGuestbookPostMessageInput({
  message = '',
  onChange,
  textFieldProps,
  user,
  sx,
}: CommonGuestbookPostMessageInputProps) {
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(event.target.value);
      }
    },
    [onChange],
  );

  const name = useMemo(() => getName(user?.firstName, user?.lastName), []);

  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={{ xs: 0.75, md: 1, lg: 1.5 }}
      sx={sx}
    >
      <AvatarWithLightboxComponent src={user?.image} name={name} />
      <TextField
        multiline
        value={message}
        fullWidth
        sx={{
          '& .MuiInputBase-root': {
            borderRadius: '25px',
            backgroundColor: LIGHT_SILVER_COLOR,
            '&:hover': {
              backgroundColor: LIGHT_SILVER_DARKER_COLOR,
            },
            '&:focus': {
              backgroundColor: LIGHT_SILVER_DARKER_COLOR,
            },
            '&:active': {
              backgroundColor: LIGHT_SILVER_DARKER_COLOR,
            },
          },
          ...textFieldProps?.sx,
        }}
        {...textFieldProps}
        onChange={handleChange}
      />
    </Stack>
  );
}

export const CommonGuestbookPostInputComponent = memo(
  CommonGuestbookPostMessageInput,
);
