import 'react-image-lightbox/style.css';

import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import { SxProps, Theme } from '@mui/material/styles';
import React, { memo, useCallback, useState } from 'react';
import Lightbox from 'react-image-lightbox';

import { PLACEHOLDER_AVATAR } from '../utils/constants';

export interface ImageProps {
  image?: string | null;
  imageFullScreen?: string | null;
  title?: string;
  cardMediaSx?: SxProps<Theme>;
}

function Image({ image, title, imageFullScreen, cardMediaSx }: ImageProps) {
  const [openLightbox, setOpenLightbox] = useState(false);

  const toggleOpenLightbox = useCallback(() => {
    setOpenLightbox(!openLightbox);
  }, [openLightbox]);

  if (!image) {
    return (
      <Box sx={{ cursor: 'pointer', width: '100%', maxWidth: '100%' }}>
        <CardMedia
          image={PLACEHOLDER_AVATAR}
          sx={{ borderRadius: 1, paddingTop: '80%', ...cardMediaSx }}
        />
      </Box>
    );
  }

  return (
    <>
      {openLightbox && (
        <Lightbox
          reactModalStyle={{ overlay: { zIndex: 9999 } }}
          mainSrc={imageFullScreen || image}
          onCloseRequest={toggleOpenLightbox}
        />
      )}

      <Box
        onClick={toggleOpenLightbox}
        sx={{ cursor: 'pointer', width: '100%', maxWidth: '100%' }}
      >
        <CardMedia
          image={image}
          title={title}
          sx={{ borderRadius: 1, paddingTop: '80%', ...cardMediaSx }}
        />
      </Box>
    </>
  );
}

export const ImageComponent = memo(Image);
