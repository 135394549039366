import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { memo, useMemo } from 'react';

import PATIENT_NAVIGATION_ITEMS, {
  PATIENT_NAVIGATION_ITEM_GUESTBOOK,
} from '../../configs/patient-navigation';
import { MY_PATIENTS_ROUTE } from '../../configs/routes';
import { Patient } from '../../interfaces/entities/patient.interface';
import { PatientNavigationItem } from '../../interfaces/patient-navigation-item';
import { DRAWER_WIDTH } from '../../utils/constants';
import { DesktopPatientNavigationComponent } from './desktop-patient-navigation/desktop-patient-navigation.component';
import { MobilePatientNavigationComponent } from './mobile-patient-navigation/mobile-patient-navigation.component';

interface PatientNavigationProps {
  patient?: Patient;
  patientId: string;
}

const getSidebarItems: (
  id: string,
  patient?: Patient,
) => ReadonlyArray<PatientNavigationItem> = (id: string, patient?: Patient) =>
  PATIENT_NAVIGATION_ITEMS.map((item) => ({
    ...item,
    to: `${MY_PATIENTS_ROUTE.path}/${id}/${item.to}`,
    counter:
      item.label === PATIENT_NAVIGATION_ITEM_GUESTBOOK.label &&
      patient?.newGuestbookPostsCount
        ? patient.newGuestbookPostsCount
        : undefined,
  }));

function PatientNavigation({ patient, patientId }: PatientNavigationProps) {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const sidebarItems = useMemo(() => {
    return getSidebarItems(patient?.id || patientId, patient);
  }, [patient]);

  if (mobileView) {
    return <MobilePatientNavigationComponent items={sidebarItems} />;
  }

  return (
    <Box width={`${DRAWER_WIDTH}px`}>
      <DesktopPatientNavigationComponent
        patient={patient}
        loading={!patient}
        items={sidebarItems}
      />
    </Box>
  );
}

export const PatientNavigationComponent = memo(PatientNavigation);
