import BlockIcon from '@mui/icons-material/Block';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import Box from '@mui/material/Box';
import MUIButton from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Access,
  ACCESS_ROLES,
} from '../../interfaces/entities/access.interface';
import { User } from '../../interfaces/user.interface';
import { allNursesHaveRole } from '../../utils/all-nurses-have-role';
import { AccessRoleToggleButtonComponent } from '../buttons/access-role-toggle-button.component';
import { SelectUserListItemComponent } from '../users/select-user-list-item.component';
import { DialogComponent } from './dialog.component';
export interface ConfirmGiveAccessToDepartmentProps {
  isOpen: boolean;
  onConfirm: () => void | Promise<void>;
  onClose: () => void;
  departmentName?: string;
  nurses?: User[];
  nursesAccesses?: Record<string, ACCESS_ROLES>;
  onClickSelect?: (id: User['id'], role: Access['role']) => void;
  onClickRemove?: (id: User['id']) => void;
  onClickSelectAll?: (role: Access['role']) => void;
  onClickRemoveAll?: () => void;
}

const ALL_WITHOUT_ACCESS = 'all-without-access';
const ALL_ADMINS_VALUE = 'all-admins';
const ALL_MEMBERS_VALUE = 'all-members';

function ConfirmGiveAccessToDepartment({
  onClose,
  onConfirm,
  isOpen,
  departmentName,
  nurses = [],
  nursesAccesses = {},
  onClickSelectAll,
  onClickRemoveAll,
  onClickSelect,
  onClickRemove,
}: ConfirmGiveAccessToDepartmentProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChangeAll = useCallback(
    (event: React.MouseEvent<HTMLElement>, newValue: string) => {
      if (onClickSelectAll && newValue === ALL_ADMINS_VALUE) {
        return onClickSelectAll(ACCESS_ROLES.ADMIN);
      }

      if (onClickSelectAll && newValue === ALL_MEMBERS_VALUE) {
        return onClickSelectAll(ACCESS_ROLES.MEMBER);
      }

      if (onClickRemoveAll && newValue === ALL_WITHOUT_ACCESS) {
        return onClickRemoveAll();
      }
    },
    [onClickSelectAll, onClickRemoveAll],
  );

  const allAdmins = useMemo(() => {
    return allNursesHaveRole(nurses, nursesAccesses, ACCESS_ROLES.ADMIN);
  }, [nurses, nursesAccesses]);

  const allMembers = useMemo(() => {
    return allNursesHaveRole(nurses, nursesAccesses, ACCESS_ROLES.MEMBER);
  }, [nurses, nursesAccesses]);

  const allValue = useMemo(() => {
    if (allAdmins) {
      return ALL_ADMINS_VALUE;
    }

    if (allMembers) {
      return ALL_MEMBERS_VALUE;
    }

    return null;
  }, [allAdmins, allMembers]);

  return (
    <DialogComponent
      isOpen={isOpen}
      onClose={onClose}
      title={t('create-profile-give-access-to-department-modal.title')}
      fullScreen={fullScreen}
      maxWidth={'xl'}
      description={
        <>
          <Typography variant="body1">
            {t('create-profile-give-access-to-department-modal.description', {
              department: departmentName,
            })}
          </Typography>
          <Typography mt={2} pb={2} variant="h5" textAlign={'center'}>
            {departmentName}
          </Typography>
          <List>
            <Box key={'all'} pb={1}>
              <ListItem
                sx={{
                  height: theme.spacing(7.25),
                }}
                secondaryAction={
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    spacing={1}
                  >
                    <Typography
                      variant="body2"
                      sx={{ textDecoration: 'underline' }}
                      component={'p'}
                    >
                      {t(
                        'create-profile-give-access-to-department-apply-to-all.button',
                      )}
                    </Typography>
                    <ToggleButtonGroup
                      exclusive
                      value={allValue}
                      onChange={handleChangeAll}
                    >
                      <AccessRoleToggleButtonComponent
                        value={ALL_WITHOUT_ACCESS}
                      >
                        <BlockIcon />
                      </AccessRoleToggleButtonComponent>
                      <AccessRoleToggleButtonComponent
                        value={ALL_ADMINS_VALUE}
                        label={t(
                          `access-list-role-${ACCESS_ROLES.ADMIN}.label`,
                        )}
                      >
                        <ManageAccountsIcon />
                      </AccessRoleToggleButtonComponent>
                      <AccessRoleToggleButtonComponent
                        value={ALL_MEMBERS_VALUE}
                        label={t(
                          `access-list-role-${ACCESS_ROLES.MEMBER}.label`,
                        )}
                      >
                        <PermIdentityIcon />
                      </AccessRoleToggleButtonComponent>
                    </ToggleButtonGroup>
                  </Stack>
                }
                disablePadding
              />
            </Box>
            <Divider />

            {nurses.map((item) => {
              return (
                <Box key={item.id}>
                  <SelectUserListItemComponent
                    accessRole={nursesAccesses[item.id]}
                    onSetRole={(role: Access['role']) =>
                      onClickSelect && onClickSelect(item.id, role)
                    }
                    onRemoveRole={() => onClickRemove && onClickRemove(item.id)}
                    {...item}
                  />
                  <Divider />
                </Box>
              );
            })}
          </List>
        </>
      }
    >
      <Box position={'relative'}>
        <Box
          sx={(theme) => ({
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            width: '100%',
            height: theme.spacing(3.5),
            mt: -3.5,
            background:
              'linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))',
            display: nurses.length <= 2 ? 'none' : undefined,
            [theme.breakpoints.up('sm')]: {
              display: nurses.length <= 3 ? 'none' : undefined,
            },
          })}
        />
        <Box
          display="flex"
          px={3}
          py={2}
          sx={(theme) => ({
            flexDirection: 'column',
            [theme.breakpoints.up('md')]: {
              flexDirection: 'row',
              justifyContent: 'flex-end',
            },
          })}
        >
          <MUIButton
            onClick={onConfirm}
            disableElevation
            variant="contained"
            sx={{
              minWidth: '150px',
            }}
          >
            {t(
              'create-profile-give-access-to-department-modal-give-access.button',
            )}
          </MUIButton>
          <MUIButton
            onClick={onClose}
            disableElevation
            sx={(theme) => ({
              minWidth: '80px',
              flexDirection: 'column',
              order: '-1',
              [theme.breakpoints.up('md')]: {
                flexDirection: 'row',
                justifyContent: 'flex-end',
                order: '0',
                ml: 1,
              },
            })}
          >
            {t('general-cancel.button')}
          </MUIButton>
        </Box>
      </Box>
    </DialogComponent>
  );
}

export const ConfirmGiveAccessToDepartmentComponent = memo(
  ConfirmGiveAccessToDepartment,
);
