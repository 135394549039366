import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AlbumsOneComponent } from '../../components/albums/albums-one.component';
import { EmptyScreenComponent } from '../../components/empty-screen.component';
import { useGuestbookFiles } from '../../hooks/guestbook/use-guestbook-files';
import { usePatient } from '../../hooks/patients/use-patient';
import { usePatientRole } from '../../hooks/patients/use-patient-role';
import { useEditButton } from '../../hooks/use-edit-button';
import { AlbumFile } from '../../interfaces/entities/album-file.interface';
import { ProfilePageProps } from '../../interfaces/profile-page-props.interface';
import { DEFAULT_GUESTBOOK_ALBUM_NAME } from '../../utils/constants';

function GuestbookAlbumContainerBase({ profileId }: ProfilePageProps) {
  const { t } = useTranslation();
  const { items, loading, hasMore, loadMore, onClickFavorite } =
    useGuestbookFiles(profileId);
  const { item: profile, fetchLoading } = usePatient(profileId);
  const { isPatientRoleAdmin } = usePatientRole(profileId);
  const { onClick: onClickEdit } = useEditButton();

  const albumFilesItems: AlbumFile[] | undefined = useMemo(() => {
    if (!items) {
      return items;
    }

    return items.map(
      (item) => ({ ...item, isFavorite: item.albumIsFavorite } as AlbumFile),
    );
  }, [items]);

  return (
    <AlbumsOneComponent
      profileId={profileId}
      name={profile?.guestbookAlbumName || DEFAULT_GUESTBOOK_ALBUM_NAME}
      showDeleteButton={false}
      showEditButton={isPatientRoleAdmin}
      onEdit={onClickEdit}
      description={t('guestbook-album-description.body')}
      loading={fetchLoading}
      files={albumFilesItems as AlbumFile[]}
      loadingFiles={loading}
      hasMoreFiles={hasMore}
      loadMoreFiles={loadMore}
      onMarkFavorite={onClickFavorite}
      EmptyScreen={
        <EmptyScreenComponent
          title={t('photos-list-empty.header')}
          description={t('photos-list-empty.body')}
          text={`${t('photos-list-empty-add-photos.button')}`}
          hasContent={false}
        />
      }
    />
  );
}

export const GuestbookAlbumContainer = observer(GuestbookAlbumContainerBase);
