import { useCallback, useEffect, useState } from 'react';

import { SlideshowSettings } from '../interfaces/slideshow-settings.interface';

export const SLIDESHOW_SETTINGS_LOCAL_STORAGE_KEY = 'slideshow-settings';
export const DEFAULT_SLIDESHOW_SETTINGS: SlideshowSettings = {
  loop: false,
  interval: 10 * 1000, // 10 sec
};
export function useSlideshowSettings(): {
  onChange: (value: SlideshowSettings) => void;
  value: SlideshowSettings;
} {
  const [value, setValue] = useState<SlideshowSettings>(
    DEFAULT_SLIDESHOW_SETTINGS,
  );

  useEffect(() => {
    const settingsFromLocalStorage = localStorage.getItem(
      SLIDESHOW_SETTINGS_LOCAL_STORAGE_KEY,
    );
    if (!settingsFromLocalStorage) {
      return;
    }
    try {
      const parsedSettings = JSON.parse(settingsFromLocalStorage);
      setValue({
        ...DEFAULT_SLIDESHOW_SETTINGS,
        interval: parsedSettings.interval,
        loop: parsedSettings.loop,
      });
    } catch (err) {
      console.error("Can't parse slideshow settings");
    }
  }, []);

  const onChange = useCallback((value: SlideshowSettings) => {
    setValue(value);
    localStorage.setItem(
      SLIDESHOW_SETTINGS_LOCAL_STORAGE_KEY,
      JSON.stringify(value),
    );
  }, []);

  return {
    onChange,
    value,
  };
}
