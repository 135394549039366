import { useEffect, useState } from 'react';

import { Unit } from '../../interfaces/entities/unit.interface';
import { getUnitParentsJoin } from '../../utils/get-unit-parents-join';
import { useStores } from '../use-stores';

export const useUnit = (
  id?: Unit['id'],
): {
  item: Unit | null;
  fetchLoading?: boolean;
} => {
  const [item, setItem] = useState<Unit | null>(null);
  const [fetchLoading, setFetchLoading] = useState<boolean>(true);
  const { unitsStore: store } = useStores();

  useEffect(() => {
    const fetch = async () => {
      if (!id) {
        return;
      }
      setFetchLoading(true);
      setItem((await store.fetchUnit(id, getUnitParentsJoin())) || null);
      setFetchLoading(false);
    };
    if (id) {
      fetch();
    }
  }, [store.fetchUnit, id]);

  return {
    item,
    fetchLoading: fetchLoading,
  };
};
