import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import Button, { ButtonProps } from '@mui/material/Button';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useGoToOrBack } from '../../hooks/use-go-to-or-back';

interface BackButtonProps extends ButtonProps {
  path?: string;
}

function BackButton(props: BackButtonProps) {
  const { t } = useTranslation();
  const { go } = useGoToOrBack(props.path);

  return (
    <Button onClick={go} {...props} disableElevation>
      <ArrowLeftIcon />
      {t('general-back.button')}
    </Button>
  );
}

export const BackButtonComponent = memo(BackButton);
