import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import DatePickerProps from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Locale from 'date-fns/locale';
import daLocale from 'date-fns/locale/da';
import enLocale from 'date-fns/locale/en-US';
import nbLocale from 'date-fns/locale/nb';
import ptLocale from 'date-fns/locale/pt';
import svLocale from 'date-fns/locale/sv';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { LOCALES } from '../../utils/constants';
import { FieldProps } from '../interfaces/field-props.interface';
import { FieldWrapperComponent } from './field-wrapper.component';

export const LOCALES_MAPPER: Record<LOCALES, Locale> = {
  [LOCALES.en]: enLocale,
  [LOCALES.nb]: nbLocale,
  [LOCALES.pt]: ptLocale,
  [LOCALES.sv]: svLocale,
  [LOCALES.da]: daLocale,
};

interface DateFieldProps
  extends Omit<typeof DatePickerProps, 'onChange' | 'error'>,
    Omit<FieldProps, 'label'> {
  onChange: (date: Date | null) => void;
  value: Date | string | null;
  label: string;
}

function DateField(props: DateFieldProps) {
  const { label, onChange, value } = props;

  const { i18n } = useTranslation();

  const locale = LOCALES_MAPPER[i18n.language as LOCALES] || LOCALES_MAPPER.nb;
  return (
    <FieldWrapperComponent {...props}>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
        <Stack spacing={3}>
          <DatePicker
            disableFuture
            label={label}
            inputFormat="dd/MM/yyyy"
            onChange={onChange}
            value={value}
            renderInput={(params) => (
              <TextField {...params} error={false} sx={{ width: '100%' }} />
            )}
          />
        </Stack>
      </LocalizationProvider>
    </FieldWrapperComponent>
  );
}

export const DateFieldComponent = memo(DateField);
