import Box from '@mui/material/Box';
import React, { memo, ReactElement } from 'react';

import { GuestbookPostLoadingComponent } from './guestbook-post-loading.component';

const ARRAY = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

interface GuestbookLoadingProps {
  count?: number;
}

function GuestbookLoading({ count = 4 }: GuestbookLoadingProps): ReactElement {
  return (
    <Box maxHeight={'75vh'} overflow={'auto'}>
      {ARRAY.slice(0, count).map((item, index) => (
        <Box key={index} paddingBottom={3}>
          <GuestbookPostLoadingComponent />
        </Box>
      ))}
    </Box>
  );
}

export const GuestbookLoadingComponent = memo(GuestbookLoading);
