import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';
import PhotoAlbumIcon from '@mui/icons-material/PhotoAlbum';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface AlbumEditSelectedButtonProps {
  disabled?: boolean;
  disabledButtons?: {
    makeAlbumCover?: boolean;
  };
  onMakeAlbumCover?: () => void;
  onRotateRight?: () => void;
  onDeleteSelected?: () => void;
}

function AlbumEditSelectedButton({
  disabled,
  disabledButtons,
  onMakeAlbumCover,
  onDeleteSelected,
}: AlbumEditSelectedButtonProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const onClick = useCallback(() => {
    setOpen(!open);
  }, [setOpen, open]);

  const onBlur = useCallback(() => {
    setTimeout(() => setOpen(false), 100);
  }, [setOpen]);

  return (
    <Box onBlur={onBlur}>
      <Button
        variant="outlined"
        onClick={onClick}
        disableElevation
        fullWidth
        disabled={disabled}
      >
        <EditIcon sx={{ paddingRight: '7px' }} />
        {t('album-edit-selected.button')}
      </Button>
      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
        sx={(theme) => ({
          position: 'absolute',
          zIndex: theme.zIndex.drawer + 1,
          background: '#FFFFFF',
          border: '1px solid #E5E5E5',
          boxSizing: 'border-box',
          boxShadow: '-1px 2px 4px rgba(0, 0, 0, 0.25)',
          borderRadius: '8px',
        })}
      >
        <List component="div" disablePadding>
          <ListItem
            disabled={disabledButtons?.makeAlbumCover}
            sx={{ width: '100%', padding: '0px', margin: '0px' }}
          >
            <ListItemButton onClick={onMakeAlbumCover}>
              <ListItemText primary={t('album-edit-make-album-cover.button')} />
              <ListItemIcon
                sx={{
                  paddingLeft: '10px',
                  minWidth: '30px',
                  color: '#000',
                }}
              >
                <PhotoAlbumIcon />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
          <ListItem sx={{ width: '100%', padding: '0px', margin: '0px' }}>
            <ListItemButton onClick={onDeleteSelected}>
              <ListItemText
                primary={t('album-edit-delete-selected.button')}
                sx={{ paddingLeft: '2px' }}
              />
              <ListItemIcon
                sx={{
                  paddingLeft: '10px',
                  minWidth: '30px',
                  color: '#000',
                }}
              >
                <DeleteOutlineOutlinedIcon />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        </List>
      </Collapse>
    </Box>
  );
}

export const AlbumEditSelectedButtonComponent = memo(AlbumEditSelectedButton);
