import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import List from '@mui/material/List';
import { ListItemProps } from '@mui/material/ListItem';
import Menu from '@mui/material/Menu';
import { useTheme } from '@mui/material/styles';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { memo, useCallback, useState } from 'react';

export enum MORE_BUTTON_DIRECTIONS {
  horizontal = 'horizontal',
  vertical = 'vertical',
}
export interface MoreButtonProps extends Omit<IconButtonProps, 'onClick'> {
  direction?: MORE_BUTTON_DIRECTIONS;
  items: ReadonlyArray<React.ReactElement<ListItemProps>>;
}

function MoreButton({
  items,
  direction = MORE_BUTTON_DIRECTIONS.horizontal,
  ...iconButtonProps
}: MoreButtonProps) {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClickMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  return (
    <>
      <IconButton
        {...iconButtonProps}
        onClick={handleClickMenu}
        aria-expanded={open ? 'true' : undefined}
      >
        {direction === MORE_BUTTON_DIRECTIONS.horizontal ? (
          <MoreHorizIcon />
        ) : (
          <MoreVertIcon />
        )}
      </IconButton>
      {!mobileView ? (
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMenu}
          PaperProps={{
            sx: {
              width: '250px',
            },
          }}
        >
          {items}
        </Menu>
      ) : (
        <SwipeableDrawer
          anchor="bottom"
          open={open}
          onClose={handleCloseMenu}
          onOpen={handleClickMenu}
          disableSwipeToOpen
          sx={(theme) => ({
            zIndex: theme.zIndex.drawer + 4,
          })}
        >
          <List>{items}</List>
        </SwipeableDrawer>
      )}
    </>
  );
}

export const MoreButtonComponent = memo(MoreButton);
