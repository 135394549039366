import { useEffect, useMemo } from 'react';

import { ACCESS_ROLES } from '../../interfaces/entities/access.interface';
import { Patient } from '../../interfaces/entities/patient.interface';
import { useStores } from '../use-stores';

export const usePatientRole = (
  patientId: Patient['id'],
): { role?: ACCESS_ROLES; isPatientRoleAdmin: boolean; loading?: boolean } => {
  const { patientsStore: store, authStore } = useStores();

  useEffect(() => {
    store.fetchRole(patientId);
    authStore.fetchMe();
  }, [store.fetchRole, authStore.fetchMe, patientId]);

  const role = store.roles[patientId];

  const isPatientRoleAdmin = useMemo(() => {
    return role === ACCESS_ROLES.ADMIN;
  }, [role]);

  return {
    role,
    isPatientRoleAdmin,
    loading: store.loadingRole,
  };
};
