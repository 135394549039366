import SaveIcon from '@mui/icons-material/Save';
import Button, { ButtonProps } from '@mui/material/Button';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

function SaveButton({ title, ...props }: ButtonProps & { title?: string }) {
  const { t } = useTranslation();

  return (
    <Button variant={'contained'} disableElevation {...props}>
      {title || (
        <>
          <SaveIcon sx={{ pr: '5px', height: 'auto' }} />
          {t('general-save.button')}
        </>
      )}
    </Button>
  );
}

export const SaveButtonComponent = memo(SaveButton);
