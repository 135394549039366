import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import React, { memo, ReactElement } from 'react';

function AlbumFileCardLoading(): ReactElement {
  return (
    <Card
      sx={{
        boxShadow:
          '0px 1px 1px rgba(0, 0, 0, 0.07), 0px 2px 1px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1)',
        borderTopRightRadius: '0px',
        borderTopLeftRadius: '0px',
        borderBottomRightRadius: '4px',
        borderBottomLeftRadius: '4px',
      }}
    >
      <CardActionArea>
        <Skeleton
          variant={'rectangular'}
          sx={{
            maxWidth: '100%',
          }}
        >
          <CardMedia sx={{ paddingTop: '74%' }}>
            <></>
          </CardMedia>
        </Skeleton>
      </CardActionArea>
      <CardContent
        sx={(theme) => ({ padding: `${theme.spacing(1)} !important` })}
      >
        <Typography gutterBottom variant="caption">
          <Skeleton />
        </Typography>
      </CardContent>
    </Card>
  );
}

export const AlbumFileCardLoadingComponent = memo(AlbumFileCardLoading);
