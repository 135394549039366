import Box from '@mui/material/Box';
import React, { memo, ReactElement } from 'react';

import { STORY_CARD_WIDTH } from '../../../utils/constants';
import { StoryCardLoadingComponent } from './story-card-loading.component';

const ARRAY = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

function StoriesListLoading(): ReactElement {
  return (
    <Box overflow={'auto'}>
      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent={'center'}
        sx={(theme) => ({
          [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-start',
          },
        })}
      >
        {ARRAY.map((item, index) => (
          <Box
            key={index}
            mb={3}
            sx={(theme) => ({
              width: `${STORY_CARD_WIDTH}px`,
              [theme.breakpoints.up('md')]: {
                mr: 2,
              },
            })}
            display={'block'}
            height={'auto'}
          >
            <StoryCardLoadingComponent />
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export const StoriesListLoadingComponent = memo(StoriesListLoading);
