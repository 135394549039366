import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { memo } from 'react';

interface FormWrapperProps {
  isLoading?: boolean;
  title?: string;
  children: React.ReactNode;
  BackButton?: React.ReactNode;
}

function FormWrapper({ children, isLoading, title }: FormWrapperProps) {
  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      spacing={5}
      sx={{
        position: 'relative',
      }}
    >
      {title && (
        <Grid item>
          <Typography variant="h4">{title}</Typography>;
        </Grid>
      )}
      <Grid item>{children}</Grid>
      {isLoading && (
        <Backdrop
          open
          sx={(theme) => ({
            zIndex: theme.zIndex.drawer + 1,
            background: 'rgba(255,255,255,0.7)',
            position: 'absolute',
          })}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </Grid>
  );
}

export const FormWrapperComponent = memo(FormWrapper);
