import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { CREATE_PATH } from '../configs/profile-routes';

export function useCreateButton(): {
  onClick: () => void;
} {
  const history = useHistory();
  const onClick = useCallback(() => {
    const newPath =
      history.location.pathname +
      (history.location.pathname.endsWith('/')
        ? CREATE_PATH
        : `/${CREATE_PATH}`);
    history.push(newPath);
  }, [history, history.location.pathname]);

  return {
    onClick,
  };
}
