import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  DeleteDialogComponent,
  DeleteDialogProps,
} from '../dialog/delete-dialog.component';

export type GuestbookPostDeleteDialog = Pick<
  DeleteDialogProps,
  'open' | 'onCancel' | 'onConfirm'
>;

function GuestbookPostDeleteDialog(props: GuestbookPostDeleteDialog) {
  const { t } = useTranslation();
  return (
    <DeleteDialogComponent
      {...props}
      title={t('guestbook-like-delete.tooltip')}
      contentText={t('guestbook-post-delete.confirm')}
    />
  );
}

export const GuestbookPostDeleteDialogComponent = memo(
  GuestbookPostDeleteDialog,
);
