import { User } from '../user.interface';
import { ACCESS_ROLES } from './access.interface';
import { BaseEntity } from './base-entity.interface';
import { Patient } from './patient.interface';

export enum INVITATION_STATUSES {
  PENDING = 'pending',
  DECLINED = 'declined',
  ACCEPTED = 'accepted',
}

export interface Invitation extends BaseEntity {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  role: ACCESS_ROLES;
  status: INVITATION_STATUSES;
  patient: Patient;
  createdBy?: User;
  existingRole?: ACCESS_ROLES;
}
