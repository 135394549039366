import { TFunction } from 'i18next';

import { MIN_DATE } from '../../utils/constants';
import { validateDate } from './is-date-valid';

export function validateBirthDate(
  t: TFunction,
  value?: Date | null,
): undefined | string {
  return validateDate(value, {
    optional: true,
    validationError: t('patient-edit-birth-date.error'),
    min: MIN_DATE,
    minError: t('patient-edit-birth-date.error'),
    max: new Date(),
    maxError: t('patient-edit-birth-date-future-date.error'),
  });
}
