import { AxiosInstance } from 'axios';
import {
  action,
  AnnotationsMap,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';

import { ListResponse } from '../../interfaces/list-response.interface';
import { getRandomId } from '../../utils/get-random-id';

/**
 * Abstract class for Base Store (based on using MobX)
 */
export abstract class BaseStore<T extends { id: string }> {
  /**
   * List of items
   */
  items: ReadonlyArray<T> = [];
  /**
   * Current item
   */
  item: T | null = null;
  /**
   * Flag of loading list
   */
  loading?: boolean;
  /**
   * Flag of loading one
   */
  loadingOne?: boolean;
  /**
   * Number of total pages for list
   */
  totalPages = 0;
  /**
   * Number of current page for list
   */
  currentPage = 0;

  /**
   * Map for annotation that is using by MobX
   */
  protected readonly annotations: AnnotationsMap<BaseStore<T>, never> = {
    items: observable,
    item: observable,
    loading: observable,
    loadingOne: observable,
    totalPages: observable,
    currentPage: observable,
    list: action,
    one: action,
    hasMore: computed,
    itemsObj: computed,
  };

  /**
   *
   * @param api AxiosInstance
   * @param entitiesName string. Plural name of entity, it is using for REST API url
   */
  constructor(
    protected readonly api: AxiosInstance,
    protected entitiesName: string,
  ) {
    makeObservable(this, this.annotations);
  }

  /**
   * Async function to fetch list of items
   * @param page number. Page number of fetching list
   * @param limit number. limit of number items per page
   * @param prefix string. Prefix is using for setting string at the begin of REST API url
   */
  async list(page = 1, limit = 50, prefix?: string, end?: string) {
    if (this.currentPage >= page) {
      return;
    }

    if (this.loading) {
      return;
    }

    runInAction(() => {
      this.loading = true;
    });
    try {
      const { data } = await this.api.get<ListResponse<T>>(
        this.getUrl(prefix, end),
        {
          params: {
            page,
            limit,
          },
        },
      );
      runInAction(() => {
        this.items = data.items;
        this.totalPages = data.meta.totalPages || 0;
        this.currentPage = data.meta.currentPage;
      });
    } catch (err) {
      console.error(err);
    }
    runInAction(() => {
      this.loading = false;
    });
  }

  /**
   * Async function to load more items
   * @param limit number. limit of number items per page
   * @param prefix string. Prefix is using for setting string at the begin of REST API url
   */
  async loadMore(limit = 50, prefix?: string) {
    try {
      const { data } = await this.api.get<ListResponse<T>>(
        this.getUrl(prefix),
        {
          params: {
            page: this.currentPage + 1,
            limit,
          },
        },
      );
      runInAction(() => {
        this.items = [...this.items, ...data.items];
        this.totalPages = data.meta.totalPages || 0;
        this.currentPage = data.meta.currentPage;
      });
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * Async function to get one item by id
   * @param id string. id of entity
   * @param force boolean. Flag to force fetch item from REST API, default false
   * @param prefix string. Prefix is using for setting string at the begin of REST API url
   * @returns
   */
  async one(id: T['id'], force = false, prefix?: string) {
    if (!id) {
      return;
    }

    if (!force && this.item?.id === id) {
      return this.item;
    }

    if (!force && this.itemsObj[id]) {
      return runInAction(() => {
        this.loadingOne = false;
        this.item = this.itemsObj[id];
      });
    }

    if (this.loadingOne) {
      return;
    }

    runInAction(() => {
      this.loadingOne = true;
    });

    try {
      const { data } = await this.api.get<T>(`${this.getUrl(prefix)}/${id}`);
      runInAction(() => {
        this.item = data;
      });
    } catch (err) {
      console.error(err);
    }

    runInAction(() => {
      this.loadingOne = false;
    });
  }

  /**
   * Async function to create entity
   * @param data Partial<T>. Data to save
   * @param prefix string. Prefix is using for setting string at the begin of REST API url
   */
  async create(data: Partial<T>, prefix?: string): Promise<T | null> {
    const temporaryItem: T = { ...data, id: getRandomId() } as T;

    runInAction(() => {
      this.item = temporaryItem;
      this.items = [temporaryItem, ...this.items];
    });

    try {
      const { data: createdItem } = await this.api.post(
        this.getUrl(prefix),
        data,
      );
      runInAction(() => {
        this.item = createdItem;
        this.items = this.items.reduce((arr: ReadonlyArray<T>, item: T) => {
          if (item.id === temporaryItem.id) {
            return [...arr, { ...item, ...createdItem }];
          }
          return [...arr, item];
        }, []);
      });
    } catch (err) {
      console.error(err);
      return null;
    }

    return this.item;
  }

  /**
   * Async function to update entity
   * @param id string. id of entity
   * @param data Partial<T>. Data to save
   * @param prefix string. Prefix is using for setting string at the begin of REST API url
   */
  async update(
    id: T['id'],
    data: Partial<T>,
    prefix?: string,
  ): Promise<T | null> {
    const temporaryItem: T = { ...data, id } as T;

    runInAction(() => {
      this.item = temporaryItem;
      this.items = this.items.reduce((arr: ReadonlyArray<T>, item: T) => {
        if (item.id === temporaryItem.id) {
          return [...arr, { ...item, ...temporaryItem }];
        }
        return [...arr, item];
      }, []);
    });

    try {
      const { data: updatedItem } = await this.api.put(
        `${this.getUrl(prefix)}/${id}`,
        data,
      );
      runInAction(() => {
        this.item = updatedItem;
        this.items = this.items.reduce((arr: ReadonlyArray<T>, item: T) => {
          if (item.id === temporaryItem.id) {
            return [...arr, { ...item, ...updatedItem }];
          }
          return [...arr, item];
        }, []);
      });
    } catch (err) {
      console.error(err);
    }
    return this.item;
  }

  /**
   * Async function to delete entity by id
   * @param id string. id of entity
   * @param prefix string. Prefix is using for setting string at the begin of REST API url
   */
  async delete(id: T['id'], prefix?: string): Promise<void> {
    runInAction(() => {
      this.item = null;
      this.items = this.items.filter((item) => item.id !== id);
    });

    try {
      await this.api.delete(`${this.getUrl(prefix)}/${id}`);
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * Computed property to check 'do we have more items'
   */
  get hasMore() {
    return !this.currentPage || this.currentPage < this.totalPages;
  }

  /**
   * Computed property to easier find by id in object: <id, item>
   */
  get itemsObj(): Record<T['id'], T> {
    return this.items.reduce((obj: Record<T['id'], T>, item: T) => {
      obj[item.id as T['id']] = item;
      return obj;
    }, {} as Record<T['id'], T>);
  }

  /**
   * Function to define REST API url for entity
   * @param prefix string. Prefix is using for setting string at the begin of REST API url
   * @returns string
   */
  protected getUrl(prefix?: string, end?: string): string {
    const withPrefix = !prefix
      ? this.entitiesName
      : `${prefix}/${this.entitiesName}`;
    return !end ? withPrefix : `${withPrefix}${end}`;
  }
}
