import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import React, { memo, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { RotateRightButtonComponent } from '../components/buttons/rotate-right-button.component';
import { CheckboxComponent } from '../components/checkbox.component';
import { StarCheckboxComponent } from '../components/star-checkbox.component';
import { AlbumFilePreview } from '../interfaces/album-file-preview.interface';
import { AlbumFile } from '../interfaces/entities/album-file.interface';
import { EMPTY_IMAGE } from '../utils/constants';
import { isVideoType } from '../utils/is-video-type';
import { isVideoUrl } from '../utils/is-video-url';
import { AssistiveTextComponent } from './shared/assistive-text.component';
import { DescriptionFieldComponent } from './shared/description-field.component';

interface AlbumFileFormProps {
  file: AlbumFilePreview;
  rotation?: number;
  isAlbumCover?: boolean;
  onClickSelect?: (id: AlbumFile['id']) => void;
  onClickRotate?: (id: AlbumFile['id']) => void;
  onChange?: (id: AlbumFile['id'], data: Partial<AlbumFile>) => void;
  onClick?: (id: AlbumFile['id']) => void;
}

function AlbumFileFormBase({
  file,
  rotation,
  isAlbumCover,
  onChange,
  onClickSelect,
  onClickRotate,
  onClick,
}: AlbumFileFormProps) {
  const { t } = useTranslation();
  const [description, setDescription] = useState(file?.description || '');
  const [isFavorite, setIsFavorite] = useState(file?.isFavorite || false);

  const videoRef = useRef<HTMLVideoElement>(null);

  const onChangeDescription = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setDescription(event.target.value);
      if (onChange) {
        onChange(file.id, {
          ...file,
          description: event.target.value,
          isFavorite,
        });
      }
    },
    [setDescription, file, file.id, onChange, description, isFavorite],
  );

  const onChangeIsFavorite = useCallback(
    (value: boolean) => {
      setIsFavorite(value);
      if (onChange) {
        onChange(file.id, {
          ...file,
          description,
          isFavorite: value,
        });
      }
    },
    [setIsFavorite, file, file.id, onChange, description],
  );

  const onChangeSelect = useCallback(() => {
    if (onClickSelect) {
      onClickSelect(file.id);
    }
  }, [onClickSelect, file.id]);

  const onClickRotateButton = useCallback(() => {
    if (onClickRotate) {
      onClickRotate(file.id);
    }
  }, [onClickRotate, file.id]);

  const video = useMemo(() => {
    if (!file) {
      return false;
    }
    return (file.type && isVideoType(file.type)) || isVideoUrl(file.url);
  }, [file?.url, file?.type]);

  const onClickCardAction = useCallback(async () => {
    if (!video && onClick) {
      onClick(file.id);
    }

    if (videoRef?.current) {
      if (videoRef.current.paused) {
        await videoRef.current.play();
        videoRef.current.muted = false;
        videoRef.current.controls = true;
      } else {
        videoRef.current.pause();
        videoRef.current.muted = true;
        videoRef.current.controls = false;
      }
    }
  }, [video, onClick, file.id]);

  return (
    <Card
      sx={{
        boxShadow:
          '0px 1px 1px rgba(0, 0, 0, 0.07), 0px 2px 1px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1)',
        borderTopRightRadius: '0px',
        borderTopLeftRadius: '0px',
        borderBottomRightRadius: '4px',
        borderBottomLeftRadius: '4px',
        position: 'relative',
      }}
    >
      <CardActionArea onClick={onClickCardAction}>
        <CardMedia
          image={file?.url || EMPTY_IMAGE}
          sx={{
            paddingTop: '100%',
            transform: `rotate(${rotation}deg)`,
            backgroundSize: 'contain',
            position: 'relative',
          }}
        >
          {video && (
            <video
              ref={videoRef}
              src={file?.url}
              muted
              style={{
                position: 'absolute',
                width: '100%',
                top: '50%',
                transform: 'translate(0,-50%)',
                maxHeight: '100%',
              }}
            />
          )}
        </CardMedia>
        {isAlbumCover && (
          <Box
            sx={{
              position: 'absolute',
              bottom: '7px',
              width: '100%',
              textAlign: 'center',
              height: '32px',
              lineHeight: '28px',
              background: 'rgba(0,0,0, 0.6)',
              borderTop: '2px solid #F7F7F7',
              borderBottom: '2px solid #F7F7F7',
              color: '#F7F7F7',
              fontFamily: 'Roboto',
              fontSize: '16px',
              fontWeight: 500,
            }}
          >
            {t('album-edit-indicate-album-cover.label')}
          </Box>
        )}
      </CardActionArea>
      <CardContent
        sx={(theme) => ({ padding: `${theme.spacing(1)} !important` })}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={1}
        >
          <Grid item xs>
            <DescriptionFieldComponent
              value={description}
              handleChange={onChangeDescription}
              minRows={2}
              label={t('album-edit-caption.label')}
            />
            <AssistiveTextComponent>
              {t('album-edit-caption.assistive')}
            </AssistiveTextComponent>
          </Grid>
          <Grid item>
            <StarCheckboxComponent
              checked={!!isFavorite}
              onChange={onChangeIsFavorite}
            />
          </Grid>
        </Grid>
      </CardContent>
      <Box sx={{ position: 'absolute', top: 4, left: 4 }}>
        <CheckboxComponent onChange={onChangeSelect} />
      </Box>
      <Box sx={{ position: 'absolute', top: 4, right: 4 }}>
        <RotateRightButtonComponent onClick={onClickRotateButton} />
      </Box>
    </Card>
  );
}

export const AlbumFileForm = memo(AlbumFileFormBase);
