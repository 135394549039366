import { startCase } from 'lodash';
import React, { memo, ReactElement, ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import PageProps from '../interfaces/page-props.interface';

function PageBase({
  title = 'MinMemoria',
  children,
}: PageProps & { children?: ReactNode }): ReactElement {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{startCase(t(title as string))}</title>
      </Helmet>
      {children}
    </>
  );
}

export const Page = memo(PageBase);
