const REGEX_WITH_TRANSFORMATIONS =
  /^(https:\/\/res\.cloudinary\.com\/.+\/image\/upload)\/(.+)\/(v\d+\/.+)$/;
const REGEX_WITHOUT_TRANSFORMATIONS =
  /^(https:\/\/res\.cloudinary\.com\/.+\/image\/upload)\/(v.+)$/;

export function addTransformationsToImageURL(
  url: string,
  transformations: string[],
): string {
  if (!url) {
    return url;
  }
  const matchResultWithTransformations = url.match(REGEX_WITH_TRANSFORMATIONS);
  const matchResultWithoutTransformations = url.match(
    REGEX_WITHOUT_TRANSFORMATIONS,
  );

  if (!matchResultWithTransformations && !matchResultWithoutTransformations) {
    return url;
  }

  let prefix = '';
  let transformationsStr = '';
  let endStr = '';

  if (matchResultWithTransformations) {
    prefix = matchResultWithTransformations[1];
    transformationsStr = matchResultWithTransformations[2];
    endStr = matchResultWithTransformations[3];
  } else if (matchResultWithoutTransformations) {
    prefix = matchResultWithoutTransformations[1];
    endStr = matchResultWithoutTransformations[2];
  } else {
    return url;
  }

  const existingTransformations = transformationsStr
    ? transformationsStr.split(',')
    : [];

  return `${prefix}/${[...existingTransformations, transformations].join(
    ',',
  )}/${endStr}`;
}
