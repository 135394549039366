import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import smoothscroll from 'smoothscroll-polyfill';

import { LayoutComponent } from './components/layout.component';
import { LocaleRoute } from './components/locale-route.component';
import { MY_PATIENTS_ROUTE, routes } from './configs/routes';
import { InstallWidgetContainer } from './containers/install-widget.container';
import { IntercomProviderContainer } from './containers/intercom/intercom-provider.container';
import theme from './theme';
import { LOCALES } from './utils/constants';

smoothscroll.polyfill();

const MAX_SNACK = 3;

function App() {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={MAX_SNACK}>
        <IntercomProviderContainer>
          <Router>
            <Switch>
              {Object.keys(LOCALES).map((locale) => (
                <Route
                  key={`${locale}`}
                  path={`/${locale}/*`}
                  render={(props) => (
                    <LocaleRoute locale={locale} route={props} />
                  )}
                  exact={false}
                />
              ))}
              {routes.map((route) => (
                <Route
                  key={`${route.key}`}
                  path={`${route.path}`}
                  render={() =>
                    route.component && (
                      <LayoutComponent>
                        <route.component {...route} />
                      </LayoutComponent>
                    )
                  }
                  exact={route.exact}
                />
              ))}
              <Route
                path={'/'}
                exact
                component={() => {
                  return (
                    <LayoutComponent>
                      <Redirect to={MY_PATIENTS_ROUTE.path} />
                    </LayoutComponent>
                  );
                }}
              />
            </Switch>
          </Router>
        </IntercomProviderContainer>
      </SnackbarProvider>
      <InstallWidgetContainer />
    </ThemeProvider>
  );
}
export default App;
