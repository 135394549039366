import Box, { BoxProps } from '@mui/material/Box';
import React, { memo } from 'react';

interface ErrorWrapperComponentProps extends BoxProps {
  error?: boolean;
}

function ErrorWrapper({ error, sx, ...props }: ErrorWrapperComponentProps) {
  return (
    <Box
      {...props}
      sx={{
        ...sx,
        animation: error ? 'shake 0.45s' : undefined,
        '@keyframes shake': {
          '0%': {
            transform: 'translateX(0)',
          },
          '15%': {
            transform: 'translateX(-2px)',
          },
          '30%': {
            transform: 'translateX(2px)',
          },
          '45%': {
            transform: 'translateX(-2px)',
          },
          '60%': {
            transform: 'translateX(2px)',
          },
          '75%': {
            transform: 'translateX(-2px)',
          },
          '90%': {
            transform: 'translateX(2px)',
          },
          '100%': {
            transform: 'translateX(0)',
          },
        },
      }}
    />
  );
}

export const ErrorWrapperComponent = memo(ErrorWrapper);
