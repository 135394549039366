import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Linkify from 'react-linkify';
import VisibilitySensor from 'react-visibility-sensor';

import { GuestbookFileCardComponent } from '../../components/guestbook/guestbook-file-card.component';
import { GuestbookFilesLightboxComponent } from '../../components/guestbook/guestbook-file-lightbox.component';
import { GuestbookLikesComponent } from '../../components/guestbook/guestbook-likes.component';
import { GuestbookReplyDeleteDialogComponent } from '../../components/guestbook/guestbook-reply-delete-dialog.component';
import { GuestbookReplyInputComponent } from '../../components/guestbook/guestbook-reply-input.component';
import { GuestbookReplyMessageComponent } from '../../components/guestbook/guestbook-reply-message.component';
import { usePatientRole } from '../../hooks/patients/use-patient-role';
import { useStores } from '../../hooks/use-stores';
import { GuestbookPost } from '../../interfaces/entities/guestbook-post.interface';
import { GuestbookReply } from '../../interfaces/entities/guestbook-reply.interface';
import { Patient } from '../../interfaces/entities/patient.interface';
import { BLACK_COLOR } from '../../theme';
import { activeVisibilitySensorForGuestbookPost } from '../../utils/active-visibility-sensor-for-guestbook-post';

export interface GuestbookReplyCardProps
  extends Omit<GuestbookReply, 'guestbookFiles'> {
  patientId: Patient['id'];
  guestbookFiles?: GuestbookPost['guestbookFiles'];
  isNew?: GuestbookPost['isNew'];
}

function GuestbookReplyCard({
  id,
  user,
  message,
  createdAt,
  guestbookLikes,
  guestbookFiles,
  patientId,
  parent,
  isNew: isNewDefault,
  readByMe,
}: GuestbookReplyCardProps) {
  const { guestbookPostsStore: store, patientsStore, authStore } = useStores();
  const { role } = usePatientRole(patientId);

  // Making isNew block update only on the initial loading
  const [isNew, setIsNew] = useState(isNewDefault);
  const [showEdit, setShowEdit] = useState<boolean>(false);

  const [indexFileToShow, setIndexFileToShow] = useState<number | null>(null);

  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);

  const [countLikes, setCountLikes] = useState(guestbookLikes.length);
  const [isLiked, setIsLiked] = useState(
    (authStore.me?.id &&
      store.getReplyLikes(patientId, parent.id)?.[id]?.[authStore.me.id]) ||
      false,
  );

  useEffect(() => {
    if (!isNewDefault && isNew) {
      setIsNew(false);
    }
  }, []);

  const likeId = useMemo(() => {
    const likes = store.getReplyLikes(patientId, parent.id);

    if (!authStore.me?.id || !likes[id]) {
      return;
    }
    return likes[id][authStore.me.id];
  }, [id, authStore.me?.id, store.getReplyLikes(patientId, parent.id)]);

  const showEditDelete = useMemo(() => {
    if (!authStore.me?.id || !user?.id) {
      return;
    }
    return authStore.me.id === user.id;
  }, [authStore.me?.id, user?.id, patientId]);

  const showCountLikes = useMemo(() => {
    if (!countLikes) {
      return false;
    }

    if (countLikes === 1 && isLiked) {
      return false;
    }

    return true;
  }, [countLikes, isLiked]);

  const activeToRead = useMemo(() => {
    return !!(
      authStore.me?.id &&
      activeVisibilitySensorForGuestbookPost(authStore.me.id, {
        readByMe,
        user,
      })
    );
  }, [authStore.me?.id, readByMe, user]);

  const onClickLike = useCallback(() => {
    if (isLiked) {
      setIsLiked(false);
      setCountLikes(countLikes - 1);
    } else {
      setIsLiked(true);
      setCountLikes(countLikes + 1);
    }
    if (likeId) {
      store.removeLikeFromReply(patientId, parent.id, id, likeId);
    } else {
      store.addLikeToReply(patientId, parent.id, id);
    }
  }, [id, isLiked, likeId, patientId, parent.id]);

  const onClickFile = useCallback(
    (index: number | string) => {
      setIndexFileToShow(index as number);
    },
    [setIndexFileToShow],
  );

  const onCloseLightBox = useCallback(() => {
    setIndexFileToShow(null);
  }, [setIndexFileToShow]);

  const onClickEdit = useCallback(() => {
    setShowEdit(true);
  }, [setShowEdit]);

  const onCancelEdit = useCallback(() => {
    setShowEdit(false);
  }, [setShowEdit]);

  const onSubmitEdit = useCallback(
    async (data: Partial<GuestbookReply>) => {
      store.updateReplyByPatientId(
        patientId,
        parent.id,
        id,
        data as GuestbookReply,
      );
      onCancelEdit();
    },
    [patientId, id, parent.id, onCancelEdit, store.updateReplyByPatientId],
  );

  const onClickDelete = useCallback(() => {
    setShowDeleteDialog(true);
  }, [setShowDeleteDialog]);

  const onCloseDelete = useCallback(() => {
    setShowDeleteDialog(false);
  }, [setShowDeleteDialog]);

  const onSubmitDelete = useCallback(async () => {
    onCloseDelete();
    store.deleteReplyByPatientId(patientId, parent.id, id);
  }, [patientId, id, onCloseDelete, parent.id, store.deleteReplyByPatientId]);

  const onRead = useCallback(
    (visible: boolean) => {
      if (!visible || !activeToRead) {
        return;
      }

      if (role) {
        patientsStore.readGuestbookPost(
          patientId,
          id,
          undefined,
          authStore.me,
          role,
        );
      }
    },
    [activeToRead, patientId, id, role],
  );

  return (
    <VisibilitySensor
      active={activeToRead}
      delayedCall
      onChange={onRead}
      partialVisibility={'bottom' as unknown as boolean}
    >
      <Box
        sx={{
          ...(!showEdit && isNew
            ? {
                backgroundColor: 'primary.light',
                borderColor: 'primary.main',
                borderStyle: 'solid',
                borderWidth: '1px',
                padding: 2,
              }
            : {}),
          ...(showEdit
            ? {
                backgroundColor: 'secondary.light',
                borderColor: 'secondary.main',
                borderRadius: '3px',
                borderStyle: 'solid',
                borderWidth: '1px',
                padding: 2,
              }
            : {}),
        }}
      >
        {!!(indexFileToShow !== null && guestbookFiles) && (
          <GuestbookFilesLightboxComponent
            files={guestbookFiles}
            defaultIndex={indexFileToShow}
            onClose={onCloseLightBox}
          />
        )}
        <GuestbookReplyDeleteDialogComponent
          open={showDeleteDialog}
          onCancel={onCloseDelete}
          onConfirm={onSubmitDelete}
        />

        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          wrap="nowrap"
          spacing={0.5}
        >
          {user && (
            <Grid item>
              <GuestbookReplyMessageComponent
                {...user}
                createdAt={createdAt}
                showMenuButton={!showEdit && showEditDelete}
                onClickDelete={onClickDelete}
                onClickEdit={onClickEdit}
                isNew={!showEdit && isNew}
              />
            </Grid>
          )}
          {!showEdit ? (
            <>
              <Grid item>
                <Typography
                  variant={'body2'}
                  whiteSpace={'pre-line'}
                  sx={{
                    paddingLeft: '50px',
                    wordBreak: 'break-word',
                  }}
                >
                  <Linkify
                    componentDecorator={(decoratedHref, decoratedText, key) => (
                      <a
                        target="_blank"
                        href={decoratedHref}
                        key={key}
                        style={{ color: BLACK_COLOR }}
                        rel="noreferrer"
                      >
                        {decoratedText}
                      </a>
                    )}
                  >
                    {message}
                  </Linkify>
                </Typography>
              </Grid>
              {!!guestbookFiles?.length && (
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={1}
                    mt={1}
                    sx={{
                      paddingLeft: '50px',
                    }}
                  >
                    {guestbookFiles.map((file, index) => (
                      <Grid item key={index} xs={4} md={3} lg={2} xl={1.5}>
                        <Box>
                          <GuestbookFileCardComponent
                            key={file.id || index}
                            {...file}
                            id={index}
                            onClick={onClickFile}
                          />
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              )}
              <Grid
                item
                sx={{
                  paddingLeft: '57px !important',
                  marginTop: 2,
                }}
              >
                <IconButton
                  onClick={onClickLike}
                  size={'small'}
                  sx={{
                    color: 'secondary.dark',
                    paddingLeft: 0,
                  }}
                >
                  {isLiked ? (
                    <FavoriteIcon fontSize={'small'} />
                  ) : (
                    <FavoriteBorderIcon fontSize={'small'} />
                  )}
                </IconButton>
                {showCountLikes && (
                  <GuestbookLikesComponent
                    items={guestbookLikes}
                    size={'small'}
                  />
                )}
              </Grid>
            </>
          ) : (
            <Grid item>
              <GuestbookReplyInputComponent
                id={id}
                message={message}
                guestbookFiles={guestbookFiles}
                onCancel={onCancelEdit}
                onSubmit={onSubmitEdit}
                parentId={parent?.id || ''}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </VisibilitySensor>
  );
}

export const GuestbookReplyCardContainer = observer(GuestbookReplyCard);
