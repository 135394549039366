import TextField, { TextFieldProps } from '@mui/material/TextField';
import { FieldProps } from 'formik';
import React, { memo } from 'react';

import {
  FieldWrapperComponent,
  FieldWrapperProps,
} from './field-wrapper.component';

interface SingleLineFieldProps
  extends FieldProps,
    Pick<FieldWrapperProps, 'assistiveText' | 'assistiveTextPosition'> {
  label?: string;
  InputProps?: TextFieldProps['InputProps'];
}

function SingleLineFieldBase({ InputProps, ...props }: SingleLineFieldProps) {
  return (
    <FieldWrapperComponent {...props}>
      <TextField
        error={props.meta.touched && !!props.meta.error}
        fullWidth
        variant="outlined"
        label={props.label}
        InputProps={InputProps}
        {...props.field}
      />
    </FieldWrapperComponent>
  );
}

export const SingleLineField = memo(SingleLineFieldBase);
