import React, { memo, useEffect } from 'react';
import { browserName, browserVersion, isIOS } from 'react-device-detect';

import { InstallPWADialogComponent } from '../components/dialog/install-pwa-dialog.component';
import { InstallWidgetComponent } from '../components/install-widget.component';
import { useInstallWidget } from '../hooks/use-install-widget';
import { useStores } from '../hooks/use-stores';

const InstallWidget = () => {
  const { authStore } = useStores();

  useEffect(() => {
    authStore.fetchMe();
  }, [authStore]);

  const logged = !!authStore.me;

  const { show, showDialog, onCloseDialog, onConfirmDialog, ...props } =
    useInstallWidget();

  return (
    <>
      <InstallWidgetComponent
        {...props}
        sx={{
          zIndex: 1459,
          position: 'sticky',
          top: 'auto',
          bottom: 0,
          transition: ' opacity 0.3s ease-in-out, transform 0.3s ease-in-out',
          ...(logged && show
            ? { opacity: 1, transform: 'translateY(0)' }
            : {
                opacity: 0,
                transform: 'translateY(-20px)',
                pointerEvents: 'none',
              }),
        }}
      />
      <InstallPWADialogComponent
        isOpen={showDialog}
        onClose={onCloseDialog}
        onConfirm={onConfirmDialog}
        browserName={browserName}
        browserVersion={browserVersion}
        isIOS={isIOS}
      />
    </>
  );
};

export const InstallWidgetContainer = memo(InstallWidget);
