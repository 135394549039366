import Grid from '@mui/material/Grid';
import React, { memo, ReactElement } from 'react';

import { RelativeCardLoadingComponent } from './relative-card-loading.component';

function RelativesListLoading(): ReactElement {
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={6}
    >
      <Grid item xs={12} sm={6} md={3} lg={2}>
        <RelativeCardLoadingComponent />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={2}>
        <RelativeCardLoadingComponent />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={2}>
        <RelativeCardLoadingComponent />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={2}>
        <RelativeCardLoadingComponent />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={2}>
        <RelativeCardLoadingComponent />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={2}>
        <RelativeCardLoadingComponent />
      </Grid>
    </Grid>
  );
}

export const RelativesListLoadingComponent = memo(RelativesListLoading);
