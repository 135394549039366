import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import React, { memo, ReactElement } from 'react';

import { AvatarComponent } from '../../avatar.component';

function CommonGuestbookPostLoading(): ReactElement {
  return (
    <>
      <Box pb={3}>
        <Typography variant="h6" sx={{ pb: 1 }}>
          <Skeleton />
        </Typography>
        <Typography variant="subtitle1">
          <Skeleton />
        </Typography>
      </Box>

      <Skeleton sx={{ pb: 2 }} />

      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={2}
        pb={2}
        pt={2}
      >
        <Grid item xs={6} md={4} lg={3} xl={2}>
          <Skeleton
            sx={(theme) => ({
              height: '150px',
              [theme.breakpoints.up('sm')]: {
                height: '180px',
              },
              [theme.breakpoints.up('md')]: {
                height: '190px',
              },
              [theme.breakpoints.up('lg')]: {
                height: '200px',
              },
              [theme.breakpoints.up('xl')]: {
                height: '220px',
              },
            })}
          />
        </Grid>
        <Grid item xs={6} md={4} lg={3} xl={2}>
          <Skeleton
            sx={(theme) => ({
              height: '150px',
              [theme.breakpoints.up('sm')]: {
                height: '180px',
              },
              [theme.breakpoints.up('md')]: {
                height: '190px',
              },
              [theme.breakpoints.up('lg')]: {
                height: '200px',
              },
              [theme.breakpoints.up('xl')]: {
                height: '220px',
              },
            })}
          />
        </Grid>
      </Grid>

      <Box pt={3} pb={3}>
        <Typography variant="h6" sx={{ pb: 1 }}>
          <Skeleton />
        </Typography>
        <Typography variant="subtitle1">
          <Skeleton />
        </Typography>
      </Box>

      <List dense>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemAvatar>
              <Skeleton variant="circular">
                <AvatarComponent />
              </Skeleton>
            </ListItemAvatar>
            <ListItemText>
              <Skeleton />
            </ListItemText>
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemAvatar>
              <Skeleton variant="circular">
                <AvatarComponent />
              </Skeleton>
            </ListItemAvatar>
            <ListItemText>
              <Skeleton />
            </ListItemText>
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemAvatar>
              <Skeleton variant="circular">
                <AvatarComponent />
              </Skeleton>
            </ListItemAvatar>
            <ListItemText>
              <Skeleton />
            </ListItemText>
          </ListItemButton>
        </ListItem>
        <Divider />
      </List>
    </>
  );
}

export const CommonGuestbookPostLoadingComponent = memo(
  CommonGuestbookPostLoading,
);
