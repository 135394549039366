import IosShareIcon from '@mui/icons-material/IosShare';
import Box from '@mui/material/Box';
import MUIButton from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { EdgeMenuIconComponent } from '../icons/edge-menu-icon.component';
import { FireFoxA2HSIconComponent } from '../icons/firefox-a2hs-icon.component';
import { MenuIconComponent } from '../icons/menu-icon.component';
import { OperaA2HSIconComponent } from '../icons/opera-a2hs-icon.component';
import { DialogComponent } from './dialog.component';

export interface InstallPWADialogProps {
  isOpen: boolean;
  onConfirm: () => void;
  onClose: () => void;
  browserName?: string;
  browserVersion?: string;
  isIOS?: boolean;
}

function InstallPWADialog({
  onConfirm,
  onClose,
  isOpen,
  browserName = '',
  browserVersion = '',
  isIOS,
}: InstallPWADialogProps) {
  const { t } = useTranslation();

  return (
    <DialogComponent
      isOpen={isOpen}
      onClose={onClose}
      title={t('install-dialog.title')}
      description={
        <Box width="100%" display="flex" flexDirection="column">
          <Box>
            <Typography variant="subtitle1">
              {t('install-dialog.description')}
            </Typography>
            {isIOS ? (
              <ul>
                <li>
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    {t('install-dialog-ios.action1')}
                    <IosShareIcon />
                  </span>
                </li>
                <li>{t('install-dialog-ios.action2')}</li>
              </ul>
            ) : (
              <>
                {browserName.toLowerCase() === 'firefox' &&
                  +browserVersion < 79 && (
                    <ul>
                      <li>
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                          {t('install-dialog-firefox.action1')}
                          <FireFoxA2HSIconComponent />
                        </span>
                      </li>
                      <li>{t('install-dialog-firefox.action2')}</li>
                    </ul>
                  )}

                {browserName.toLowerCase() === 'firefox' &&
                  +browserVersion >= 79 && (
                    <ul>
                      <li>
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                          {t('install-dialog-firefox-new.action1')}
                          <MenuIconComponent />
                        </span>
                      </li>
                      <li>{t('install-dialog-firefox-new.action2')}</li>
                    </ul>
                  )}

                {browserName.toLowerCase() === 'opera' && (
                  <ul>
                    <li>
                      <span style={{ display: 'flex', alignItems: 'center' }}>
                        {t('install-dialog-opera.action1')}
                        <MenuIconComponent />
                      </span>
                    </li>
                    <li>
                      {t('install-dialog-opera.action2.start')}
                      <OperaA2HSIconComponent />
                      {t('install-dialog-opera.action2.end')}
                    </li>
                  </ul>
                )}

                {browserName.toLowerCase() === 'edge' && (
                  <ul>
                    <li>
                      <span style={{ display: 'flex', alignItems: 'center' }}>
                        {t('install-dialog-edge.action1')}
                        <EdgeMenuIconComponent
                          sx={{
                            width: '2em',
                            height: '2em',
                          }}
                        />
                      </span>
                    </li>
                    <li>{t('install-dialog-edge.action2')}</li>
                  </ul>
                )}

                {browserName.toLowerCase() !== 'opera' &&
                  browserName.toLowerCase() !== 'firefox' &&
                  browserName.toLowerCase() !== 'edge' && (
                    <ul>
                      <li>
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                          {t('install-dialog-other.action1')}
                        </span>
                      </li>
                      <li>{t('install-dialog-other.action2')}</li>
                    </ul>
                  )}
              </>
            )}
          </Box>
        </Box>
      }
    >
      <DialogActions>
        <MUIButton
          onClick={onConfirm}
          disableElevation
          variant="contained"
          sx={{
            minWidth: '150px',
          }}
        >
          {t('ok.button')}
        </MUIButton>
      </DialogActions>
    </DialogComponent>
  );
}

export const InstallPWADialogComponent = memo(InstallPWADialog);
