import { AxiosInstance } from 'axios';
import Cookies from 'js-cookie';
import { action, makeObservable, observable, runInAction } from 'mobx';

import { User } from '../interfaces/user.interface';
import {
  AUTH_TOKEN_DOMAIN,
  AUTH_TOKEN_EXPIRES,
  AUTH_TOKEN_KEY,
} from '../utils/constants';

const MAX_CACHE_TIME = 60 * 1000;
const IPAPI_URL = 'https://ipapi.co/json/';

export class AuthStore {
  me?: User;
  loading?: boolean;
  lastTimeAt?: Date;
  countryCode?: string;
  loadingCountryCode = false;

  constructor(
    protected readonly api: AxiosInstance,
    private readonly maxCacheTime = MAX_CACHE_TIME,
  ) {
    makeObservable(this, {
      me: observable,
      loading: observable,
      countryCode: observable,
      loadingCountryCode: observable,
      fetchMe: action,
      fetchCountryCode: action,
      logout: action,
    });
  }

  async fetchMe() {
    const now = Date.now();
    if (
      this.me &&
      (!this.lastTimeAt || now - this.lastTimeAt.getTime() < this.maxCacheTime)
    ) {
      return;
    }

    if (this.loading) {
      return;
    }

    runInAction(() => {
      this.loading = true;
    });

    try {
      const { data } = await this.api.get<User>('/auth/me', {
        // disable caching for this request
        params: { date: new Date().getTime() },
      });
      runInAction(() => {
        this.me = data;
      });
      this.lastTimeAt = new Date();
    } catch (err) {
      runInAction(() => {
        this.me = undefined;
      });
    }
    runInAction(() => {
      this.loading = false;
    });
  }

  async fetchCountryCode(force = false) {
    if (this.loadingCountryCode) {
      return;
    }

    if (this.countryCode && !force) {
      return;
    }

    runInAction(() => {
      this.loadingCountryCode = true;
    });

    try {
      const response = await this.api.get(IPAPI_URL);
      const data = response.data;
      runInAction(() => {
        this.countryCode = data.country_code;
      });
    } catch (err) {
      console.error('Error fetching country:', err);
    }
    runInAction(() => {
      this.loadingCountryCode = false;
    });
  }

  setToken(token: string) {
    Cookies.set(AUTH_TOKEN_KEY, token, {
      domain: AUTH_TOKEN_DOMAIN,
      expires: AUTH_TOKEN_EXPIRES,
    });
    return this.fetchMe();
  }

  logout() {
    Cookies.remove(AUTH_TOKEN_KEY, {
      domain: AUTH_TOKEN_DOMAIN,
    });

    runInAction(() => {
      this.me = undefined;
    });
  }

  async getLoginUrl(lng: string): Promise<string | undefined> {
    try {
      const { data } = await this.api.get<{ url: string }>('/auth/login', {
        params: {
          local: window.location.hostname === 'localhost' ? 'true' : undefined,
          lng,
        },
      });
      return data.url;
    } catch (err) {
      this.logout();
    }
  }
}
