import EditIcon from '@mui/icons-material/Edit';
import Button, { ButtonProps } from '@mui/material/Button';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

interface EditButtonProps extends ButtonProps {
  text?: string;
  iconOnly?: boolean;
}

function EditButton({ text, iconOnly = false, ...props }: EditButtonProps) {
  const { t } = useTranslation();

  if (iconOnly) {
    return (
      <IconButton size="small" {...(props as IconButtonProps)}>
        <EditIcon />
      </IconButton>
    );
  }
  return (
    <Button variant="contained" {...props} disableElevation>
      <EditIcon sx={{ paddingRight: '5px' }} />
      {text || t('general-edit.button')}
    </Button>
  );
}

export const EditButtonComponent = memo(EditButton);
