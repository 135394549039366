import { useEffect, useState } from 'react';

import { getDefaultImageTransformations } from '../utils/get-default-image-transformations';
import { addTransformationsToImageURL } from '../utils/images/add-transformations-to-image-url';

export function useProgressiveImg(
  imageUrl: string,
  height?: number,
  width?: number,
  face?: boolean,
): [string, { blur: boolean }] {
  const defaultImageTransformations = getDefaultImageTransformations(
    height,
    width,
    face,
  );

  const lowQualitySrc = addTransformationsToImageURL(imageUrl, [
    'q_10',
    ...defaultImageTransformations,
  ]);
  const highQualitySrc = addTransformationsToImageURL(imageUrl, [
    'q_100',
    ...defaultImageTransformations,
  ]);

  const [src, setSrc] = useState(lowQualitySrc);

  useEffect(() => {
    setSrc(lowQualitySrc);
    const img = new Image();
    img.src = highQualitySrc;
    img.onload = () => {
      setSrc(highQualitySrc);
    };
  }, [imageUrl, lowQualitySrc, highQualitySrc]);
  return [
    imageUrl ? src : '',
    {
      blur: imageUrl ? src === lowQualitySrc && src !== highQualitySrc : false,
    },
  ];
}
