import BlockIcon from '@mui/icons-material/Block';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Access,
  ACCESS_ROLES,
} from '../../interfaces/entities/access.interface';
import { User } from '../../interfaces/user.interface';
import { getEmails } from '../../utils/get-emails';
import getName from '../../utils/get-name';
import { getPhones } from '../../utils/get-phones';
import { AvatarWithLightboxComponent } from '../avatar-with-lightbox.component';
import { AccessRoleToggleButtonComponent } from '../buttons/access-role-toggle-button.component';

const WITHOUT_ACCESS_VALUE = 'without-access';
const ADMIN_VALUE = 'admin';
const MEMBER_VALUE = 'member';

const WITHOUT_ROLE_OPACITY = 0.5;

interface SelectUserListItemProps extends User {
  accessRole?: Access['role'];
  onRemoveRole?: () => void;
  onSetRole?: (role: Access['role']) => void;
}

function SelectUserListItem({
  accessRole,
  onRemoveRole,
  onSetRole,
  ...user
}: SelectUserListItemProps) {
  const { t } = useTranslation();

  const name = getName(user.firstName, user.lastName);

  const handleChangeAccessRole = useCallback(
    (event: React.MouseEvent<HTMLElement>, newValue: string) => {
      if (onSetRole && newValue === ADMIN_VALUE) {
        return onSetRole(ACCESS_ROLES.ADMIN);
      }

      if (onSetRole && newValue === MEMBER_VALUE) {
        return onSetRole(ACCESS_ROLES.MEMBER);
      }

      if (onRemoveRole && newValue === WITHOUT_ACCESS_VALUE) {
        return onRemoveRole();
      }
    },
    [onSetRole, onRemoveRole],
  );

  const emails = useMemo(() => {
    return getEmails(user.emails);
  }, [user.emails]);

  const phones = useMemo(() => {
    return getPhones(user.phones);
  }, [user.phones]);

  const labelId = useMemo(
    () => `checkbox-list-secondary-label-${user.id}`,
    [user.id],
  );

  const accessRoleValue = useMemo(() => {
    if (accessRole === ACCESS_ROLES.ADMIN) {
      return ADMIN_VALUE;
    }

    if (accessRole === ACCESS_ROLES.MEMBER) {
      return MEMBER_VALUE;
    }

    return WITHOUT_ACCESS_VALUE;
  }, [accessRole]);

  return (
    <ListItem
      sx={{
        px: 1,
        py: 1.5,
      }}
      disablePadding
    >
      <ListItemAvatar
        sx={{ opacity: !accessRole ? WITHOUT_ROLE_OPACITY : undefined }}
      >
        <AvatarWithLightboxComponent name={name} src={user?.image} />
      </ListItemAvatar>
      <ListItemText
        id={labelId}
        primary={name}
        secondary={`${emails}\n${phones}`}
        sx={{ opacity: !accessRole ? WITHOUT_ROLE_OPACITY : undefined }}
        secondaryTypographyProps={{
          sx: {
            whiteSpace: 'pre-line',
          },
        }}
      />
      <ListItemSecondaryAction
        sx={(theme) => ({
          [theme.breakpoints.down('sm')]: {
            position: 'relative',
            transform: 'none',
          },
        })}
      >
        <Box
          sx={(theme) => ({
            [theme.breakpoints.down('sm')]: {
              display: 'flex',
              justifyContent: 'end',
              mt: -2,
              pb: 1.5,
            },
          })}
        >
          <ToggleButtonGroup
            exclusive
            value={accessRoleValue}
            onChange={handleChangeAccessRole}
            size="small"
          >
            <AccessRoleToggleButtonComponent value={WITHOUT_ACCESS_VALUE}>
              <BlockIcon />
            </AccessRoleToggleButtonComponent>
            <AccessRoleToggleButtonComponent
              value={ADMIN_VALUE}
              label={t(`access-list-role-${ACCESS_ROLES.ADMIN}.label`)}
            >
              <ManageAccountsIcon />
            </AccessRoleToggleButtonComponent>
            <AccessRoleToggleButtonComponent
              value={MEMBER_VALUE}
              label={t(`access-list-role-${ACCESS_ROLES.MEMBER}.label`)}
            >
              <PermIdentityIcon />
            </AccessRoleToggleButtonComponent>
          </ToggleButtonGroup>
        </Box>
      </ListItemSecondaryAction>
    </ListItem>
  );
}

export const SelectUserListItemComponent = memo(SelectUserListItem);
