import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Button, { ButtonProps } from '@mui/material/Button';
import FormLabel from '@mui/material/FormLabel';
import { InputBaseComponentProps } from '@mui/material/InputBase';
import { omit } from 'lodash';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { DEEP_SPACE_SPARKLE_COLOR } from '../../theme';
import { InputFileComponent } from '../input-file.component';

const SELECT_IMAGE_ID = 'select-image-id';
interface UploadButtonProps extends ButtonProps {
  onSelectFiles?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  inputProps?: InputBaseComponentProps;
}

function UploadButton(props: UploadButtonProps) {
  const { t } = useTranslation();

  return (
    <>
      <InputFileComponent
        inputProps={props.inputProps || { accept: 'image/*,.heic,.heif' }}
        id={SELECT_IMAGE_ID}
        onChange={props.onSelectFiles}
      />
      <FormLabel htmlFor={SELECT_IMAGE_ID}>
        <Button
          component="span"
          {...omit(props, 'onSelectFiles', 'inputProps')}
          startIcon={<CloudUploadIcon sx={{ pl: '2px' }} />}
          sx={{
            textAlign: 'center',
            bgcolor: DEEP_SPACE_SPARKLE_COLOR,
            color: '#fff',
            borderRadius: '50px',
            '&:hover': {
              bgcolor: DEEP_SPACE_SPARKLE_COLOR,
            },
          }}
        >
          {props.children || t('patient-edit-profile-picture.upload')}
        </Button>
      </FormLabel>
    </>
  );
}

export const UploadButtonComponent = memo(UploadButton);
