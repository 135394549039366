import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import orderBy from 'lodash/orderBy';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { GuestbookPost } from '../../interfaces/entities/guestbook-post.interface';
import { Viewer } from '../../interfaces/viewer.interface';
import { getDateTime } from '../../utils/get-date-time';
import { getEmails } from '../../utils/get-emails';
import getName from '../../utils/get-name';
import { getPhones } from '../../utils/get-phones';
import { AvatarWithLightboxComponent } from '../avatar-with-lightbox.component';
import { VisibilityButtonComponent } from '../buttons/visibility-button.component';
import { DialogComponent } from '../dialog/dialog.component';

export interface GuestbookPosViewsProps {
  items: GuestbookPost['readBy'];
}

function GuestbookPosViews({
  items: defaultItems = [],
}: GuestbookPosViewsProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [showAll, setShowAll] = useState(false);

  const onClick = useCallback(() => {
    setShowAll(true);
  }, [setShowAll]);

  const items = useMemo(() => {
    return orderBy(defaultItems, ['createdAt'], ['asc']);
  }, [defaultItems]);

  const viewers = useMemo<Viewer[]>(() => {
    return items.reduce((arr: Viewer[], { user }) => {
      if (user) {
        arr.push(user);
      }
      return arr;
    }, []);
  }, [items]);

  const onClose = useCallback(() => {
    setShowAll(false);
  }, []);

  return (
    <>
      <VisibilityButtonComponent
        sx={{
          color: 'secondary.dark',
        }}
        viewers={viewers}
        onClick={onClick}
      />
      <DialogComponent
        isOpen={showAll}
        onClose={onClose}
        title={`${t('guestbook-read-by-list-modal.header')}:`}
        maxWidth={'md'}
        fullScreen={fullScreen}
        description={
          <List sx={{ width: '100%' }}>
            {items.map(({ id, user, createdAt }) => {
              const name = getName(user?.firstName, user?.lastName);
              return (
                <ListItem key={id}>
                  <ListItemAvatar>
                    <AvatarWithLightboxComponent
                      key={id}
                      name={name}
                      src={user?.image}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={name}
                    sx={(theme) => ({
                      [theme.breakpoints.down('sm')]: {
                        overflow: 'scroll',
                      },
                    })}
                    secondary={[
                      user?.access?.role &&
                        t(
                          `guestbook-post-role-description-${user?.access?.role}.label`,
                        ),
                      user?.emails?.[0] ? `<${getEmails(user?.emails)}>` : '',
                      user?.phones?.[0] ? `<${getPhones(user?.phones)}>` : '',
                    ]
                      .filter(Boolean)
                      .join(' ')}
                  />
                  <ListItemText
                    sx={{
                      textAlign: 'end',
                      pl: 1,
                    }}
                    secondary={getDateTime(createdAt)}
                  />
                </ListItem>
              );
            })}
          </List>
        }
      ></DialogComponent>
    </>
  );
}

export const GuestbookPosViewsComponent = memo(GuestbookPosViews);
