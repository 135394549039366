import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

export function useFormActions(url: string): {
  onCancel: () => void;
  onConfirm: () => void;
} {
  const history = useHistory();

  const goToUrl = useCallback(() => {
    history.push(url);
  }, [history, url]);

  return {
    onCancel: goToUrl,
    onConfirm: goToUrl,
  };
}
