import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { BackAndManageButtonsComponent } from '../../components/buttons/back-and-manage-buttons.component';
import { ImageComponent } from '../../components/image.component';
import { RelativesOneLoadingComponent } from '../../components/loading/relatives/relatives-one-loading.component';
import { RelativeDeleteDialogComponent } from '../../components/relatives/relative-delete-dialog.component';
import { PROFILE_ROUTE_RELATIVES } from '../../configs/profile-routes';
import { MY_PATIENTS_ROUTE } from '../../configs/routes';
import { usePatientRole } from '../../hooks/patients/use-patient-role';
import { useRelative } from '../../hooks/relatives/use-relative';
import { useDeleteDialog } from '../../hooks/use-delete-dialog';
import { useEditButton } from '../../hooks/use-edit-button';
import { useGoToOrBack } from '../../hooks/use-go-to-or-back';
import { ProfilePageProps } from '../../interfaces/profile-page-props.interface';
import getName from '../../utils/get-name';

function RelativesOne({ profileId }: ProfilePageProps) {
  const { item, fetchLoading, remove } = useRelative(profileId);
  const { t } = useTranslation();
  const { isPatientRoleAdmin } = usePatientRole(profileId);
  const { onClick: onClickEdit } = useEditButton();
  const {
    onClick: onClickDelete,
    onClose: onCloseDeleteDialog,
    open: openDeleteDialog,
  } = useDeleteDialog();

  const backPath = `${MY_PATIENTS_ROUTE.path}/${profileId}/${PROFILE_ROUTE_RELATIVES.path}`;

  const { go: goToList } = useGoToOrBack(backPath);

  const onConfirmDelete = useCallback(async () => {
    await remove();
    goToList();
  }, [remove, goToList]);

  if (fetchLoading || !item) {
    return <RelativesOneLoadingComponent />;
  }

  const { firstName, lastName, description, relationName, image } = item;
  const name = getName(firstName, lastName);

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        wrap={'nowrap'}
        spacing={6}
      >
        <Grid item>
          <BackAndManageButtonsComponent
            onClickEdit={isPatientRoleAdmin ? onClickEdit : undefined}
            onClickDelete={isPatientRoleAdmin ? onClickDelete : undefined}
            backPath={backPath}
            sx={{ paddingBottom: 1 }}
          />

          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={3}
          >
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Box position="relative">
                <ImageComponent image={image} title={name} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={8} lg={9}>
              <Typography variant={'h4'} paddingLeft={'6px'} paddingBottom={4}>
                {name}
              </Typography>
              <table
                style={{
                  overflow: 'hidden',
                  textAlign: 'left',
                }}
              >
                <tbody>
                  {relationName && (
                    <tr
                      style={{
                        verticalAlign: 'top',
                      }}
                    >
                      <th
                        style={{
                          padding: '6px',
                          textAlign: 'left',
                        }}
                      >
                        <Typography variant={'body1'}>
                          {t('relative-relationship.label')}:
                        </Typography>
                      </th>
                      <td style={{ padding: '6px' }}>
                        <Typography variant={'body1'}>
                          {relationName}
                        </Typography>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <table
            style={{
              overflow: 'hidden',
              textAlign: 'left',
            }}
          >
            <tbody>
              {description && (
                <>
                  <tr
                    style={{
                      verticalAlign: 'top',
                    }}
                  >
                    <th
                      style={{
                        padding: '0px 10px 24px 0px',
                        textAlign: 'left',
                      }}
                    >
                      <Typography variant={'h5'}>
                        {`${t('relative-about.label', { firstName })}:`}
                      </Typography>
                    </th>
                  </tr>
                  <tr>
                    <td style={{ padding: '0px 10px 24px 0px' }}>
                      <Typography variant={'body1'} whiteSpace={'pre-line'}>
                        {description}
                      </Typography>
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </Grid>
      </Grid>
      <RelativeDeleteDialogComponent
        open={openDeleteDialog}
        onCancel={onCloseDeleteDialog}
        onConfirm={onConfirmDelete}
      />
    </>
  );
}

export const RelativesOneContainer = observer(RelativesOne);
