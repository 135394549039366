import Typography, { TypographyProps } from '@mui/material/Typography';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

export interface CommonGuestbookPostTitleSelectedPatientsProps
  extends TypographyProps {
  count: number;
}

function CommonGuestbookPostTitleSelectedPatients({
  count,
  ...props
}: CommonGuestbookPostTitleSelectedPatientsProps) {
  const { t } = useTranslation();

  return (
    <Typography variant="body2" {...props}>
      <span style={{ fontWeight: 'bold' }}>{count}</span>{' '}
      {count === 1
        ? t('common-guestbook-post-selected-one.title')
        : t('common-guestbook-post-selected-many.title', {
            count,
          })}
    </Typography>
  );
}

export const CommonGuestbookPostTitleSelectedPatientsComponent = memo(
  CommonGuestbookPostTitleSelectedPatients,
);
