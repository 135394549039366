import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import FormControl, { FormControlProps } from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import * as React from 'react';
import { memo, useCallback, useMemo } from 'react';

import { WHITE_COLOR } from '../theme';
import { areAllOptionsSelected } from '../utils/are-all-options-selected';
import { findOptionForMultiSelectChipLabel } from '../utils/find-option-for-multi-select-chip';
import { getAllAvailableValuesForMultiSelect } from '../utils/get-all-available-values-for-multi-select';

const SELECT_ALL_OR_NONE_OPTION_VALUE = 'select-all-or-none';

export interface MultipleSelectChipProps<
  T extends { id: string; name: string } = { id: string; name: string },
> {
  label?: string;
  options?: T[];
  value?: T['id'][];
  groups?: { groupName: string; options: T[] }[];
  onChange?: (value: T['id'][]) => void;
  formControlProps?: FormControlProps;
  allOrNone?: boolean;
}

function MultipleSelectChip<
  T extends { id: string; name: string } = { id: string; name: string },
>({
  label,
  options = [],
  value = [],
  groups = [],
  onChange,
  formControlProps,
  allOrNone,
}: MultipleSelectChipProps<T>) {
  const handleChange = useCallback(
    (event: SelectChangeEvent<T['id'][]>) => {
      const {
        target: { value },
      } = event;

      const ids =
        typeof value === 'string' ? value.split(',') : value.filter(Boolean);

      if (ids.includes(SELECT_ALL_OR_NONE_OPTION_VALUE)) {
        return;
      }

      if (onChange) {
        onChange(ids);
      }
    },
    [onChange, options, groups],
  );

  const allSelected = useMemo(() => {
    return areAllOptionsSelected(value, options, groups);
  }, [groups, options, value]);

  const handleSelectAllOrNone = useCallback(() => {
    if (onChange && !allSelected) {
      return onChange(getAllAvailableValuesForMultiSelect(options, groups));
    }

    if (onChange && allSelected) {
      return onChange([]);
    }
  }, [allSelected, onChange, options, groups]);

  return (
    <div>
      <FormControl
        sx={{
          m: 1,
          width: 300,
        }}
        {...formControlProps}
      >
        {label && <InputLabel>{label}</InputLabel>}
        <Select
          multiple
          value={value}
          onChange={handleChange}
          input={<OutlinedInput label={label} />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip
                  key={value}
                  variant="outlined"
                  label={findOptionForMultiSelectChipLabel(
                    value,
                    options,
                    groups,
                  )}
                />
              ))}
            </Box>
          )}
        >
          {(groups || []).map(({ groupName, options }) => [
            <ListSubheader color={'primary'} key={groupName} disableSticky>
              {groupName}
            </ListSubheader>,
            options.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                <Checkbox checked={value.includes(id)} />
                <ListItemText primary={name} />
              </MenuItem>
            )),
          ])}
          {(options || []).map(({ id, name }) => (
            <MenuItem key={id} value={id}>
              <Checkbox checked={value.includes(id)} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
          {allOrNone && (
            <MenuItem
              onClick={handleSelectAllOrNone}
              value={SELECT_ALL_OR_NONE_OPTION_VALUE}
              sx={(theme) => ({
                position: 'sticky',
                bottom: theme.spacing(1),
                background: WHITE_COLOR,
                '&:hover': {
                  bgcolor: theme.palette.grey[100],
                },
              })}
            >
              <ListItemText
                sx={{
                  textDecoration: 'underline',
                  pl: '10px',
                }}
                primary={!allSelected ? 'Select all' : 'Deselect all'}
              />
            </MenuItem>
          )}
        </Select>
      </FormControl>
    </div>
  );
}

export const MultipleSelectChipComponent = memo(
  MultipleSelectChip,
) as typeof MultipleSelectChip;
