import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

export function useGoToOrBack(path?: string): {
  go: () => void;
} {
  const browserHistory = useHistory();

  const go = useCallback(() => {
    if (path) {
      return browserHistory.push(path);
    }
    browserHistory.goBack();
  }, [browserHistory, path]);

  return { go };
}
