import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Invitation } from '../../interfaces/entities/invitation.interface';
import getName from '../../utils/get-name';
import { AvatarComponent } from '../avatar.component';
import { ButtonWithTimerComponent } from '../buttons/button-with-timer.component';
import { InvitationDeleteDialogComponent } from './invitation-delete-dialog.component';

const RESEND_INVITATION_TIME = 30; // sec

interface InvitationCardProps extends Invitation {
  onClickDelete?: (id: Invitation['id']) => void;
  onClickResend?: (id: Invitation['id']) => void;
}

function InvitationCard({
  id,
  firstName,
  lastName,
  email,
  phone,
  role,
  onClickDelete,
  onClickResend,
}: InvitationCardProps) {
  const { t } = useTranslation();

  const name = getName(firstName, lastName);
  const contactInfo = [email, phone].filter(Boolean).join(' | ');

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const onClickDeleteButton = useCallback(() => {
    setOpenDeleteDialog(true);
  }, [setOpenDeleteDialog]);

  const onCloseDeleteDialog = useCallback(() => {
    setOpenDeleteDialog(false);
  }, [setOpenDeleteDialog]);

  const onConfirmDelete = useCallback(() => {
    setOpenDeleteDialog(false);

    if (onClickDelete) {
      onClickDelete(id);
    }
  }, [id, onClickDelete, setOpenDeleteDialog]);

  const onClickResendButton = useCallback(() => {
    if (onClickResend) {
      onClickResend(id);
    }
  }, [id, onClickResend]);

  let lg = 12;
  let xl = 12;
  if (onClickResend) {
    lg = lg - 2.5;
    xl = xl - 2;
  }

  if (onClickDelete) {
    lg = lg - 2.5;
    xl = xl - 2;
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={1}
    >
      <Grid item xs={12} lg={lg} xl={xl}>
        <Grid container direction="row" alignItems="center">
          <Grid item xs={10} md={9} lg={7} xl={5}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
            >
              <Grid item>{name && <AvatarComponent name={name} />}</Grid>
              <Grid item>
                <Typography
                  variant={'subtitle1'}
                  color={'rgba(0, 0, 0, 0.87)'}
                  mb={0.5}
                >
                  {name}
                </Typography>
                <Typography variant={'body2'} color={'rgba(0, 0, 0, 0.6)'}>
                  {contactInfo}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2} md={3} lg={5} xl={7}>
            {role && (
              <Typography variant={'subtitle1'} color={'rgba(0, 0, 0, 0.87)'}>
                {t(`access-list-role-${role}.label`)}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
      {(onClickResend || onClickDelete) && (
        <Grid
          item
          xs={12}
          lg={onClickResend && onClickDelete ? 5 : 2.5}
          xl={onClickResend && onClickDelete ? 4 : 2}
        >
          <Grid
            container
            direction="row"
            justifyContent={'flex-end'}
            alignItems="flex-start"
            spacing={1}
          >
            {onClickResend && (
              <Grid item xs={6}>
                <ButtonWithTimerComponent
                  time={RESEND_INVITATION_TIME}
                  variant="outlined"
                  onClick={onClickResendButton}
                  fullWidth
                  startIcon={<SendIcon />}
                  timerLabel={'access-list-pending-invite-resend-helper.label'}
                >
                  {t('access-list-pending-invite-resend.button')}
                </ButtonWithTimerComponent>
              </Grid>
            )}
            {onClickDelete && (
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  onClick={onClickDeleteButton}
                  fullWidth
                  startIcon={<DeleteIcon />}
                >
                  {t('access-list-pending-invite-delete.button')}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}

      <InvitationDeleteDialogComponent
        open={openDeleteDialog}
        onCancel={onCloseDeleteDialog}
        onConfirm={onConfirmDelete}
      />
    </Grid>
  );
}

export const InvitationCardComponent = memo(InvitationCard);
