import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { memo } from 'react';

import { Unit } from '../../interfaces/entities/unit.interface';
import { AvatarWithLightboxComponent } from '../avatar-with-lightbox.component';
import { GiveAccessToNursesButtonComponent } from '../buttons/give-access-to-nurses-button.component';
interface AccessDepartmentCardProps extends Unit {
  onClickGiveAccess?: () => void;
  loadingGiveAccessButton?: boolean;
}

function AccessDepartmentCard({
  name,
  picture,
  onClickGiveAccess,
  loadingGiveAccessButton,
}: AccessDepartmentCardProps) {
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
    >
      <Grid item>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <AvatarWithLightboxComponent name={name} src={picture} />
          </Grid>
          <Grid item>
            <Typography
              variant={'subtitle1'}
              color={'rgba(0, 0, 0, 0.87)'}
              mb={0.5}
            >
              {name}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        {onClickGiveAccess && (
          <GiveAccessToNursesButtonComponent
            onClick={onClickGiveAccess}
            loading={loadingGiveAccessButton}
            fullWidth
          />
        )}
      </Grid>
    </Grid>
  );
}

export const AccessDepartmentCardComponent = memo(AccessDepartmentCard);
