import SvgIcon from '@mui/material/SvgIcon';
import React, { memo } from 'react';

function MenuIcon() {
  return (
    <SvgIcon viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 6a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm0 6a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm-2 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
        fill="context-fill"
        fillOpacity=".8"
      ></path>
    </SvgIcon>
  );
}

export const MenuIconComponent = memo(MenuIcon);
