import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { DEFAULT_LOCALE, DEFAULT_LOCALES_LIST } from './utils/constants';

const resources = DEFAULT_LOCALES_LIST.reduce(
  (
    obj: Record<
      string,
      {
        translation: Record<string, string>;
      }
    >,
    { value },
  ) => {
    try {
      obj[value] = {
        translation: require(`./configs/locales/${value}/translation.json`),
      };
      // eslint-disable-next-line no-empty
    } catch (err) {}

    return obj;
  },
  {},
);

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    keySeparator: false, // we do not use keys in form messages.welcome
    fallbackLng: DEFAULT_LOCALE.value,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      useSuspense: false,
    },
  })
  .then(() => {
    const currentLanguage = DEFAULT_LOCALES_LIST.filter(
      ({ value }) => value === i18n.language,
    )[0];

    if (!currentLanguage) {
      i18n.changeLanguage(DEFAULT_LOCALE.value);
    }
  });

export default i18n;
