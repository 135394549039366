import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { memo, useCallback, useMemo } from 'react';

import { GuestbookPreviewFile } from '../../interfaces/guestbook-preview-file.interface';
import { GuestbookFileCardComponent } from './guestbook-file-card.component';

interface CommonGuestbookPostFilesProps {
  files?: (GuestbookPreviewFile & { id: string })[];
  onDelete?: (id: string) => void;
  onClick?: (id: string) => void;
  rowHeight?: number;
}

const DEFAULT_ROW_HEIGHTS = {
  mobile: 160,
  tablet: 175,
  desktop: 200,
};

const GRID_COLUMNS = 12;

const COLUMNS = {
  mobile: 2,
  tablet: 3,
  desktop: 4,
};

function CommonGuestbookPostFiles({
  files = [],
  rowHeight,
  onDelete,
  onClick,
}: CommonGuestbookPostFilesProps) {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const tabletView = useMediaQuery(theme.breakpoints.down('lg'));

  const handleRemoveItem = useCallback(
    (id: string) => {
      if (onDelete) {
        onDelete(id);
      }
    },
    [onDelete],
  );

  const handleClickItem = useCallback(
    (id: string) => {
      if (onClick) {
        onClick(id);
      }
    },
    [onClick],
  );
  const cols = useMemo(() => {
    if (mobileView) {
      return COLUMNS.mobile;
    }
    if (tabletView) {
      return COLUMNS.tablet;
    }

    return COLUMNS.desktop;
  }, [mobileView, tabletView]);

  const height = useMemo(() => {
    if (rowHeight) {
      return rowHeight;
    }

    if (mobileView) {
      return DEFAULT_ROW_HEIGHTS.mobile;
    }

    if (tabletView) {
      return DEFAULT_ROW_HEIGHTS.tablet;
    }

    return DEFAULT_ROW_HEIGHTS.desktop;
  }, [rowHeight]);

  const lastLinesStartIndex = useMemo(() => {
    return files.length - Math.floor(files.length / cols) * cols;
  }, [files, cols]);

  return (
    <Grid container spacing={2} columns={GRID_COLUMNS}>
      {files.map((file, index) => {
        const xs =
          index >= lastLinesStartIndex
            ? Math.floor(GRID_COLUMNS / cols)
            : Math.floor(GRID_COLUMNS / lastLinesStartIndex);

        return (
          <Grid key={file.id} item xs={xs}>
            <Box
              sx={{
                m: 2,
                transition: 'transform .2s',
                '&:hover': {
                  transform: 'scale(1.02)',
                },
              }}
            >
              <GuestbookFileCardComponent
                {...file}
                height={`${height}px`}
                onClose={() => handleRemoveItem(file.id)}
                onClick={() => handleClickItem(file.id)}
              />
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
}

export const CommonGuestbookPostFilesComponent = memo(CommonGuestbookPostFiles);
