import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
// import MobileStepper from '@mui/material/MobileStepper';
import Stack from '@mui/material/Stack';
import Step from '@mui/material/Step';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { RoundBackButtonComponent } from '../components/buttons/round-back-button.component';
import { RoundForwardButtonComponent } from '../components/buttons/round-forward-button.component';
import { useWindowDimensions } from '../hooks/use-window-dimensions';
import { DEEP_SPACE_SPARKLE_COLOR } from '../theme';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === 'dark'
        ? theme.palette.grey[800]
        : theme.palette.grey[300],
    borderTopWidth: 1,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.grey[700]
        : theme.palette.grey[300],
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
      color: theme.palette.primary.main,
    }),
    '& .QontoStepIcon-completedIcon': {
      color: theme.palette.primary.main,
      zIndex: 1,
      fontSize: 24,
    },
    '& .QontoStepIcon-circle': {
      width: 12,
      height: 12,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
  }),
);

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <CheckCircleOutline className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

function MobileQontoStepIcon(props: StepIconProps) {
  const { active, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      <div className="QontoStepIcon-circle" />
    </QontoStepIconRoot>
  );
}

export const SCROLL_TO_SWITCH_BUTTON = 250;

interface MultiStepsFormProps {
  steps: React.ReactNode[];
  activeStep?: number;
  onClickFinish?: () => void;
  finishButtonText?: string;
  onClickNext?: () => void;
  onClickBack?: () => void;
}

function MultiStepsFormBase({
  steps,
  activeStep = 0,
  onClickFinish,
  finishButtonText,
  onClickNext,
  onClickBack,
}: MultiStepsFormProps) {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const [showUpButton, setShowUpButton] = useState<boolean>(false);

  const { height } = useWindowDimensions();

  const handleScroll = useCallback(() => {
    if (window.scrollY > SCROLL_TO_SWITCH_BUTTON && !showUpButton) {
      setShowUpButton(true);
    } else if (window.scrollY < SCROLL_TO_SWITCH_BUTTON && showUpButton) {
      setShowUpButton(false);
    }
  }, [showUpButton, setShowUpButton]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const handleBack = useCallback(() => {
    if (activeStep > 0 && onClickBack) {
      onClickBack();
    }
  }, [activeStep, onClickBack]);

  const handleNext = useCallback(() => {
    if (activeStep < steps.length - 1 && onClickNext) {
      onClickNext();
    }
  }, [activeStep, steps.length, onClickNext]);

  const onClickUp = useCallback(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  const minHeight = useMemo(() => {
    if (height < 500) {
      return 350;
    }
    if (height < 700) {
      return height - 275;
    }

    return 500;
  }, [height]);

  return (
    <Box>
      {!mobileView ? (
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<QontoConnector />}
        >
          {steps.map((item, index) => (
            <Step key={index}>
              <StepLabel StepIconComponent={QontoStepIcon}></StepLabel>
            </Step>
          ))}
        </Stepper>
      ) : (
        <Stepper alternativeLabel activeStep={activeStep}>
          {steps.map((item, index) => (
            <Step key={index}>
              <StepLabel StepIconComponent={MobileQontoStepIcon}></StepLabel>
            </Step>
          ))}
        </Stepper>
      )}
      <Box
        sx={(theme) => ({
          px: 2,
          [theme.breakpoints.down('sm')]: {
            position: 'fixed',
            bottom: theme.spacing(2),
            width: `calc(100% - ${theme.spacing(4)})`,
          },
          [theme.breakpoints.up('sm')]: {
            pt: 3,
          },
        })}
      >
        <Stack direction="row" justifyContent={'space-between'} spacing={1}>
          <Fade in={activeStep > 0}>
            <Box>
              <RoundBackButtonComponent onClick={handleBack} />
            </Box>
          </Fade>
          {activeStep < steps.length - 1 ? (
            <RoundForwardButtonComponent onClick={handleNext} />
          ) : (
            <Button
              variant="contained"
              sx={{
                borderRadius: '50px',
                minWidth: '238px',
              }}
              disableElevation
              onClick={onClickFinish}
            >
              {finishButtonText}
            </Button>
          )}
        </Stack>
      </Box>
      <Box
        sx={(theme) => ({
          p: 3,
          [theme.breakpoints.up('sm')]: {
            minHeight: `${minHeight}px`,
          },
        })}
      >
        {steps[activeStep]}
      </Box>
      {showUpButton && !mobileView && (
        <Button
          onClick={onClickUp}
          variant="contained"
          color={'secondary'}
          sx={(theme) => ({
            position: 'fixed',
            zIndex: theme.zIndex.drawer + 2,
            bgcolor: `rgba(69, 94, 100, 0.7)`,
            top: theme.spacing(12),
            margin: 'auto',
            marginTop: 0,
            height: '48px',
            width: '48px',
            right: '0%',
            left: '0%',
            boxShadow:
              '0px 4px 5px rgba(0, 0, 0, 0.07), 0px 1px 10px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.1)',
            borderRadius: '50px',
            '&:hover': {
              bgcolor: DEEP_SPACE_SPARKLE_COLOR,
            },
          })}
        >
          <ArrowCircleUpIcon fontSize="large" />
        </Button>
      )}
    </Box>
  );
}

export const MultiStepsForm = memo(MultiStepsFormBase);
