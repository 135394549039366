import MUIButton, { ButtonProps } from '@mui/material/Button';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useLoginButton } from '../../hooks/use-login-button';
function SignInButton(props: Omit<ButtonProps, 'onClick'>) {
  const { t } = useTranslation();

  const { onClick } = useLoginButton();

  return (
    <MUIButton
      variant="contained"
      {...props}
      sx={{
        borderRadius: '68.953px',
        background: 'linear-gradient(90deg, #EA5F3B 0%, #923D66 100%)',
        boxShadow:
          '0px 1.94813px 3.89625px 0px rgba(0, 0, 0, 0.20), 0px 0.97406px 9.74063px 0px rgba(0, 0, 0, 0.12), 0px 3.89625px 4.87031px 0px rgba(0, 0, 0, 0.14)',
        textAlign: 'center',
        fontSize: '19.307px',
        fontWeight: 500,
        lineHeight: '15.585px',
        letterSpacing: '1.218px',
        maxWidth: '350px',
        width: '100%',
        py: 2.25,
        ...props.sx,
      }}
      onClick={onClick}
    >
      {t('sign-in.button')}
    </MUIButton>
  );
}

export const SignInButtonComponent = memo(SignInButton);
