import MUIAlert, { AlertProps } from '@mui/material/Alert';
import React, { memo } from 'react';

function Alert(props: AlertProps) {
  return (
    <MUIAlert
      sx={(theme) => ({
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
          flexWrap: 'wrap',
          justifyContent: 'center',
        },
      })}
      {...props}
    />
  );
}

export const AlertComponent = memo(Alert);
