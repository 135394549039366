import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Formik, FormikProps } from 'formik';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { SaveAndCancelButtonsComponent } from '../components/buttons/save-and-cancel-buttons.component';
import { Patient } from '../interfaces/entities/patient.interface';
import { DEFAULT_GUESTBOOK_ALBUM_NAME } from '../utils/constants';
import { getAlbumTitleTranslationKey } from '../utils/get-album-title-translation-key';
import { AssistiveTextComponent } from './shared/assistive-text.component';
import { FormWrapperComponent } from './shared/form-wrapper.component';
import { NameFieldComponent } from './shared/name-field.component';

interface GuestbookAlbumFormProps extends Pick<Patient, 'guestbookAlbumName'> {
  onSave?: (data: Partial<Pick<Patient, 'guestbookAlbumName'>>) => void;
  onCancel?: () => void;
  resetFormAfterCancel?: boolean;
}

function GuestbookAlbumFormBase({
  resetFormAfterCancel = true,
  ...props
}: GuestbookAlbumFormProps) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const translatedDefaultName = useMemo(() => {
    return t(getAlbumTitleTranslationKey(DEFAULT_GUESTBOOK_ALBUM_NAME));
  }, [t]);

  const onSave = useCallback(
    (data: Partial<Patient>) => {
      const { onSave } = props;

      if (!onSave) {
        return;
      }
      setIsLoading(true);
      onSave(data);
      setIsLoading(false);
    },
    [props, setIsLoading],
  );

  const onCancel = useCallback(
    (resetForm: () => void) => {
      if (props.onCancel) {
        props.onCancel();
      }
      if (resetFormAfterCancel) {
        resetForm();
      }
    },
    [props.onCancel, resetFormAfterCancel],
  );

  return (
    <FormWrapperComponent isLoading={isLoading}>
      <Formik<Partial<Patient>>
        initialValues={props || {}}
        onSubmit={onSave}
        validationSchema={Yup.object().shape({
          guestbookAlbumName: Yup.string().nullable(true).optional(),
        })}
      >
        {(props: FormikProps<Partial<Patient>>) => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleSubmit,
            resetForm,
          } = props;

          return (
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              spacing={3}
            >
              <Grid item xs={12} lg={9} xl={7}>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <NameFieldComponent
                        id={'guestbookAlbumName'}
                        label={t('album-edit-name.label')}
                        value={values.guestbookAlbumName || ''}
                        handleChange={handleChange}
                        error={errors.guestbookAlbumName}
                        touched={touched.guestbookAlbumName}
                      />
                      <AssistiveTextComponent>
                        {t('guestbook-album-edit-name.assistive', {
                          name: translatedDefaultName,
                        })}
                      </AssistiveTextComponent>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={12}>
                    <SaveAndCancelButtonsComponent
                      onClickCancel={() => onCancel(resetForm)}
                      onClickSave={handleSubmit}
                      sx={(theme) => ({
                        float: 'right',
                        width: '100%',

                        [theme.breakpoints.up('md')]: {
                          width: '45%',
                        },
                        [theme.breakpoints.up('lg')]: {
                          width: '35%',
                        },
                      })}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          );
        }}
      </Formik>
    </FormWrapperComponent>
  );
}

export const GuestbookAlbumForm = memo(GuestbookAlbumFormBase);
