import Input, { InputProps } from '@mui/material/Input';
import React, { memo, useCallback, useState } from 'react';

import { convertFileListWithHeic } from '../utils/heic/convert-file-list-with-heic';
import { fileListIncludesHeic } from '../utils/heic/file-list-includes-heic';
import { BackdropLoadingComponent } from './loading/backdrop-loading.component';

function InputFile(props: Omit<InputProps, 'type'>) {
  const [isLoading, setsIsLoading] = useState(false);

  const onChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (props.onChange) {
        if (
          !event.target.files?.length ||
          !fileListIncludesHeic(event.target.files)
        ) {
          return props.onChange(event);
        }

        setsIsLoading(true);
        props.onChange({
          ...event,
          target: {
            ...event.target,
            files: await convertFileListWithHeic(event.target.files),
          },
        });
        setsIsLoading(false);
      }
    },
    [props.onChange],
  );

  return (
    <>
      <Input
        {...props}
        type="file"
        onChange={onChange}
        sx={{
          ...props.sx,
          display: 'none',
        }}
      />
      <BackdropLoadingComponent open={isLoading} />
    </>
  );
}

export const InputFileComponent = memo(InputFile);
