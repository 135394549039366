import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import React, { memo, ReactElement } from 'react';

import { EMPTY_IMAGE } from '../../../utils/constants';

function AlbumCardLoading(): ReactElement {
  return (
    <Card
      sx={{
        boxShadow:
          '0px 1px 1px rgba(0, 0, 0, 0.07), 0px 2px 1px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1)',
        borderTopRightRadius: '0px',
        borderTopLeftRadius: '0px',
        borderBottomRightRadius: '4px',
        borderBottomLeftRadius: '4px',
      }}
    >
      <CardActionArea>
        <Skeleton
          variant={'rectangular'}
          sx={{
            maxWidth: '100%',
          }}
        >
          <CardMedia
            image={EMPTY_IMAGE}
            sx={{
              paddingTop: '65%',
            }}
          />
        </Skeleton>
        <CardContent>
          <Typography
            gutterBottom
            variant="subtitle1"
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              color: 'rgba(0, 0, 0, 0.87)',
            }}
          >
            <Skeleton />
          </Typography>
          <Typography variant="body2">
            <Skeleton width={'80px'} />
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export const AlbumCardLoadingComponent = memo(AlbumCardLoading);
