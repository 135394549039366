import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { Field, FieldProps, Form, Formik, FormikProps } from 'formik';
import React, { forwardRef, memo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { Patient } from '../../interfaces/entities/patient.interface';
import { SingleLineField } from './shared/name-field.component';

type PatientName = Pick<Patient, 'firstName' | 'lastName'>;

interface PatientNameFormProps extends PatientName {
  onChange: (data: PatientName) => void;
}

function PatientNameFormBase(
  { onChange, ...props }: PatientNameFormProps,
  ref: React.Ref<FormikProps<Patient>>,
) {
  const { t } = useTranslation();

  return (
    <Formik<PatientName>
      initialValues={props || {}}
      onSubmit={onChange}
      innerRef={ref as React.Ref<FormikProps<PatientName>>}
      validationSchema={Yup.object<
        Record<keyof PatientName, Yup.AnySchema>
      >().shape({
        firstName: Yup.string().required('required-field-not-filled.error'),
        lastName: Yup.string().required('required-field-not-filled.error'),
      })}
    >
      {() => (
        <Form>
          <Stack spacing={2}>
            <Box>
              <Field name="firstName">
                {(props: FieldProps) => {
                  return (
                    <SingleLineField
                      {...props}
                      label={t('patient-edit-first-name.label')}
                      assistiveText={t('patient-edit-first-name.assistive')}
                      assistiveTextPosition="top"
                    />
                  );
                }}
              </Field>
            </Box>
            <Box>
              <Field name="lastName">
                {(props: FieldProps) => {
                  return (
                    <SingleLineField
                      {...props}
                      label={t('patient-edit-last-name.label')}
                      assistiveText={t('patient-edit-last-name.assistive')}
                      assistiveTextPosition="top"
                    />
                  );
                }}
              </Field>
            </Box>
          </Stack>
        </Form>
      )}
    </Formik>
  );
}

export const PatientNameForm = memo(forwardRef(PatientNameFormBase));
