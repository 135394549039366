import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { BLACK_COLOR } from '../../theme';

const IMAGE_ZOOM_DESKTOP = 1.2;
const IMAGE_WIDTH = 326;
const IMAGE_HEIGHT = 248;

interface DraftPatientExistsProps {
  onContinue?: () => void;
  onReset?: () => void;
}
function DraftPatientExists({ onContinue, onReset }: DraftPatientExistsProps) {
  const { t } = useTranslation();

  return (
    <Stack alignItems="center" spacing={{ xs: 3, md: 4 }}>
      <Box>
        <Typography variant="h4" fontSize={'28px'} fontWeight={700}>
          {t('draft-profile-exists.title')}
        </Typography>
        <Typography variant="subtitle1">
          {t('draft-profile-exists.subtitle')}
        </Typography>
      </Box>

      <Box
        sx={(theme) => ({
          backgroundImage: `url('/assets/draft-patient-exists.svg')`,
          backgroundSize: 'cover',
          width: IMAGE_WIDTH,
          height: IMAGE_HEIGHT,
          [theme.breakpoints.up('md')]: {
            width: IMAGE_WIDTH * IMAGE_ZOOM_DESKTOP,
            height: IMAGE_HEIGHT * IMAGE_ZOOM_DESKTOP,
          },
        })}
      />
      <Box
        sx={(theme) => ({
          width: IMAGE_WIDTH,
          [theme.breakpoints.up('md')]: {
            width: IMAGE_WIDTH * IMAGE_ZOOM_DESKTOP,
          },
        })}
      >
        <Button
          fullWidth
          variant="contained"
          sx={{
            height: '50px',
            textTransform: 'none',
            borderRadius: '68.953px',
            boxShadow:
              '0px 1.9481251239776611px 3.8962502479553223px 0px rgba(0, 0, 0, 0.20), 0px 0.9740625619888306px 9.740625381469727px 0px rgba(0, 0, 0, 0.12), 0px 3.8962502479553223px 4.870312690734863px 0px rgba(0, 0, 0, 0.14)',
            fontSize: '19.307px',
            fontWeight: 500,
            lineHeight: '15.585px',
            letterSpacing: '1.218px',
          }}
          disableElevation
          onClick={onContinue}
        >
          {t('draft-profile-continue.button')}
        </Button>

        <Button
          fullWidth
          variant="contained"
          sx={{
            height: '50px',
            mt: 2.25,
            textTransform: 'none',
            color: BLACK_COLOR,
            backgroundColor: '#F2F2F2',
            borderRadius: '68.953px',
            boxShadow:
              '0px 1.9481251239776611px 3.8962502479553223px 0px rgba(0, 0, 0, 0.20), 0px 0.9740625619888306px 9.740625381469727px 0px rgba(0, 0, 0, 0.12), 0px 3.8962502479553223px 4.870312690734863px 0px rgba(0, 0, 0, 0.14)',
            fontSize: '19.307px',
            fontWeight: 500,
            lineHeight: '15.585px',
            letterSpacing: '1.218px',
            '&:hover': {
              backgroundColor: '#F2F2F2',
            },
          }}
          disableElevation
          onClick={onReset}
        >
          {t('draft-profile-new.button')}
        </Button>
      </Box>
    </Stack>
  );
}

export const DraftPatientExistsComponent = memo(DraftPatientExists);
