import { AxiosInstance } from 'axios';

import { Album } from '../interfaces/entities/album.interface';
import { PatientBaseStore } from './shared/patient-base.store';

export class AlbumsStore extends PatientBaseStore<Album> {
  constructor(api: AxiosInstance) {
    super(api, 'albums');
  }
}
