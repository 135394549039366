import { FormHelperText } from '@mui/material';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { FieldProps } from '../interfaces/field-props.interface';

interface FieldWrapperProps extends FieldProps {
  children: React.ReactNode;
}

function FieldWrapper({ children, name, error }: FieldWrapperProps) {
  const { t } = useTranslation();

  const errorId = name ? `${name}-input` : 'error-id';
  return (
    <>
      {children}
      {!!error && (
        <FormHelperText error id={errorId}>
          {t(error as string, error)}
        </FormHelperText>
      )}
    </>
  );
}

export const FieldWrapperComponent = memo(FieldWrapper);
