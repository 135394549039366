export function getDefaultImageTransformations(
  height?: number,
  width?: number,
  face?: boolean,
): string[] {
  const arr = [];

  if (height) {
    arr.push(`h_${height}`);
  }

  if (width) {
    arr.push(`w_${width}`);
  }

  if (face) {
    arr.push('g_face');
  }
  return [...arr, 'g_auto', 'c_fill', 'f_auto'];
}
