import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { observer } from 'mobx-react-lite';
import React, { Ref, useCallback, useImperativeHandle, useState } from 'react';
import Lightbox from 'react-image-lightbox';

import { AlbumFilesEditorComponent } from '../../components/albums/album-files-editor.component';
import { useAlbumFilesEditor } from '../../hooks/albums/use-album-files-editor';
import { useStores } from '../../hooks/use-stores';
import { Album } from '../../interfaces/entities/album.interface';
import { AlbumFile } from '../../interfaces/entities/album-file.interface';
import { ProfilePageProps } from '../../interfaces/profile-page-props.interface';
import { EMPTY_IMAGE } from '../../utils/constants';

interface AlbumFilesListNewProps extends ProfilePageProps {
  albumImage?: Album['image'];
  onChangeAlbumCover: (value: string) => void;
}

export type AlbumFilesListNewRef = {
  save: (
    album: Album,
    updateAlbumCover: (value: string) => Promise<Album | null>,
  ) => Promise<void>;
};

const IMAGE_PADDING = 50;

function AlbumFilesListNew(
  { profileId, onChangeAlbumCover, albumImage }: AlbumFilesListNewProps,
  ref: Ref<AlbumFilesListNewRef>,
) {
  const { albumFilesStore } = useStores();

  const [indexToShow, setIndexToShow] = useState<number | null>(null);

  const {
    files,
    selected,
    rotated,
    onChange: onChangeFiles,
    onClickSelect,
    onClickRotate,
    onDeleteSelected,
    getSelectedFileUrl,
    getFilesToCreate,
    onSelectFiles,
  } = useAlbumFilesEditor(onChangeAlbumCover, albumImage, []);

  useImperativeHandle(
    ref,
    () => ({
      async save(
        album: Album,
        updateAlbumCover: (value: string) => Promise<Album | null>,
      ) {
        const filesToCreate = getFilesToCreate();
        const createdFiles = await albumFilesStore.createManyByAlbumId(
          profileId,
          album.id,
          filesToCreate,
        );

        const index = filesToCreate.findIndex(
          ({ url }) => url && albumImage === url,
        );

        if (index !== -1 && createdFiles?.[index]?.url) {
          await updateAlbumCover(createdFiles[index].url);
        }
      },
    }),
    [files, albumImage, rotated],
  );

  const { lightbox } = useStyles();

  const onClickFile = useCallback(
    (id: AlbumFile['id']) => {
      const index = files.findIndex((file) => file.id === id);
      if (index !== -1) {
        setIndexToShow(index);
      }
    },
    [files, setIndexToShow],
  );

  const onMakeAlbumCover = useCallback(() => {
    const url = getSelectedFileUrl();
    if (url) {
      onChangeAlbumCover(url);
    }
  }, [onChangeAlbumCover, getSelectedFileUrl]);

  return (
    <>
      {indexToShow !== null && (
        <Lightbox
          reactModalStyle={{ overlay: { zIndex: 9999 } }}
          wrapperClassName={lightbox}
          imagePadding={IMAGE_PADDING}
          mainSrc={files[indexToShow].url || EMPTY_IMAGE}
          imageTitle={
            <Typography
              variant={'subtitle1'}
              color={'#FFFFFF'}
              position={'absolute'}
              left={'50%'}
            >
              {`${indexToShow + 1}/${files.length}`}
            </Typography>
          }
          imageCaption={
            <Typography variant={'body1'} color={'#FFFFFF'}>
              {files[indexToShow].description}
            </Typography>
          }
          onCloseRequest={() => {
            setIndexToShow(null);
          }}
        />
      )}
      <AlbumFilesEditorComponent
        files={files}
        selected={selected}
        rotated={rotated}
        albumImage={albumImage}
        onChange={onChangeFiles}
        onClickSelect={onClickSelect}
        onClickRotate={onClickRotate}
        onClick={onClickFile}
        onDeleteSelected={onDeleteSelected}
        onMakeAlbumCover={onMakeAlbumCover}
        onSelectFiles={onSelectFiles}
      />
    </>
  );
}

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    lightbox: {
      '& .ril-caption': {
        bottom: Math.floor(IMAGE_PADDING / 2) - 10,
        backgroundColor: 'rgba(0, 0, 0, 0)',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
  }),
);

export const AlbumFilesListNewContainer = observer(AlbumFilesListNew, {
  forwardRef: true,
});
