import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BackButtonComponent } from '../../components/buttons/back-button.component';
import { SaveAndCancelButtonsComponent } from '../../components/buttons/save-and-cancel-buttons.component';
import { ConfirmLeaveDialogComponent } from '../../components/dialog/confirm-leave-dialog.component';
import { BackdropLoadingComponent } from '../../components/loading/backdrop-loading.component';
import { PROFILE_ROUTE_ALBUMS } from '../../configs/profile-routes';
import { MY_PATIENTS_ROUTE } from '../../configs/routes';
import { AlbumForm } from '../../forms/album.form';
import { usePatientRole } from '../../hooks/patients/use-patient-role';
import { useFormActions } from '../../hooks/use-form-actions';
import { useStores } from '../../hooks/use-stores';
import { Album } from '../../interfaces/entities/album.interface';
import { ProfilePageProps } from '../../interfaces/profile-page-props.interface';
import {
  AlbumFilesListNewContainer,
  AlbumFilesListNewRef,
} from '../album-files/album-files-list-new.container';

function AlbumsNew({ profileId }: ProfilePageProps) {
  const { isPatientRoleAdmin } = usePatientRole(profileId);
  const { albumsStore } = useStores();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [blockLeave, setBlockLeave] = useState(true);

  const [item, setItem] = useState<Partial<Album>>({});

  const ref = useRef<AlbumFilesListNewRef>(null);

  const backPath = `${MY_PATIENTS_ROUTE.path}/${profileId}/${PROFILE_ROUTE_ALBUMS.path}`;

  const { onCancel, onConfirm } = useFormActions(backPath);

  const onSave = useCallback(async () => {
    setLoading(true);
    const created = await albumsStore.createByPatientId(profileId, item);

    if (created?.id) {
      await ref.current?.save(created, (image: string) =>
        albumsStore.updateByPatientId(profileId, created.id, {
          ...created,
          image,
        }),
      );
    }
    setLoading(false);
    setBlockLeave(false);
    onConfirm();
    setBlockLeave(true);
  }, [
    onConfirm,
    profileId,
    albumsStore.createByPatientId,
    albumsStore.updateByPatientId,
    item,
    setLoading,
  ]);

  const onChangeAlbumCover = useCallback(
    (image: string) => {
      setItem({ ...item, image } as Album);
    },
    [setItem, item],
  );

  if (!isPatientRoleAdmin) {
    return <></>;
  }

  return (
    <>
      <Stack direction="column" spacing={2}>
        <Box>
          <BackButtonComponent path={backPath} />
        </Box>
        <Box>
          <AlbumForm {...item} onChange={setItem} />
        </Box>
        <Box>
          <AlbumFilesListNewContainer
            ref={ref}
            profileId={profileId}
            albumImage={item.image}
            onChangeAlbumCover={onChangeAlbumCover}
          />
        </Box>
        <Box>
          <SaveAndCancelButtonsComponent
            onClickCancel={onCancel}
            onClickSave={onSave}
            disabledSave={!item.name}
            sx={(theme) => ({
              pt: 1,
              float: 'right',
              width: '100%',

              [theme.breakpoints.up('md')]: {
                width: '45%',
              },
              [theme.breakpoints.up('lg')]: {
                width: '35%',
              },
            })}
          />
        </Box>
      </Stack>

      <BackdropLoadingComponent open={loading} />

      <ConfirmLeaveDialogComponent
        blockLeave={blockLeave}
        description={t('modal-leave-album-warning.body')}
        stayButtonText={t('modal-leave-album-warning-stay.button')}
        leaveButtonText={t('modal-leave-album-warning-leave.button')}
      />
    </>
  );
}

export const AlbumsNewContainer = observer(AlbumsNew);
