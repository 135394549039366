import { AxiosInstance } from 'axios';
import uniqBy from 'lodash/uniqBy';
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';

import {
  Invitation,
  INVITATION_STATUSES,
} from '../interfaces/entities/invitation.interface';
import { Patient } from '../interfaces/entities/patient.interface';
import { PatientsStore } from './patients.store';
import { PatientBaseStore } from './shared/patient-base.store';

export class InvitationsStore extends PatientBaseStore<Invitation> {
  newIds: ReadonlyArray<Invitation['id']> = [];
  codeError: string | null = null;
  loadingCode?: boolean;
  deletingByPatientId: Record<Patient['id'], ReadonlyArray<Invitation['id']>> =
    {};
  resendingByPatientId: Record<Patient['id'], ReadonlyArray<Invitation['id']>> =
    {};
  constructor(
    api: AxiosInstance,
    private readonly patientsStore: PatientsStore,
  ) {
    super(api, 'invitations');
    makeObservable(this, {
      addByCode: action,
      clearError: action,
      codeError: observable,
      loadingCode: observable,
      newIds: observable,
      deletingByPatientId: observable,
      resendingByPatientId: observable,
      acceptedAndDeclined: computed,
      pending: computed,
    });
  }

  get acceptedAndDeclined(): ReadonlyArray<Invitation> {
    return uniqBy(
      this.items.filter(({ status }) => status !== INVITATION_STATUSES.PENDING),
      'id',
    );
  }

  get pending(): ReadonlyArray<Invitation> {
    return uniqBy(
      [
        ...this.items.filter(
          ({ status }) => status === INVITATION_STATUSES.PENDING,
        ),
      ],
      'id',
    );
  }

  async addByCode(code: number): Promise<Invitation | undefined> {
    let data: Invitation;
    runInAction(() => {
      this.loadingCode = true;
    });
    try {
      const response = await this.api.post<Invitation>(
        `${this.getUrl()}/code`,
        {
          code,
        },
      );
      data = response.data;
    } catch (err) {
      runInAction(() => {
        this.codeError = 'Invalid code';
        this.loadingCode = false;
      });

      return;
    }

    if (this.items.some(({ id }) => id === data.id)) {
      return;
    }

    runInAction(() => {
      this.newIds = [...this.newIds, data.id];
      this.items = [
        { ...data, status: INVITATION_STATUSES.PENDING },
        ...this.items,
      ];
      this.loadingCode = false;
    });

    return data;
  }

  async getManyShortByIds(
    ids: ReadonlyArray<Invitation['id']>,
  ): Promise<ReadonlyArray<Invitation>> {
    const idsToGet = Array.from(new Set(ids)).filter(
      (id) => ![...this.items].some((invitation) => invitation.id === id),
    );

    if (!idsToGet.length) {
      return [];
    }

    const invitations = (
      await Promise.all(
        idsToGet.map(async (id) => {
          try {
            const { data } = await this.api.get<Invitation>(
              `${this.getUrl()}/${id}/short`,
            );
            return data;
          } catch (err) {
            return;
          }
        }),
      )
    ).filter(Boolean) as ReadonlyArray<Invitation>;

    runInAction(() => {
      this.items = [
        ...invitations.map((data) => ({
          ...data,
          status: INVITATION_STATUSES.PENDING,
        })),
        ...this.items,
      ];
    });

    return invitations;
  }

  async accept(id: Invitation['id']): Promise<Invitation | undefined> {
    const found = this.acceptedAndDeclined.some(
      (invitation) => invitation.id === id,
    );

    if (found) {
      return;
    }

    const { data } = await this.api.post<Invitation>(
      `${this.getUrl()}/${id}/accept`,
    );

    runInAction(() => {
      this.newIds = this.newIds.filter((newId) => newId !== id);

      this.items = this.items.map((item) => {
        if (item.id === id) {
          return data;
        }
        return item;
      });
      this.patientsStore.items = [...this.patientsStore.items, data.patient];
    });

    return data;
  }

  async decline(id: Invitation['id']): Promise<Invitation | undefined> {
    const found = this.acceptedAndDeclined.some(
      (invitation) => invitation.id === id,
    );

    if (found) {
      return;
    }

    const { data } = await this.api.post<Invitation>(
      `${this.getUrl()}/${id}/decline`,
    );

    runInAction(() => {
      this.newIds = this.newIds.filter((newId) => newId !== id);

      this.items = this.items.map((item) => {
        if (item.id === id) {
          return data;
        }
        return item;
      });
      this.patientsStore.items = [...this.patientsStore.items, data.patient];
    });

    return data;
  }

  clearError() {
    runInAction(() => {
      this.codeError = null;
    });
  }

  async deleteByPatientId(
    patientId: Patient['id'],
    id: Invitation['id'],
  ): Promise<void> {
    runInAction(() => {
      this.deletingByPatientId[patientId] = [
        ...(this.deletingByPatientId[patientId] || []),
        id,
      ];
    });

    await super.deleteByPatientId(patientId, id);

    runInAction(() => {
      this.deletingByPatientId[patientId] = (
        this.deletingByPatientId[patientId] || []
      ).filter((deletingId) => deletingId !== id);
    });
  }

  async resendByPatientId(
    patientId: Patient['id'],
    id: Invitation['id'],
  ): Promise<void> {
    runInAction(() => {
      this.resendingByPatientId[patientId] = [
        ...(this.resendingByPatientId[patientId] || []),
        id,
      ];
    });

    try {
      await this.api.post<Invitation>(
        `${this.getUrlByPatientId(patientId)}/${id}/resend`,
      );
    } catch (err) {
      console.error(err);
    }

    runInAction(() => {
      this.resendingByPatientId[patientId] = (
        this.resendingByPatientId[patientId] || []
      ).filter((resendingId) => resendingId !== id);
    });
  }

  isDeletingByPatientId(patientId: Patient['id'], id: Invitation['id']) {
    return (this.deletingByPatientId[patientId] || []).includes(id);
  }

  isResendingByPatientId(patientId: Patient['id'], id: Invitation['id']) {
    return (this.resendingByPatientId[patientId] || []).includes(id);
  }
}
