import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
// import TextField from '@mui/material/TextField';
import { Field, FieldProps, Form, Formik, FormikProps } from 'formik';
import React, { forwardRef, memo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { Patient } from '../../interfaces/entities/patient.interface';
import { Unit } from '../../interfaces/entities/unit.interface';
import { FieldWrapperComponent } from './shared/field-wrapper.component';

interface PatientDepartment {
  departmentId: Unit['id'] | null;
}

interface PatientDepartmentFormProps extends PatientDepartment {
  onChange: (data: PatientDepartment) => void;
  items: ReadonlyArray<Unit>;
}

function PatientDepartmentFormBase(
  { onChange, items, ...props }: PatientDepartmentFormProps,
  ref: React.Ref<FormikProps<Patient>>,
) {
  const { t } = useTranslation();

  return (
    <Formik<PatientDepartment>
      initialValues={props}
      onSubmit={onChange}
      innerRef={ref as React.Ref<FormikProps<PatientDepartment>>}
      validationSchema={Yup.object<
        Record<keyof PatientDepartment, Yup.AnySchema>
      >().shape({
        departmentId: Yup.string()
          .nullable()
          .required('required-field-not-filled.error'),
      })}
    >
      {() => (
        <Form>
          <Field name="departmentId">
            {(props: FieldProps) => {
              return (
                <Autocomplete
                  autoHighlight
                  getOptionLabel={(option) => option.name}
                  options={items}
                  renderInput={(params) => (
                    <FieldWrapperComponent
                      {...props}
                      meta={{
                        ...props.meta,
                        touched: !!props.meta.error,
                      }}
                    >
                      <TextField
                        error={!!props.meta.error}
                        {...params}
                        label={t('profile-department-form.select')}
                      />
                    </FieldWrapperComponent>
                  )}
                  {...props.field}
                  onChange={(event, newValue) => {
                    return props.form.setFieldValue(
                      props.field.name,
                      (newValue as Unit | null)?.id,
                    );
                  }}
                  value={items.find(({ id }) => props.field.value === id)}
                />
              );
            }}
          </Field>
        </Form>
      )}
    </Formik>
  );
}

export const PatientDepartmentForm = memo(
  forwardRef(PatientDepartmentFormBase),
);
