import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

const FOOTER_TEXT_BODY = 'vilmer-footer-copyright.body';
const FOOTER_TEXT_ORGANIZATION = 'vilmer-footer-organization.body';
const FOOTER_TEXT_LINK = 'vilmer-footer-terms-link.body';
const FOOTER_TEXT_LINK_BODY = 'vilmer-landing-page-link.body';

import { VILKAR_URL, VILMER_LOGO_PATH, VILMER_URL } from '../utils/constants';

function Footer() {
  const { t } = useTranslation();

  return (
    <footer>
      <Box
        sx={(theme) => ({
          background: theme.palette.background.paper,
          paddingTop: '17px',
          paddingBottom: '12px',
          textAlign: 'center',
          position: 'relative',
          zIndex: theme.zIndex.drawer + 1,
        })}
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <img
              src={VILMER_LOGO_PATH}
              alt="VILMER Logo"
              style={{
                height: '24px',
              }}
            />
          </Grid>
          <Grid item>
            <Typography
              variant="caption"
              sx={{
                color: 'rgba(0, 0, 0, 0.6);',
              }}
            >
              {t(FOOTER_TEXT_BODY)}
              <span> | {t(FOOTER_TEXT_ORGANIZATION)} |</span>
              <Link
                target="_blank"
                underline="none"
                href={VILKAR_URL}
                ml={1}
                color="inherit"
              >
                {t(FOOTER_TEXT_LINK)}
              </Link>
              <Link
                target="_blank"
                underline="none"
                href={VILMER_URL}
                color="inherit"
              >
                {' '}
                | {t(FOOTER_TEXT_LINK_BODY)}
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </footer>
  );
}

export const FooterComponent = memo(Footer);
