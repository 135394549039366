import SlideshowIcon from '@mui/icons-material/Slideshow';
import Button, { ButtonProps } from '@mui/material/Button';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { DEEP_SPACE_SPARKLE_COLOR } from '../../theme';

export const SLIDESHOW_BUTTON_STYLE = {
  bgcolor: DEEP_SPACE_SPARKLE_COLOR,
  boxShadow:
    '0px 4px 5px rgba(0, 0, 0, 0.07), 0px 1px 10px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.1)',
  borderRadius: '50px',
  '&:hover': {
    bgcolor: DEEP_SPACE_SPARKLE_COLOR,
  },
};

function SlideshowButton(props: ButtonProps) {
  const { t } = useTranslation();

  return (
    <Button
      variant="contained"
      color={'secondary'}
      startIcon={<SlideshowIcon />}
      style={SLIDESHOW_BUTTON_STYLE}
      {...props}
    >
      {t('slideshow.button')}
    </Button>
  );
}

export const SlideshowButtonComponent = memo(SlideshowButton);
