import ChatIcon from '@mui/icons-material/Chat';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BackButtonComponent } from '../../components/buttons/back-button.component';
import { ConfirmLeaveDialogComponent } from '../../components/dialog/confirm-leave-dialog.component';
import { BackdropLoadingComponent } from '../../components/loading/backdrop-loading.component';
import { PhotoEditorComponent } from '../../components/photo-editor.component';
import { PROFILE_ROUTE_ALBUMS } from '../../configs/profile-routes';
import { MY_PATIENTS_ROUTE } from '../../configs/routes';
import { GuestbookAlbumForm } from '../../forms/guestbook-album.form';
import { usePatient } from '../../hooks/patients/use-patient';
import { usePatientRole } from '../../hooks/patients/use-patient-role';
import { useFormActions } from '../../hooks/use-form-actions';
import { usePhotoEditor } from '../../hooks/use-photo-editor';
import { Patient } from '../../interfaces/entities/patient.interface';
import { ProfilePageProps } from '../../interfaces/profile-page-props.interface';
import { GUESTBOOK_ALBUM_ID } from '../../utils/constants';

function GuestbookAlbumEditContainerBase({ profileId }: ProfilePageProps) {
  const { t } = useTranslation();
  const [blockLeave, setBlockLeave] = useState(true);

  const { item, fetchLoading, update, updateLoading, updateWithPhoto } =
    usePatient(profileId);
  const { isPatientRoleAdmin } = usePatientRole(profileId);

  const {
    props: photoEditorProps,
    isChanged: photoIsChanged,
    getPhoto,
  } = usePhotoEditor(item?.guestbookAlbumImage);

  const backPath = `${MY_PATIENTS_ROUTE.path}/${profileId}/${PROFILE_ROUTE_ALBUMS.path}/${GUESTBOOK_ALBUM_ID}`;

  const { onCancel, onConfirm } = useFormActions(backPath);

  const onSave = useCallback(
    async (data: Partial<Patient>) => {
      if (!photoIsChanged) {
        await update(data);
      } else {
        const image = await getPhoto();
        if (!image) {
          await update({ ...data, guestbookAlbumImage: null });
        } else {
          await updateWithPhoto(data, image as File, 'guestbookAlbumImage');
        }
      }
      setBlockLeave(false);
      onConfirm();
      setBlockLeave(true);
    },
    [update, updateWithPhoto, photoIsChanged, getPhoto, onConfirm],
  );

  if (fetchLoading) {
    return <></>;
  }

  if (!item || !isPatientRoleAdmin) {
    return <></>;
  }

  return (
    <>
      <Grid container direction="row" spacing={1}>
        <Grid item xs={12}>
          <BackButtonComponent path={backPath} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant={'h5'}>
            {t('album-edit-indicate-album-cover.label')}
          </Typography>
        </Grid>
        <Grid item xs={12} xl={10}>
          <PhotoEditorComponent
            {...photoEditorProps}
            uploadButtonText={t('guestbook-album-upload-button')}
            placeHolder={
              <CardMedia
                sx={{
                  borderRadius: 1,
                  paddingTop: '80%',
                  position: 'relative',
                  bgcolor: 'primary.light',
                  color: 'primary.main',
                  '& svg': {
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    fontSize: '130px',
                  },
                }}
              >
                <ChatIcon />
              </CardMedia>
            }
          />
        </Grid>

        <Grid item xs={12} xl={10}>
          <GuestbookAlbumForm
            {...item}
            onSave={onSave}
            onCancel={onCancel}
            resetFormAfterCancel={false}
          />
        </Grid>
      </Grid>
      <BackdropLoadingComponent open={!!updateLoading} />
      <ConfirmLeaveDialogComponent
        blockLeave={blockLeave}
        description={t('modal-leave-personlig-warning.body')}
        stayButtonText={t('modal-leave-personlig-warning-stay.button')}
        leaveButtonText={t('modal-leave-personlig-warning-leave.button')}
      />
    </>
  );
}

export const GuestbookAlbumEditContainer = observer(
  GuestbookAlbumEditContainerBase,
);
