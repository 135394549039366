import Grid from '@mui/material/Grid';
import { SxProps, Theme } from '@mui/material/styles';
import React, { memo } from 'react';

import { DeleteButtonComponent } from './delete-button.component';
import { EditButtonComponent } from './edit-button.component';

export interface ManageButtonsProps {
  onClickEdit?: () => void;
  onClickDelete?: () => void;
  sx?: SxProps<Theme>;
  size?: 'small' | 'big';
  editText?: string;
  deleteText?: string;
}

function ManageButtons({
  onClickDelete,
  onClickEdit,
  sx,
  size = 'big',
  editText,
  deleteText,
}: ManageButtonsProps) {
  const xs = onClickDelete && onClickEdit ? 6 : 12;
  const sm = size === 'big' ? 6 : 4;
  const md = size === 'big' ? 4 : 3;
  const lg = size === 'big' ? 3 : 2;
  const xl = size === 'big' ? 2 : 1;

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-end"
      spacing={{ xs: 1, md: 2 }}
      sx={sx}
    >
      {onClickDelete && (
        <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
          <DeleteButtonComponent
            fullWidth
            onClick={onClickDelete}
            text={deleteText}
          />
        </Grid>
      )}
      {onClickEdit && (
        <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
          <EditButtonComponent
            fullWidth
            onClick={onClickEdit}
            text={editText}
          />
        </Grid>
      )}
    </Grid>
  );
}

export const ManageButtonsComponent = memo(ManageButtons);
