import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import React, { memo, ReactElement } from 'react';

import { AvatarComponent } from '../../avatar.component';

function RelativeCardLoading(): ReactElement {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item>
        <Skeleton variant="circular">
          <AvatarComponent
            sx={(theme) => ({
              height: '166px',
              width: '166px',
              fontSize: theme.typography.h2.fontSize,
              fontWeight: theme.typography.h2.fontWeight,
              fontStyle: theme.typography.h2.fontStyle,
              lineHeight: theme.typography.h2.lineHeight,
              letterSpacing: theme.typography.h2.letterSpacing,
            })}
          />
        </Skeleton>
      </Grid>
      <Grid item>
        <Typography variant="body1" textAlign="center" paddingTop={2}>
          <Skeleton width={'140px'} />
        </Typography>
      </Grid>

      <Grid item>
        <Typography variant="body2" textAlign="center" paddingTop={1}>
          <Skeleton width={'40px'} />
        </Typography>
      </Grid>
    </Grid>
  );
}

export const RelativeCardLoadingComponent = memo(RelativeCardLoading);
