import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import React, { memo, ReactElement } from 'react';

import { AvatarComponent } from '../../avatar.component';

function AccessCardLoading(): ReactElement {
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item xl={4}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <Skeleton variant="circular">
              <AvatarComponent />
            </Skeleton>
          </Grid>
          <Grid item>
            <Typography
              variant={'subtitle1'}
              color={'rgba(0, 0, 0, 0.87)'}
              mb={0.5}
            >
              <Skeleton
                sx={(theme) => ({
                  width: '120px',
                  [theme.breakpoints.up('sm')]: {
                    width: '200px',
                  },
                })}
              />
            </Typography>
            <Typography variant={'body2'} color={'rgba(0, 0, 0, 0.6)'}>
              <Skeleton
                sx={(theme) => ({
                  width: '80px',
                  [theme.breakpoints.up('sm')]: {
                    width: '140px',
                  },
                })}
              />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xl={4}>
        <Typography variant={'subtitle1'} color={'rgba(0, 0, 0, 0.87)'}>
          <Skeleton
            sx={(theme) => ({
              width: '70px',
              [theme.breakpoints.up('sm')]: {
                width: '100px',
              },
            })}
          />
        </Typography>
      </Grid>
      <Grid item xl={2}></Grid>
    </Grid>
  );
}

export const AccessCardLoadingComponent = memo(AccessCardLoading);
