import URL from 'url-parse';

export function addSearchParam(
  url: string,
  paramName: string,
  paramValue: string,
): string {
  const urlObj = URL(url);
  urlObj.set(
    'query',
    urlObj.query
      ? `${urlObj.query}&${paramName}=${paramValue}`
      : `${paramName}=${paramValue}`,
  );
  return urlObj.toString();
}
