import orderBy from 'lodash/orderBy';
import { useCallback, useEffect } from 'react';

import { BaseEntity } from '../../interfaces/entities/base-entity.interface';
import { Patient } from '../../interfaces/entities/patient.interface';
import { PatientBaseStore } from '../../stores/shared/patient-base.store';

export function usePatientNestedItems<T extends BaseEntity>(
  store: PatientBaseStore<T>,
  patientId: Patient['id'],
  limit?: number,
  ...order: ReadonlyArray<any>
): {
  items: ReadonlyArray<T>;
  hasMore: boolean;
  loadMore: () => Promise<void>;
  loading?: boolean;
  errorLoading?: boolean;
  reload: () => void;
} {
  useEffect(() => {
    store.listByPatientId(patientId, 1, limit);
  }, [store.listByPatientId, patientId, limit]);

  const loadMore = useCallback(() => {
    return store.loadMoreByPatientId(patientId, limit);
  }, [store.loadMoreByPatientId, patientId]);

  const items: ReadonlyArray<T> = store.itemsByPatientId[patientId] || [];

  return {
    items: order ? orderBy(items, ...order) : items,
    hasMore: store.getHasMoreByPatientId(patientId) || false,
    loadMore: loadMore,
    loading: store.loadingByPatientId[patientId],
    errorLoading: !!store.errorLoadingByPatientId[patientId],
    reload: () => store.listByPatientId(patientId, 1, limit, undefined, true),
  };
}
