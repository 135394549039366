import { Field, FieldProps, Form, Formik, FormikProps } from 'formik';
import React, { forwardRef, memo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { Patient } from '../../interfaces/entities/patient.interface';
import { SingleLineField } from './shared/name-field.component';

type PatientBirthPlace = Pick<Patient, 'birthPlace'>;

interface PatientBirthPlaceFormProps extends PatientBirthPlace {
  onChange: (data: PatientBirthPlace) => void;
}

function PatientBirthPlaceFormBase(
  { onChange, ...props }: PatientBirthPlaceFormProps,
  ref: React.Ref<FormikProps<Patient>>,
) {
  const { t } = useTranslation();

  return (
    <Formik<PatientBirthPlace>
      initialValues={props || {}}
      onSubmit={onChange}
      innerRef={ref as React.Ref<FormikProps<PatientBirthPlace>>}
      validationSchema={Yup.object<
        Record<keyof PatientBirthPlace, Yup.AnySchema>
      >().shape({
        birthPlace: Yup.string().nullable().optional(),
      })}
    >
      {() => (
        <Form>
          <Field name="birthPlace">
            {(props: FieldProps) => {
              return (
                <SingleLineField
                  {...props}
                  label={t('persona-edit-home-place.label')}
                  assistiveText={t('home-place.assistive')}
                  assistiveTextPosition={'top'}
                />
              );
            }}
          </Field>
        </Form>
      )}
    </Formik>
  );
}

export const PatientBirthPlaceForm = memo(
  forwardRef(PatientBirthPlaceFormBase),
);
