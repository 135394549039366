import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BackButtonComponent } from '../../components/buttons/back-button.component';
import { ConfirmLeaveDialogComponent } from '../../components/dialog/confirm-leave-dialog.component';
import { BackdropLoadingComponent } from '../../components/loading/backdrop-loading.component';
import { PatientsOneEditLoadingComponent } from '../../components/loading/patients/patients-one-edit-loading.component';
import { PhotoEditorComponent } from '../../components/photo-editor.component';
import { MY_PATIENTS_ROUTE } from '../../configs/routes';
import { PatientForm } from '../../forms/patient.form';
import { usePatient } from '../../hooks/patients/use-patient';
import { usePatientRole } from '../../hooks/patients/use-patient-role';
import { useFormActions } from '../../hooks/use-form-actions';
import { usePhotoEditor } from '../../hooks/use-photo-editor';
import { Patient } from '../../interfaces/entities/patient.interface';
import { ProfilePageProps } from '../../interfaces/profile-page-props.interface';

function PatientsOneEdit({ profileId }: ProfilePageProps) {
  const { t } = useTranslation();
  const [blockLeave, setBlockLeave] = useState(true);

  const { item, fetchLoading, update, updateLoading, updateWithPhoto } =
    usePatient(profileId);
  const { isPatientRoleAdmin } = usePatientRole(profileId);

  const {
    props: photoEditorProps,
    isChanged: photoIsChanged,
    getPhoto,
  } = usePhotoEditor(item?.image);

  const backPath = `${MY_PATIENTS_ROUTE.path}/${profileId}`;

  const { onCancel, onConfirm } = useFormActions(backPath);

  const onSave = useCallback(
    async (data: Partial<Patient>) => {
      if (!photoIsChanged) {
        await update(data);
      } else {
        const image = await getPhoto();
        if (!image) {
          await update({ ...data, image: null });
        } else {
          await updateWithPhoto(data, image as File);
        }
      }
      setBlockLeave(false);
      onConfirm();
      setBlockLeave(true);
    },
    [update, updateWithPhoto, photoIsChanged, getPhoto, onConfirm],
  );

  if (fetchLoading) {
    return <PatientsOneEditLoadingComponent />;
  }

  if (!item || !isPatientRoleAdmin) {
    return <></>;
  }

  return (
    <>
      <Grid container direction="row" spacing={1}>
        <Grid item xs={12}>
          <BackButtonComponent path={backPath} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant={'h5'}>
            {t('patient-edit-profile-picture.header')}
          </Typography>
        </Grid>
        <Grid item xs={12} xl={10}>
          <PhotoEditorComponent
            {...photoEditorProps}
            uploadButtonText={
              photoEditorProps.image
                ? t('patient-edit-profile-picture.change')
                : t('patient-edit-profile-picture.upload')
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant={'h5'} pt={1}>
            {t('patient-edit-bio.header')}
          </Typography>
        </Grid>
        <Grid item xs={12} xl={10}>
          <PatientForm
            {...item}
            onSave={onSave}
            onCancel={onCancel}
            resetFormAfterCancel={false}
          />
        </Grid>
      </Grid>
      <BackdropLoadingComponent open={!!updateLoading} />
      <ConfirmLeaveDialogComponent
        blockLeave={blockLeave}
        description={t('modal-leave-personlig-warning.body')}
        stayButtonText={t('modal-leave-personlig-warning-stay.button')}
        leaveButtonText={t('modal-leave-personlig-warning-leave.button')}
      />
    </>
  );
}

export const PatientsOneEditContainer = observer(PatientsOneEdit);
