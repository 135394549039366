import Grid from '@mui/material/Grid';
import React, { memo, ReactElement, ReactNode } from 'react';

import { CreateButtonComponent } from '../buttons/create-button.component';

interface PatientNestedItemsWrapperProps {
  titleCreateButton?: string;
  onClickCreate?: () => void;
  children: ReactNode;
  disabledCreateButton?: boolean;
}

function PatientNestedItemsWrapper({
  children,
  onClickCreate,
  titleCreateButton,
  disabledCreateButton,
}: PatientNestedItemsWrapperProps): ReactElement {
  return (
    <Grid container direction="column" alignItems="flex-end" spacing={3}>
      {onClickCreate && (
        <Grid
          item
          sx={(theme) => ({
            [theme.breakpoints.down('md')]: {
              width: '100%',
            },
          })}
        >
          <CreateButtonComponent
            fullWidth
            onClick={onClickCreate}
            title={titleCreateButton}
            disabled={disabledCreateButton}
          />
        </Grid>
      )}
      <Grid
        item
        sx={{
          width: '100%',
        }}
      >
        {children}
      </Grid>
    </Grid>
  );
}

export const PatientNestedItemsWrapperComponent = memo(
  PatientNestedItemsWrapper,
);
