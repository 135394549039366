import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { memo, ReactNode } from 'react';

import { useInstallWidget } from '../hooks/use-install-widget';
import { EmptyScreenButtonComponent } from './buttons/empty-screen-button.component';

interface EmptyScreenProps {
  title: string;
  description: string;
  hasContent?: boolean;
  onClick?: () => void;
  text: string;
  icon?: ReactNode;
}

const BOX_STYLE = {
  position: 'fixed',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  bottom: '70px',
  padding: '20px 10px 36px',
  background: '#FFFFFF',
};

function EmptyScreen({
  title,
  description,
  hasContent,
  onClick,
  text,
  icon,
}: EmptyScreenProps) {
  const { show: showInstallWidget } = useInstallWidget();

  return (
    <Grid
      direction="column"
      container
      alignItems="center"
      sx={(theme) => ({
        paddingTop: '100px',
        [theme.breakpoints.up('sm')]: {
          pt: 6,
        },
      })}
    >
      <Typography
        variant={'h6'}
        pb={4}
        textAlign="center"
        sx={(theme) => ({
          [theme.breakpoints.up('sm')]: {
            fontSize: '34px',
          },
        })}
      >
        {title}
      </Typography>
      {hasContent && (
        <Typography textAlign="center" maxWidth={700} variant={'body1'} mb={6}>
          {description}
        </Typography>
      )}
      {hasContent && (
        <Box
          sx={(theme) => ({
            [theme.breakpoints.down('sm')]: {
              ...BOX_STYLE,
              bottom: !showInstallWidget ? BOX_STYLE.bottom : '166px',
            },
          })}
        >
          <EmptyScreenButtonComponent
            startIcon={icon}
            onClick={onClick}
            variant="contained"
            color="secondary"
          >
            {text}
          </EmptyScreenButtonComponent>
        </Box>
      )}
    </Grid>
  );
}

export const EmptyScreenComponent = memo(EmptyScreen);
