import orderBy from 'lodash/orderBy';

import { ProfileNavigationItem } from '../interfaces/profile-navigation-item';

export function getProfileMenuIndex(
  pathname: string,
  items: ReadonlyArray<ProfileNavigationItem>,
): number {
  const purePathname = pathname.replace(/\/$/, '');
  for (let i = 0; i < items.length; i++) {
    const to = items[i].to.replace(/\/$/, '');
    if (to === purePathname) {
      return i;
    }
  }

  const arrStartsWith = [];
  for (let i = 0; i < items.length; i++) {
    const to = items[i].to.replace(/\/$/, '');
    if (purePathname.startsWith(to)) {
      arrStartsWith.push({ to, index: i });
    }
  }

  const sortedArrStartsWith = orderBy(
    arrStartsWith,
    ({ to }) => to.length,
    'desc',
  );

  return sortedArrStartsWith.length ? sortedArrStartsWith[0]?.index : -1;
}
