import Badge from '@mui/material/Badge';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { PatientNavigationItem } from '../../../interfaces/patient-navigation-item';

interface ListItemProps extends PatientNavigationItem {
  isActive?: boolean;
  onClick?: () => void;
}

function ListItem(props: ListItemProps) {
  const { t } = useTranslation();

  const { Icon, ActiveIcon, isActive, label = '', onClick, counter } = props;

  return (
    <ListItemButton
      to={props.to}
      exact={props.exact}
      component={NavLink}
      onClick={onClick}
      sx={(theme: Theme) => ({
        paddingLeft: '18px',
        paddingRight: '18px',
        color: 'rgba(0, 0, 0, 0.6) ',
        '& svg': {
          fill: '#000000',
          fillOpacity: 0.87,
        },
        '&.active': {
          background: `${theme.palette.primary.light} !important`,
          color: `${theme.palette.primary.main} !important`,
          '& svg': {
            fill: theme.palette.primary.dark,
            fillOpacity: 1,
          },
        },
      })}
    >
      <ListItemIcon sx={{ minWidth: '54px' }}>
        {!ActiveIcon || !isActive ? (
          <Icon sx={{ height: '20px', color: 'rgba(0, 0, 0, 0.87)' }} />
        ) : (
          <ActiveIcon sx={{ height: '20px', color: 'rgba(0, 0, 0, 0.87)' }} />
        )}
      </ListItemIcon>
      <ListItemText>
        <Typography variant="subtitle2">{t(label)}</Typography>
      </ListItemText>
      {counter && <Badge badgeContent={counter} color="primary" />}
    </ListItemButton>
  );
}

export const ListItemComponent = memo(ListItem);
