import VisibilityIcon from '@mui/icons-material/Visibility';
import AvatarGroup from '@mui/material/AvatarGroup';
import Button, { ButtonProps } from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { memo } from 'react';

import { Viewer } from '../../interfaces/viewer.interface';
import getName from '../../utils/get-name';
import { AvatarComponent } from '../avatar.component';

interface VisibilityButtonProps extends ButtonProps {
  viewers: Viewer[];
}

const DEFAULT_MAX = 3;

function VisibilityButton({ viewers, ...props }: VisibilityButtonProps) {
  return (
    <Button size="small" startIcon={<VisibilityIcon />} {...props}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={0.5}
      >
        <Grid item>
          <Typography variant={'body2'}>{viewers.length}</Typography>
        </Grid>
        <Grid item>
          <AvatarGroup
            max={DEFAULT_MAX}
            sx={{
              '&:hover': {
                cursor: 'pointer',
              },
              '& .MuiAvatarGroup-avatar': {
                width: 25,
                height: 25,
                fontSize: '12px',
              },
            }}
          >
            {viewers.map(({ id, firstName, lastName, image }) => (
              <AvatarComponent
                key={id}
                name={getName(firstName, lastName)}
                src={image}
              />
            ))}
          </AvatarGroup>
        </Grid>
      </Grid>
    </Button>
  );
}

export const VisibilityButtonComponent = memo(VisibilityButton);
