import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker, { DatePickerProps } from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TextField from '@mui/material/TextField';
import { FieldProps } from 'formik';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { LOCALES } from '../../../utils/constants';
import { LOCALES_MAPPER } from '../../shared/date-field.component';
import {
  FieldWrapperComponent,
  FieldWrapperProps,
} from './field-wrapper.component';

interface DateFieldProps
  extends FieldProps,
    Pick<FieldWrapperProps, 'assistiveText' | 'assistiveTextPosition'> {
  label?: string;
  inputFormat?: string;
  readOnly?: DatePickerProps['readOnly'];
}

function DateFieldBase({
  label,
  inputFormat = 'dd/MM/yyyy',
  readOnly,
  ...props
}: DateFieldProps) {
  const { i18n } = useTranslation();

  const locale = LOCALES_MAPPER[i18n.language as LOCALES] || LOCALES_MAPPER.nb;

  return (
    <FieldWrapperComponent {...props}>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
        <DatePicker
          {...props.field}
          disableFuture
          label={label}
          inputFormat={inputFormat}
          readOnly={readOnly}
          renderInput={(params) => (
            <TextField
              onBlur={props.field.onBlur}
              {...params}
              error={props.meta.touched && !!props.meta.error}
              sx={{ width: '100%' }}
            />
          )}
          onChange={(date?: Date | null) =>
            props.form.setFieldValue(props.field.name, date || null)
          }
        />
      </LocalizationProvider>
    </FieldWrapperComponent>
  );
}

export const DateField = memo(DateFieldBase);
