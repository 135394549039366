import Grid from '@mui/material/Grid';
import { SxProps, Theme } from '@mui/material/styles';
import React, { memo } from 'react';

import { BackButtonComponent } from './back-button.component';
import { ManageButtonsComponent } from './manage-buttons.component';

export interface BackAndManageButtonsProps {
  backPath?: string;
  onClickEdit?: () => void;
  onClickDelete?: () => void;
  sx?: SxProps<Theme>;
  editText?: string;
  deleteText?: string;
}

function BackAndManageButtons({
  onClickDelete,
  onClickEdit,
  backPath,
  sx,
  editText,
  deleteText,
}: BackAndManageButtonsProps) {
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="stretch"
      spacing={1}
      sx={sx}
    >
      <Grid item xs={4}>
        <BackButtonComponent path={backPath} />
      </Grid>
      <Grid item xs={8}>
        <ManageButtonsComponent
          onClickEdit={onClickEdit}
          onClickDelete={onClickDelete}
          editText={editText}
          deleteText={deleteText}
        />
      </Grid>
    </Grid>
  );
}

export const BackAndManageButtonsComponent = memo(BackAndManageButtons);
