import orderBy from 'lodash/orderBy';
import { useCallback, useEffect, useMemo } from 'react';

import { AlbumFile } from '../interfaces/entities/album-file.interface';
import { GuestbookFile } from '../interfaces/entities/guestbook-file.interface';
import { Patient } from '../interfaces/entities/patient.interface';
import { useStores } from './use-stores';

export function useFavoriteFiles(profileId: Patient['id']): {
  items: AlbumFile[] | undefined;
  loading?: boolean;
  total?: number;
  onClickFavorite: (id: GuestbookFile['id'], value: boolean) => void;
  loadMore: () => Promise<void>;
} {
  const { guestbookFilesStore, albumFilesStore } = useStores();

  useEffect(() => {
    guestbookFilesStore.fetchAllFavorite(profileId);
  }, [profileId]);

  useEffect(() => {
    albumFilesStore.fetchAllFavorite(profileId);
  }, [profileId]);

  const items = useMemo(() => {
    if (
      !guestbookFilesStore.allFavoritesByProfileId[profileId] &&
      !albumFilesStore.allFavoritesByProfileId[profileId]
    ) {
      return;
    }

    const guestbookFiles = (
      guestbookFilesStore.allFavoritesByProfileId[profileId] || []
    ).map(
      (item) => ({ ...item, isFavorite: item.albumIsFavorite } as AlbumFile),
    );

    const albumFiles = albumFilesStore.allFavoritesByProfileId[profileId] || [];

    return orderBy([...guestbookFiles, ...albumFiles], ['createdAt'], ['desc']);
  }, [
    profileId,
    guestbookFilesStore.allFavoritesByProfileId[profileId],
    albumFilesStore.allFavoritesByProfileId[profileId],
  ]);

  const loading: boolean = useMemo(() => {
    if (
      guestbookFilesStore.loadingAllFavoritesByProfileId[profileId] !== false ||
      albumFilesStore.loadingAllFavoritesByProfileId[profileId] !== false
    ) {
      return true;
    }

    return false;
  }, [
    profileId,
    guestbookFilesStore.loadingAllFavoritesByProfileId[profileId],
    albumFilesStore.loadingAllFavoritesByProfileId[profileId],
  ]);

  const total = useMemo(() => {
    if (
      !guestbookFilesStore.allFavoritesByProfileId[profileId] ||
      !albumFilesStore.allFavoritesByProfileId[profileId]
    ) {
      return;
    }

    return (
      guestbookFilesStore.allFavoritesByProfileId[profileId]?.length +
      albumFilesStore.allFavoritesByProfileId[profileId]?.length
    );
  }, [
    profileId,
    guestbookFilesStore.allFavoritesByProfileId[profileId],
    albumFilesStore.allFavoritesByProfileId[profileId],
  ]);

  const onClickFavorite = useCallback(
    (id: string, value: boolean) => {
      const albumFileFound = (
        albumFilesStore.allFavoritesByProfileId[profileId] || []
      ).find((item) => item.id === id);

      if (albumFileFound) {
        if (value) {
          return albumFilesStore.makeFavorite(
            profileId,
            id,
            albumFileFound.albumId,
            value,
          );
        }

        return albumFilesStore.removeFavorite(profileId, id);
      }

      const guestbookFileFound = (
        guestbookFilesStore.allFavoritesByProfileId[profileId] || []
      ).find((item) => item.id === id);

      if (guestbookFileFound) {
        if (value) {
          return guestbookFilesStore.makeFavorite(profileId, id);
        }

        return guestbookFilesStore.removeFavorite(profileId, id);
      }
    },
    [
      profileId,
      guestbookFilesStore.allFavoritesByProfileId[profileId],
      albumFilesStore.allFavoritesByProfileId[profileId],
    ],
  );

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const loadMore = useCallback(async () => {}, []);

  return {
    items,
    loading,
    total,
    onClickFavorite,
    loadMore,
  };
}
