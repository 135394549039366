import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Button, { ButtonProps } from '@mui/material/Button';
import FormLabel from '@mui/material/FormLabel';
import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { GuestbookPreviewFile } from '../../interfaces/guestbook-preview-file.interface';
import { InputFileComponent } from '../input-file.component';

const SELECT_FILES_INPUT_ID = 'guestbook-upload-select-file-input-id';

export interface GuestbookUploadButtonProps extends ButtonProps {
  inputId?: string;
  onSelectFiles?: (arr: ReadonlyArray<GuestbookPreviewFile>) => void;
}

function GuestbookUploadButton({
  onSelectFiles,
  inputId = SELECT_FILES_INPUT_ID,
  ...buttonProps
}: GuestbookUploadButtonProps) {
  const { t } = useTranslation();

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const selectedFiles: ReadonlyArray<GuestbookPreviewFile> = [
        ...Array.prototype.slice.call(event.target.files || []),
      ].map((file) => ({
        file,
        name: file.name,
        type: file.type,
        url: URL.createObjectURL(file),
      }));

      if (onSelectFiles) {
        onSelectFiles(selectedFiles);
      }
    },

    [onSelectFiles],
  );

  return (
    <>
      <InputFileComponent
        inputProps={{
          accept: 'video/*,image/*,.heic,.heif',
          multiple: true,
        }}
        id={inputId}
        onChange={onChange}
      />
      <FormLabel htmlFor={inputId} sx={{ width: '100%' }}>
        <Button
          variant="contained"
          component="span"
          startIcon={<CloudUploadIcon />}
          {...buttonProps}
        >
          {buttonProps?.children || t('guestbook-attach.button')}
        </Button>
      </FormLabel>
    </>
  );
}

export const GuestbookUploadButtonComponent = memo(GuestbookUploadButton);
