import ArrowBackIosNewRounded from '@mui/icons-material/ArrowBackIosNewRounded';
import { ButtonProps } from '@mui/material/Button';
import React, { memo } from 'react';

import { RoundButtonComponent } from './round-button.component';

function RoundBackButton(props: ButtonProps) {
  return (
    <RoundButtonComponent {...props}>
      <ArrowBackIosNewRounded />
    </RoundButtonComponent>
  );
}

export const RoundBackButtonComponent = memo(RoundBackButton);
