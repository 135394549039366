import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import GroupsIcon from '@mui/icons-material/Groups';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  GuestbookPost,
  GuestbookPostUser,
} from '../../interfaces/entities/guestbook-post.interface';
import { getDateTime } from '../../utils/get-date-time';
import getName from '../../utils/get-name';
import { AvatarWithLightboxComponent } from '../avatar-with-lightbox.component';
import { MoreButtonComponent } from '../more-button.component';

export interface GuestbookPostMessageProps extends Partial<GuestbookPostUser> {
  showMenuButton?: boolean;
  createdAt: GuestbookPost['createdAt'];
  onClickEdit?: () => void;
  onClickDelete?: () => void;
  isNew?: GuestbookPost['isNew'];
  commonId?: GuestbookPost['commonId'];
}

function GuestbookPostMessage({
  firstName,
  lastName,
  image,
  accesses,
  createdAt,
  showMenuButton,
  onClickEdit,
  onClickDelete,
  isNew,
  commonId,
}: GuestbookPostMessageProps) {
  const { t } = useTranslation();

  const handleClickEdit = useCallback(() => {
    if (onClickEdit) {
      onClickEdit();
    }
  }, [onClickEdit]);

  const handleClickDelete = useCallback(() => {
    if (onClickDelete) {
      onClickDelete();
    }
  }, [onClickDelete]);

  const name = useMemo(() => {
    return getName(firstName, lastName);
  }, [firstName, lastName]);

  const dateTime = useMemo(() => {
    return getDateTime(createdAt);
  }, [createdAt]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="flex-end"
    >
      <Grid item>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <AvatarWithLightboxComponent src={image} name={name} />
          </Grid>
          <Grid item>
            <Typography variant={'subtitle1'} color={'rgba(0, 0, 0, 0.87)'}>
              {name}
              {!!accesses?.length && (
                <Typography
                  variant={'body2'}
                  color={'rgba(0, 0, 0, 0.6)'}
                  sx={(theme) => ({
                    display: 'inline',
                    [theme.breakpoints.down('md')]: {
                      display: 'block',
                    },
                  })}
                >
                  {` (${t(`access-list-role-${accesses[0].role}.label`)})`}
                </Typography>
              )}
            </Typography>
            <Typography variant={'caption'} color={'rgba(0, 0, 0, 0.87)'}>
              {dateTime}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-end"
          spacing={0.75}
          paddingRight={1}
        >
          {showMenuButton && (
            <MoreButtonComponent
              aria-label="more"
              aria-haspopup="true"
              size={'large'}
              sx={{
                padding: 0,
                color: 'rgba(0, 0, 0, 0.87)',
              }}
              items={[
                <ListItem
                  button
                  key={'general-edit.button'}
                  onClick={handleClickEdit}
                  divider
                >
                  <ListItemIcon>
                    <EditIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('general-edit.button')} />
                </ListItem>,
                <ListItem
                  button
                  key={'general-delete.button'}
                  onClick={handleClickDelete}
                >
                  <ListItemIcon>
                    <DeleteIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('general-delete.button')} />
                </ListItem>,
              ]}
            />
          )}
          {isNew && (
            <Chip
              avatar={
                <FiberManualRecordIcon
                  sx={(theme) => ({
                    color: `${theme.palette.primary.main}!important`,
                    backgroundColor: 'transparent!important',
                  })}
                />
              }
              label={t('guestbook-post-new.label')}
              variant="outlined"
              color="primary"
            />
          )}
          {commonId && (
            <Chip
              avatar={
                <GroupsIcon
                  sx={(theme) => ({
                    ml: 0.75,
                    color: `${theme.palette.primary.main}!important`,
                    backgroundColor: 'transparent!important',
                  })}
                />
              }
              label={
                <Typography
                  variant={'caption'}
                  fontWeight={500}
                  color={'primary'}
                  mr={-1}
                >
                  {t('guestbook-common-post.tag')}
                </Typography>
              }
              variant="outlined"
              color="primary"
            />
          )}
        </Stack>
      </Grid>
    </Grid>
  );
}

export const GuestbookPostMessageComponent = memo(GuestbookPostMessage);
