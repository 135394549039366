export default function (name?: string, n?: number): string {
  return name
    ? name
        .split(' ')
        .map((item) => item.substring(0, 1))
        .join('')
        .toUpperCase()
        .replace(/\P{L}/gu, '')
        .slice(0, n)
    : '';
}
