import WarningIcon from '@mui/icons-material/Warning';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Location } from 'history';
import React, { memo, useCallback, useState } from 'react';
import { Prompt, useHistory } from 'react-router-dom';

import { DialogComponent } from './dialog.component';

interface ConfirmLeaveDialogProps {
  description?: string;
  stayButtonText?: string;
  leaveButtonText?: string;
  blockLeave?: boolean;
  open?: boolean;
  onClickStay?: () => void;
  onClickLeave?: () => void;
}

const ConfirmLeaveDialog = ({
  blockLeave = true,
  open,
  description,
  leaveButtonText,
  stayButtonText,
  onClickStay,
  onClickLeave,
}: ConfirmLeaveDialogProps) => {
  const history = useHistory();

  const [locationToLeave, setLocationToLeave] = useState<Location | null>(null);

  const promptMessage = useCallback((location: Location) => {
    if (window.location.pathname === location.pathname) {
      return true;
    }

    setLocationToLeave(location);

    return false;
  }, []);

  const handleClickStay = useCallback(() => {
    if (onClickStay) {
      onClickStay();
    }
    setLocationToLeave(null);
  }, [onClickStay]);

  const handleClickLeave = useCallback(() => {
    if (onClickLeave) {
      onClickLeave();
    }
    setLocationToLeave(null);
    if (locationToLeave) {
      history.push(locationToLeave);
    }
  }, [history, locationToLeave, onClickLeave]);

  return (
    <>
      <Prompt
        when={blockLeave ? !locationToLeave : false}
        message={promptMessage}
      />
      <DialogComponent
        isOpen={open || !!locationToLeave}
        title={
          <WarningIcon fontSize="large" sx={{ mx: 'auto', display: 'block' }} />
        }
        description={description}
        onClose={handleClickStay}
      >
        <Box
          display="flex"
          px={3}
          py={2}
          sx={(theme) => ({
            flexDirection: 'column',
            [theme.breakpoints.up('md')]: {
              flexDirection: 'row',
              justifyContent: 'flex-end',
            },
          })}
        >
          <Button
            onClick={handleClickStay}
            variant={'contained'}
            autoFocus
            sx={{
              minWidth: '150px',
            }}
          >
            {stayButtonText}
          </Button>
          <Button
            autoFocus
            onClick={handleClickLeave}
            sx={(theme) => ({
              minWidth: '80px',
              flexDirection: 'column',
              order: '-1',
              [theme.breakpoints.up('md')]: {
                flexDirection: 'row',
                justifyContent: 'flex-end',
                order: '0',
                ml: 1,
              },
            })}
          >
            {leaveButtonText}
          </Button>
        </Box>
      </DialogComponent>
    </>
  );
};

export const ConfirmLeaveDialogComponent = memo(ConfirmLeaveDialog);
