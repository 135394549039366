import Typography from '@mui/material/Typography';
import {
  Editor,
  IAllProps as WysiwygEditorProps,
} from '@tinymce/tinymce-react';
import React, { memo, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Editor as TinyMCEEditor, EditorEvent } from 'tinymce';

import { YoutubeDialogComponent } from '../../../components/youtube-dialog.component';
import { StoriesOneImagesDialogContainer } from '../../../containers/stories/stories-one-images-dialog.continer';
import { LOCALES } from '../../../utils/constants';
import {
  getYouTubeId,
  YOUTUBE_EMBED_PATH,
} from '../../../utils/get-youtube-id';
import { FieldProps } from '../../interfaces/field-props.interface';
import { FieldWrapperComponent } from '../field-wrapper.component';
import {
  EXTENDED_VALID_ELEMENTS,
  TINYMCE_LOCALES_MAPPER,
  TYNIMCE_API_KEY,
  TYNIMCE_AUTORISIZE_BOTTOM_MARGIN,
  TYNIMCE_IMAGE_BUTTON,
  TYNIMCE_IMAGE_BUTTON_ICON,
  TYNIMCE_MIN_HEIGHT,
  TYNIMCE_PLUGINS,
  TYNIMCE_TOOLBAR,
  TYNIMCE_VIDEO_BUTTON,
  TYNIMCE_VIDEO_BUTTON_ICON,
} from './consts';

type WysiwygFieldProps = FieldProps & WysiwygEditorProps;

const FIELD_NAME_DEFAULT = 'wysiwyg';
const FIELD_ID_DEFAULT = `${FIELD_NAME_DEFAULT}-id`;

function WysiwygField(props: WysiwygFieldProps) {
  const { t, i18n } = useTranslation();
  const { id = FIELD_ID_DEFAULT, name = FIELD_ID_DEFAULT, label } = props;

  const editorRef = useRef<TinyMCEEditor>(null);

  const [openYoutubeDialog, setOpenYoutubeDialog] = useState(false);
  const [openPhotosDialog, setOpenPhotosDialog] = useState(false);

  const showYoutubeDialog = useCallback(() => {
    setOpenYoutubeDialog(true);
  }, [setOpenYoutubeDialog]);

  const hideYoutubeDialog = useCallback(() => {
    setOpenYoutubeDialog(false);
  }, [setOpenYoutubeDialog]);

  const showPhotosDialog = useCallback(() => {
    setOpenPhotosDialog(true);
  }, [setOpenPhotosDialog]);

  const hidePhotosDialog = useCallback(() => {
    setOpenPhotosDialog(false);
  }, [setOpenPhotosDialog]);

  const onSubmitYoutubeDialog = useCallback(
    (url: string) => {
      hideYoutubeDialog();

      const youtubeVideoId = getYouTubeId(url);

      editorRef.current?.execCommand(
        'mceInsertContent',
        false,
        `<iframe
              src="${YOUTUBE_EMBED_PATH}${youtubeVideoId}"
              width="320"
              height="180"
              frameborder="0"
              allowfullscreen="allowfullscreen"
            ></iframe>`,
      );
    },
    [hideYoutubeDialog],
  );

  const onSubmitImageDialog = useCallback(
    (url: string) => {
      hidePhotosDialog();
      editorRef.current?.execCommand(
        'mceInsertContent',
        false,
        `<img src="${url}" width="450px"></image>`,
      );
    },
    [hidePhotosDialog],
  );

  const onInit = useCallback(
    (event: EditorEvent<Record<string, unknown>>, editor: TinyMCEEditor) => {
      (editorRef.current as TinyMCEEditor | null) = editor;
    },
    [editorRef?.current],
  );

  const setup = useCallback(
    (editor: TinyMCEEditor) => {
      editor.ui.registry.addButton(TYNIMCE_IMAGE_BUTTON, {
        icon: TYNIMCE_IMAGE_BUTTON_ICON,
        tooltip: t('stories-edit-add-picture.header'),
        onAction: showPhotosDialog,
      });
      editor.ui.registry.addButton(TYNIMCE_VIDEO_BUTTON, {
        icon: TYNIMCE_VIDEO_BUTTON_ICON,
        tooltip: t('stories-edit-add-youtube-link.header'),
        onAction: showYoutubeDialog,
      });
    },
    [editorRef?.current, t, showYoutubeDialog],
  );

  return (
    <FieldWrapperComponent name={name} {...props}>
      {label && <Typography variant={'subtitle1'}>{label}</Typography>}
      <Editor
        apiKey={TYNIMCE_API_KEY}
        onInit={onInit}
        init={{
          id,
          min_height: TYNIMCE_MIN_HEIGHT,
          autoresize_bottom_margin: TYNIMCE_AUTORISIZE_BOTTOM_MARGIN,
          menubar: false,
          plugins: TYNIMCE_PLUGINS,
          toolbar: TYNIMCE_TOOLBAR,
          statusbar: false,
          mobile: {
            toolbar_drawer: 'floating',
          },
          language:
            TINYMCE_LOCALES_MAPPER[i18n.language as LOCALES] ||
            TINYMCE_LOCALES_MAPPER.nb,
          setup: setup,
          extended_valid_elements: EXTENDED_VALID_ELEMENTS,
          contextmenu: false,
        }}
        {...props}
      />
      <YoutubeDialogComponent
        open={openYoutubeDialog}
        onClose={hideYoutubeDialog}
        onSave={onSubmitYoutubeDialog}
      />
      <StoriesOneImagesDialogContainer
        open={openPhotosDialog}
        onClose={hidePhotosDialog}
        onSave={onSubmitImageDialog}
      />
    </FieldWrapperComponent>
  );
}

export const WysiwygFieldComponent = memo(WysiwygField);
