import { ACCESS_ROLES } from '../interfaces/entities/access.interface';
import { User } from '../interfaces/user.interface';

export function allNursesHaveRole(
  nurses: User[],
  nursesAccesses: Record<User['id'], ACCESS_ROLES>,
  role: ACCESS_ROLES,
): boolean {
  const n = nurses.length;
  for (let i = 0; i < n; i++) {
    const nurse = nurses[i];
    if (nursesAccesses[nurse.id] !== role) {
      return false;
    }
  }
  return true;
}
