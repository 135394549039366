import Grid from '@mui/material/Grid';
import React, { memo } from 'react';

import { AlbumFileCardLoadingComponent } from './album-files/album-file-card-loading.component';

const ARRAY = [1, 2, 3, 4, 5, 6];

function ImagesDialogLoading() {
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={3}
    >
      {ARRAY.map((index) => (
        <Grid key={index} item xs={6} sm={4} md={3} lg={2}>
          <AlbumFileCardLoadingComponent />
        </Grid>
      ))}
    </Grid>
  );
}

export const ImagesDialogLoadingComponent = memo(ImagesDialogLoading);
