import Button, { ButtonProps } from '@mui/material/Button';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

function CancelButton(props: ButtonProps) {
  const { t } = useTranslation();

  return (
    <Button variant={'outlined'} disableElevation {...props}>
      {t('general-cancel.button')}
    </Button>
  );
}

export const CancelButtonComponent = memo(CancelButton);
