import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { Redirect } from 'react-router-dom';

import { MY_PATIENTS_ROUTE } from '../configs/routes';
import { BackdropLoadingComponent } from './loading/backdrop-loading.component';

export interface LocaleRouteProps {
  locale: string;
  route: RouteComponentProps<{
    [key: string]: string;
  }>;
}

function LocaleRouteComponent({ locale, route }: LocaleRouteProps) {
  const { i18n } = useTranslation();

  const routerPath = (
    route.match.params as {
      [key: string]: string;
    }
  )?.[0];

  useEffect(() => {
    if (routerPath) {
      i18n.changeLanguage(locale).then(() => {
        route.history.replace(`/${routerPath}${route.location.search}`);
      });
    }
  }, [route, routerPath, i18n]);

  if (routerPath) {
    return <BackdropLoadingComponent open={true} />;
  }

  return <Redirect to={MY_PATIENTS_ROUTE.path} />;
}

export const LocaleRoute = memo(LocaleRouteComponent);
