import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  DeleteDialogComponent,
  DeleteDialogProps,
} from '../dialog/delete-dialog.component';

export type StoryDeleteDialog = Pick<
  DeleteDialogProps,
  'open' | 'onCancel' | 'onConfirm'
>;

function StoryDeleteDialog(props: StoryDeleteDialog) {
  const { t } = useTranslation();
  return (
    <DeleteDialogComponent
      {...props}
      title={t('story-delete-warning-modal.header')}
      contentText={t('story-delete-warning-modal.body')}
    />
  );
}

export const StoryDeleteDialogComponent = memo(StoryDeleteDialog);
