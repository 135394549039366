import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

import PROFILE_NAVIGATION_ITEMS, {
  PROFILE_NAVIGATION_ITEM_HOME,
} from '../../configs/profile-navigation';
import { MY_PATIENTS_ROUTE } from '../../configs/routes';
import { Patient } from '../../interfaces/entities/patient.interface';
import { ProfileNavigationItem } from '../../interfaces/profile-navigation-item';
import { LIGHT_SILVER_DARKER_COLOR } from '../../theme';
import { getProfileMenuIndex } from '../../utils/get-profile-menu-index';
interface ProfileNavigationProps {
  profile?: Patient;
  profileId: string;
  loading?: boolean;
}

const getSidebarItems: (
  id: string,
  profile?: Patient,
) => ReadonlyArray<ProfileNavigationItem> = (id: string, profile?: Patient) =>
  PROFILE_NAVIGATION_ITEMS.map((item) => ({
    ...item,
    to: `${MY_PATIENTS_ROUTE.path}/${id}/${item.to}`,
    counter:
      item.label === PROFILE_NAVIGATION_ITEM_HOME.label &&
      profile?.newGuestbookPostsCount
        ? profile.newGuestbookPostsCount
        : undefined,
  }));

function ProfileNavigation({ profile, profileId }: ProfileNavigationProps) {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  const { t } = useTranslation();

  const sidebarItems = useMemo(() => {
    return getSidebarItems(profileId, profile);
  }, [profile]);

  const selectedIndex = useMemo(() => {
    return getProfileMenuIndex(location.pathname, sidebarItems);
  }, [location.pathname, sidebarItems]);

  return (
    <Box
      sx={(theme) => ({
        mt: 0.75,
        ml: -5,
        width: `calc(100% + ${theme.spacing(2 * 5)})`,
        [theme.breakpoints.down('sm')]: {
          ml: -1,
          width: `calc(100% + ${theme.spacing(2)})`,
        },
      })}
    >
      <Toolbar
        sx={{
          minHeight: 'auto!important',
          px: '0!important',
          borderTop: `1px solid ${LIGHT_SILVER_DARKER_COLOR}`,
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          component={List}
          sx={{
            p: 0,
          }}
        >
          {sidebarItems.map((item, index) => (
            <Grid
              key={index}
              item
              xs={12 / sidebarItems.length}
              component={ListItem}
              sx={{
                p: 0,
              }}
            >
              <ListItemButton
                to={item.to}
                component={NavLink}
                selected={selectedIndex === index}
                sx={(theme) => ({
                  height: theme.spacing(8),
                  color: 'rgba(0, 0, 0, 0.6)',
                  '&.Mui-selected': {
                    color: theme.palette.primary.main,
                    backgroundColor: 'transparent',
                    borderBottom: `${mobileView ? 3 : 2}px solid ${
                      theme.palette.primary.main
                    }`,
                    [theme.breakpoints.down('sm')]: {
                      fontWeight: 600,
                    },
                  },
                  [theme.breakpoints.down('sm')]: {
                    px: 0.5,
                    height: theme.spacing(5),
                  },
                })}
              >
                <ListItemText
                  sx={{
                    my: 0,
                  }}
                >
                  <Typography
                    variant={!mobileView ? 'h6' : 'subtitle2'}
                    textAlign={'center'}
                    sx={{
                      [theme.breakpoints.down('sm')]: {
                        fontWeight: selectedIndex === index ? 600 : 500,
                      },
                    }}
                  >
                    {t(item.label)}
                  </Typography>
                </ListItemText>
                {(item.counter || 0) > 0 && (
                  <Badge badgeContent={item.counter} color="primary" />
                )}
              </ListItemButton>
            </Grid>
          ))}
        </Grid>
      </Toolbar>
    </Box>
  );
}

export const ProfileNavigationComponent = memo(ProfileNavigation);
