import { Field, FieldProps, Form, Formik, FormikProps } from 'formik';
import React, { forwardRef, memo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { Patient } from '../../interfaces/entities/patient.interface';
import { MultiLinesField } from './shared/multi-lines-field.component';

type PatientInterests = Pick<Patient, 'interests'>;

interface PatientInterestsFormProps extends PatientInterests {
  onChange: (data: PatientInterests) => void;
}

const MIN_ROWS = 5;

function PatientInterestsFormBase(
  { onChange, ...props }: PatientInterestsFormProps,
  ref: React.Ref<FormikProps<Patient>>,
) {
  const { t } = useTranslation();

  return (
    <Formik<PatientInterests>
      initialValues={props || {}}
      onSubmit={onChange}
      innerRef={ref as React.Ref<FormikProps<PatientInterests>>}
      validationSchema={Yup.object<
        Record<keyof PatientInterests, Yup.AnySchema>
      >().shape({
        interests: Yup.string().nullable().optional(),
      })}
    >
      {() => (
        <Form>
          <Field name="interests">
            {(props: FieldProps) => {
              return (
                <MultiLinesField
                  {...props}
                  label={t('patient-edit-interests.label')}
                  assistiveText={t('patient-edit-interests.assistive')}
                  assistiveTextPosition={'top'}
                  minRows={MIN_ROWS}
                />
              );
            }}
          </Field>
        </Form>
      )}
    </Formik>
  );
}

export const PatientInterestsForm = memo(forwardRef(PatientInterestsFormBase));
