import axios, { AxiosInstance } from 'axios';

import { UploadPreResult } from '../interfaces/upload-pre-result.interface';
import { UploadResponse } from '../interfaces/upload-response.interface';
import { getRandomId } from '../utils/get-random-id';
import { formatContentRange } from '../utils/images/format-content-range';
import { sliceFile } from '../utils/slice-file';

const CHUNK_SIZE = 5 * 1024 * 1024; // 5MB chunk size

export class UploadsService {
  constructor(private readonly api: AxiosInstance) {}

  async upload(
    url: string,
    file: File,
    uploadData?: any,
  ): Promise<UploadResponse> {
    const { data } = await this.api.post<UploadPreResult>(
      this.getFullUrl(url),
      uploadData,
    );

    const arr: { chunk: Blob; start: number; end: number }[] = [];
    let start = 0;

    while (start < file.size) {
      let end = start + CHUNK_SIZE;
      if (end > file.size) {
        end = file.size;
      }

      const chunk = sliceFile(file, start, end);
      arr.push({ chunk, start, end });
      start += CHUNK_SIZE;
    }

    const uniqueUploadId = getRandomId();
    const results = await Promise.all(
      arr.map(({ chunk, start, end }) =>
        this.uploadChunk(
          chunk,
          data,
          uniqueUploadId,
          formatContentRange(start, end - 1, file.size),
        ),
      ),
    );

    const uploadingData = results.find(
      ({ secure_url, url }) => secure_url || url,
    );

    return {
      ...uploadingData,
      url: uploadingData.secure_url || uploadingData.url,
    };
  }

  async uploadByUrl(
    url: string,
    originalUrl: string,
    uploadData?: any,
  ): Promise<UploadResponse> {
    const { data } = await this.api.post<UploadPreResult>(
      this.getFullUrl(url),
      uploadData,
    );

    const formData = new FormData();
    formData.append('file', originalUrl);
    formData.append('api_key', data.api_key);
    if (data.folder) {
      formData.append('folder', data.folder);
    }
    formData.append('signature', data.signature);
    formData.append('timestamp', '' + data.timestamp);

    const { data: uploadingData } = await axios({
      method: 'post',
      url: data.url,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    });

    return uploadingData;
  }

  private async uploadChunk(
    chunk: Blob,
    data: UploadPreResult,
    uniqueUploadId: string,
    contentRange: string,
  ) {
    const formData = new FormData();
    formData.append('file', chunk);
    formData.append('api_key', data.api_key);
    if (data.folder) {
      formData.append('folder', data.folder);
    }
    formData.append('signature', data.signature);
    formData.append('timestamp', '' + data.timestamp);

    const { data: uploadingData } = await axios({
      method: 'post',
      url: data.url,
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-Unique-Upload-Id': uniqueUploadId,
        'Content-Range': contentRange,
      },
      data: formData,
    });

    return uploadingData;
  }

  private getFullUrl(url: string) {
    return `${url}/uploads`;
  }
}
