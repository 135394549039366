import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, Redirect } from 'react-router-dom';

import { PROFILE_ROUTE_INVITATION_CREATE } from '../../configs/profile-routes';
import { MY_PATIENTS_ROUTE } from '../../configs/routes';
import { Patient } from '../../interfaces/entities/patient.interface';
import { BLACK_COLOR, WHITE_COLOR } from '../../theme';
import getName from '../../utils/get-name';
import { AvatarComponent } from '../avatar.component';
import {
  ConfirmGiveAccessToDepartmentComponent,
  ConfirmGiveAccessToDepartmentProps,
} from '../dialog/confirm-give-access-to-department.component';

const IMAGE_WIDTH = 240;
const IMAGE_HEIGHT = 240;

interface PatientCreatedDepartmentAccessProps
  extends Patient,
    Pick<
      ConfirmGiveAccessToDepartmentProps,
      | 'nurses'
      | 'nursesAccesses'
      | 'onClickSelect'
      | 'onClickSelectAll'
      | 'onClickRemove'
      | 'onClickRemoveAll'
    > {
  onClickGiveAccess?: () => void;
  departmentName: string;
  loadingNurses?: boolean;
}

function PatientCreatedDepartmentAccess({
  id,
  firstName,
  lastName,
  image,
  departmentName,
  onClickGiveAccess,
  nurses,
  nursesAccesses,
  loadingNurses,
  onClickSelect,
  onClickSelectAll,
  onClickRemove,
  onClickRemoveAll,
}: PatientCreatedDepartmentAccessProps) {
  const { t } = useTranslation();
  const [showConfirmDialog, setShowConfirmDialog] = useState(true);

  const clickGiveAccess = useCallback(() => {
    setShowConfirmDialog(true);
  }, []);

  const closeConfirmDialog = useCallback(() => {
    setShowConfirmDialog(false);
  }, []);

  const onConfirmDialog = useCallback(() => {
    setShowConfirmDialog(false);
    if (onClickGiveAccess) {
      onClickGiveAccess();
    }
  }, [onClickGiveAccess]);

  const name = useMemo(() => {
    return getName(firstName, lastName);
  }, [firstName, lastName]);

  if (loadingNurses) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={(theme) => ({
          minHeight: '67vh',
          [theme.breakpoints.down('sm')]: {
            minHeight: '74vh',
          },
        })}
      >
        <CircularProgress size={80} />
      </Box>
    );
  }

  if (loadingNurses === false && !nurses?.length) {
    return (
      <Redirect
        to={`${MY_PATIENTS_ROUTE.path}/${id}/${PROFILE_ROUTE_INVITATION_CREATE.path}`}
      />
    );
  }

  return (
    <Stack alignItems="center" spacing={{ xs: 3, md: 4 }}>
      <AvatarComponent
        name={name}
        src={image || ''}
        sx={(theme) => ({
          height: IMAGE_HEIGHT,
          width: IMAGE_WIDTH,
          fontSize: theme.typography.h1.fontSize,
          fontWeight: theme.typography.h1.fontWeight,
          fontStyle: theme.typography.h1.fontStyle,
          lineHeight: theme.typography.h1.lineHeight,
          letterSpacing: theme.typography.h1.letterSpacing,
          border: '1px solid',
          color: WHITE_COLOR,
          boxShadow: `0 0 0 6px ${theme.palette.primary.main}`,
        })}
      />

      <Box>
        <Typography variant="h4" fontSize={'28px'} fontWeight={700}>
          {t('create-profile-give-access-to-department.title', {
            department: departmentName,
          })}
        </Typography>
      </Box>

      <Box width={'100%'} maxWidth={'450px'}>
        <Button
          fullWidth
          variant="contained"
          sx={{
            height: '50px',
            textTransform: 'none',
            borderRadius: '68.953px',
            boxShadow:
              '0px 1.9481251239776611px 3.8962502479553223px 0px rgba(0, 0, 0, 0.20), 0px 0.9740625619888306px 9.740625381469727px 0px rgba(0, 0, 0, 0.12), 0px 3.8962502479553223px 4.870312690734863px 0px rgba(0, 0, 0, 0.14)',
            fontSize: '19.307px',
            fontWeight: 500,
            lineHeight: '15.585px',
            letterSpacing: '1.218px',
          }}
          disableElevation
          onClick={clickGiveAccess}
        >
          {t('create-profile-give-access-to-department.button')}
        </Button>
        <Link
          to={`${MY_PATIENTS_ROUTE.path}/${id}/${PROFILE_ROUTE_INVITATION_CREATE.path}`}
          underline="none"
          component={RouterLink}
        >
          <Button
            fullWidth
            variant="contained"
            sx={{
              height: '50px',
              mt: 2.25,
              textTransform: 'none',
              color: BLACK_COLOR,
              backgroundColor: '#F2F2F2',
              borderRadius: '68.953px',
              boxShadow:
                '0px 1.9481251239776611px 3.8962502479553223px 0px rgba(0, 0, 0, 0.20), 0px 0.9740625619888306px 9.740625381469727px 0px rgba(0, 0, 0, 0.12), 0px 3.8962502479553223px 4.870312690734863px 0px rgba(0, 0, 0, 0.14)',
              fontSize: '19.307px',
              fontWeight: 500,
              lineHeight: '15.585px',
              letterSpacing: '1.218px',
              '&:hover': {
                backgroundColor: '#F2F2F2',
              },
            }}
            disableElevation
          >
            {t('create-profile-later.button')}
          </Button>
        </Link>
      </Box>
      <ConfirmGiveAccessToDepartmentComponent
        isOpen={showConfirmDialog}
        departmentName={departmentName}
        onConfirm={onConfirmDialog}
        onClose={closeConfirmDialog}
        nurses={nurses}
        nursesAccesses={nursesAccesses}
        onClickSelect={onClickSelect}
        onClickSelectAll={onClickSelectAll}
        onClickRemove={onClickRemove}
        onClickRemoveAll={onClickRemoveAll}
      />
    </Stack>
  );
}

export const PatientCreatedDepartmentAccessComponent = memo(
  PatientCreatedDepartmentAccess,
);
